import CONST from './Constant';
import {doLogout} from "../../Modules/Auth";
import {axiosPrivate} from "../Axios/SetupAxios";

export const openRightDrawer = (payload) => ({
    type: CONST.RIGHT_DRAWER_STATE,
    payload:payload,
})

export const openLeftDrawer = (payload) => ({
    type: CONST.LEFT_DRAWER_STATE,
    payload:payload,
})

export const setAppBarHeight = (payload) => ({
    type: CONST.SET_APPBAR_HEIGHT,
    payload:payload,
})

export const openCommonModal = (payload) => ({
    type: CONST.COMMON_MODAL_STATE,
    payload:payload,
})

export const openFsModal = (payload) => ({
    type: CONST.FS_MODAL_STATE,
    payload:payload,
})
export const setFsModal = (payload) => ({
    type: CONST.SET_FS_MODAL,
    payload:payload,
})

export const showBlockUI = (payload) => ({
    type: CONST.BLOCKUI_STATE,
    payload:payload,
})


/****************************************
 FETCH ALL BANKS
 ****************************************/
export const fetchBankSuccess = (payload) => ({
    type: CONST.FETCH_BANKS_SUCCESS,
    payload: payload
})

export const fetchBankFailed = (payload) => ({
    type: CONST.FETCH_BANKS_FAILED,
    payload: payload
})

export const fetchBanks = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.get('bank', ).then((response) => {
        res['success'] = response;
        dispatch(fetchBankSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchBankFailed(error?.response));
    });
    return res;
}


/****************************************
 FETCH ALL LOV
 ****************************************/
export const fetchLOVSuccess = (payload) => ({
    type: CONST.FETCH_LOVS_SUCCESS,
    payload: payload
})

export const fetchLOVFailed = (payload) => ({
    type: CONST.FETCH_LOVS_FAILED,
    payload: payload
})

export const fetchLOV = async (dispatch, data) => {

    await axiosPrivate.get('lov', ).then((response) => {
        dispatch(fetchLOVSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchLOVFailed(error?.response));
    });
}
