import CONST from "./Constant";

export const INITIAL_STATE = {
    leftDrawerWidth:240,
    rightDrawerWidth:500,
    commonModalWidth:400,
    anchorEl:'',
    openLeftDrawer:false,
    openRightDrawer:false,
    openCommonModal:false,
    openFsModal:false,
    fsModalComponent:null,
    openChangePasswordModal:false,
    showBlockUI:false,
    appBarHeight:'',
    items:'',
    banks:'',
};

const generalReducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.LEFT_DRAWER_STATE:
            return {
                ...state,
                openLeftDrawer: action.payload
            }
        case CONST.RIGHT_DRAWER_STATE:
            return {
                ...state,
                openRightDrawer: action.payload
            }
        case CONST.SET_APPBAR_HEIGHT:
            return {
                ...state,
                appBarHeight: action.payload
            }
        case CONST.COMMON_MODAL_STATE:
            return {
                ...state,
                openCommonModal: action.payload
            }
        case CONST.FS_MODAL_STATE:
            return {
                ...state,
                openFsModal: action.payload
            }
        case CONST.SET_FS_MODAL:
            return {
                ...state,
                fsModalComponent: action.payload.component,
                openFsModal: action.payload.state
            }
        case CONST.BLOCKUI_STATE:
            return {
                ...state,
                showBlockUI: action.payload
            }
        case CONST.FETCH_LOVS_SUCCESS:
            return {
                ...state,
                lov: action.payload?.data
            }
        case CONST.FETCH_BANKS_SUCCESS:
            return {
                ...state,
                banks: action.payload?.data
            }
        case CONST.HAS_BUSINESS_SUCCESS:
            return {
                ...state,
                hasBusiness: action.payload?.data ?? false,
            }
        default:
            return state;
    }
}

export default generalReducer;