import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import { useMediaQuery} from "@mui/material";
import { deleteFinancial } from "../../index";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const FinancialDeleteModal = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const config = useSelector((store) => store.config);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const financials = useSelector((store)=> store.business.financials.selected);

    const handleSubmit = async () => {
        await deleteFinancial(dispatch, financials.id).then((res) => {
            dispatch(setFsModal({component:null, state:false}));
        });
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={config.openFsModal}
                onClose={() => dispatch(setFsModal({component:null, state:false})) }
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>Confirm Delete!</DialogTitle>
                <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                    Are you sure, you want to delete this entry?
                </DialogContent>
                <DialogActions sx={{p:3, pt:0}}>
                    <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                    <Button variant={"contained"} color={'primary'} onClick={ handleSubmit }>Proceed</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}