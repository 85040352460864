import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED CUSTOMER_SUPPLIER
 ****************************************/
export const setSelectedCustomerSupplier = (payload) => ({
    type: CONST.SET_SELECTED_CUSTOMER_SUPPLIER,
    payload: payload
})
/****************************************
 FETCH CUSTOMER_SUPPLIER
 ****************************************/
export const fetchCustomerSuppliersSuccess = (payload) => ({
    type: CONST.FETCH_CUSTOMER_SUPPLIERS_SUCCESS,
    payload: payload
})

export const fetchCustomerSuppliersFailed = (payload) => ({
    type: CONST.FETCH_CUSTOMER_SUPPLIERS_FAILED,
    payload: payload
})

export const fetchCustomerSuppliers = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.get('customer', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchCustomerSuppliersSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchCustomerSuppliersFailed(error?.response));
    });
    return res;
}
/****************************************
 ADD Business CUSTOMER_SUPPLIER
 ****************************************/
export const addCustomerSupplierSuccess = (payload) => ({
    type: CONST.ADD_CUSTOMER_SUPPLIER_SUCCESS,
    payload: payload
})

export const addCustomerSupplierFailed = (payload) => ({
    type: CONST.ADD_CUSTOMER_SUPPLIER_FAILED,
    payload: payload
})

export const addCustomerSupplier = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('customer', data).then((response) => {
        res['success'] = response;
        dispatch(addCustomerSupplierSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addCustomerSupplierFailed(error?.response));
    });
    return res;
}
/****************************************
 Update Business CUSTOMER_SUPPLIER
 ****************************************/
export const updateCustomerSupplierSuccess = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_SUPPLIER_SUCCESS,
    payload: payload
})

export const updateCustomerSupplierFailed = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_SUPPLIER_FAILED,
    payload: payload
})

export const updateCustomerSupplier = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('customer/' + data['customer_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateCustomerSupplierSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateCustomerSupplierFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business CUSTOMER_SUPPLIER
 ****************************************/
export const deleteCustomerSupplierSuccess = (payload) => ({
    type: CONST.DELETE_CUSTOMER_SUPPLIER_SUCCESS,
    payload: payload
})

export const deleteCustomerSupplierFailed = (payload) => ({
    type: CONST.DELETE_CUSTOMER_SUPPLIER_FAILED,
    payload: payload
})

export const deleteCustomerSupplier = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.delete('customer/' + id).then((response) => {
        res['success'] = response;
        console.log(id);
        dispatch(deleteCustomerSupplierSuccess(id));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteCustomerSupplierFailed(error?.response));
    });
    return res;
}