import * as React from 'react';
import {useDispatch} from "react-redux";
import {documented} from "../../Redux/LoanAction";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export const DocumentationCompleteModal = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        await documented(dispatch, {loan_id:props.loan_id}).then((res) => {
            if(res['success']){
                props.setOpen(false);
                navigate('/business-finance');
            }
        });
    };

    return(
        <>
            <DialogTitle>Documentation Completed</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, the documentation for this loan is completed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Documentation Completed</Button>
            </DialogActions>
        </>
    )
}