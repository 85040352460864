import * as React from 'react';
import {Chip as ChipUI} from "@mui/material";
import {styled} from "@mui/material/styles";


export const Chip = (props) => {
    console.log(props)
    const StyledChip = styled(ChipUI)({
        border: 0,
        borderRadius: 10,
        padding: '0 8px',
        height:25,
        '& .MuiChip-label':{
            fontSize: 10,
            textTransform:'uppercase',
            padding: '0',
        }
    });

    return (
        <>
            <StyledChip {...props} />
        </>
    );
}