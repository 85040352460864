import React,{useState } from 'react';
import Box from "@mui/material/Box";
import {Card, CardActions, CardContent, CardHeader, Container, Paper, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {doForgotPassword} from "../../Users";
import { useDispatch, } from "react-redux";
import {Link} from "react-router-dom";
import {isBrowser} from "react-device-detect";
import BlockUI from "../../Components/Common/BlockUI";
import Toastr from "../../Components/Common/Toastr";
import PoweredBy from "../../Components/Common/PoweredBy";
import {showBlockUI} from "../../../Setup/Redux/ConfigAction";

export const ForgotPassword = () => {

    return (
        <>
            {isBrowser ? <BrowserView /> : <MobileView /> }
        </>
    )
}

const BrowserView = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = useState();

    const [data, setData] = useState({
        username : '',
    })


    const handleSubmit = async () => {
        dispatch(showBlockUI(true));
        doForgotPassword(dispatch, data).then((res) => {
            dispatch(showBlockUI(false));
            setResponse(res);
        });
    };

    const handleChange = (e) => {

        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}} >

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:1, display:'flex', justifyContent:'center',alignItems:'center'}} />

                    <Box sx={{p:2, flexGrow:0.75}}>
                        <Stack direction={"column"} spacing={2} sx={{ minWidth: '310px' }} alignItems={"center"}>

                            <Box sx={{marginBottom:'20px'}}>
                                <img src={'/images/ifund-logo.png'} width={200} />
                            </Box>

                            <Card sx={{marginTop:'80px', border:'none'}}>

                                <CardHeader title={"Forgot Password"} sx={{borderBottom:'none!important'}} />

                                <CardContent>
                                    <Stack spacing={2} sx={{minWidth:300}}>
                                        <TextField
                                            type="text"
                                            id="username"
                                            onChange={(e) => handleChange(e)}
                                            value={data['username']}
                                            required
                                            autoComplete="off"
                                            label="Registered Email ID"
                                        />
                                    </Stack>
                                </CardContent>

                                <CardActions sx={{display:'flex', flexDirection:'column', p:2, borderTop:'none!important'}} >

                                    <Button color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Request Password Change</Button>

                                    <Stack direction={"row"} spacing={2} justifyContent="space-between">
                                        <Link to={'/login'}>Return to Login Page</Link>
                                    </Stack>

                                </CardActions>

                            </Card>

                            <BlockUI />

                            { Object.keys(response).length > 0 && <Toastr response={response} /> }
                        </Stack>
                    </Box>

                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>
    )
}
const MobileView = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = useState();

    const [data, setData] = useState({
        username : '',
    })


    const handleSubmit = async () => {
        dispatch(showBlockUI(true));
        doForgotPassword(dispatch, data).then((res) => {
            dispatch(showBlockUI(false));
            setResponse(res);
        });
    };

    const handleChange = (e) => {

        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    return (
        <Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

            <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                <Box sx={{flexGrow:2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src={'/images/ifund-logo.png'} width={150} />
                </Box>

                <Box sx={{p:2,flexGrow:0.75}}>

                    <Stack spacing={2} sx={{ minWidth: '310px' }}>

                        <TextField
                            type="text"
                            id="username"
                            onChange={(e) => handleChange(e)}
                            value={data['username']}
                            required
                            autoComplete="off"
                            label="Registered Email ID"
                            fullWidth
                        />
                        <Button color="primary" className={'text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Request Password Reset</Button>

                        <Link to={'/login'}>Return to Login Page</Link>

                        <BlockUI />
                        { Object.keys(response).length > 0 && <Toastr response={response} /> }
                    </Stack>
                </Box>

                <Box sx={{p:2}}>
                    <PoweredBy />
                </Box>
            </Stack>
        </Container>
    )
}