import * as React from 'react';
import {countries} from "../../../Setup/Config";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
export default function SelectCountry(props) {
    const handleChange = (e) => {
        props.state({
            ...props.data,
            [e.target.name] :e.target.value,
        });
    }

    return (
        <>
            <FormControl sx={{ width:'100%', mb:3  }}>
                <InputLabel id={"label-"+props?.name}>{props?.label}</InputLabel>
                <Select
                    fullWidth
                    disabled={(props?.disabled || props?.disabledSubmit)}
                    //value={}
                    name={props?.name}
                    MenuProps={{
                        style: {zIndex: 35001}
                    }}
                    value={(props.data[props.name] || props.default) ?? " "}
                    label={props?.label}
                    onChange={(e) => handleChange(e)}
                >
                    <MenuItem value=" " key="None">Select Country</MenuItem>
                    { countries.map((i) => (
                        <MenuItem key={i.id} value={i.iso3}>{i.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}