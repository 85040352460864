import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";
import {updateInvoiceFailed, updateInvoiceSuccess} from "../../Invoices";


//setSelectedService
export const resetServices = (payload) => ({
    type: CONST.RESET_ALL,
    payload:null
})
export const setSelectedService = (payload) => ({
    type: CONST.SET_SELECTED_SERVICE,
    payload: payload
})

/****************************************
 FETCH SERVICE
 ****************************************/
export const fetchServiceSuccess = (payload) => ({
    type: CONST.FETCH_SERVICE_SUCCESS,
    payload: payload
})

export const fetchServiceFailed = (payload) => ({
    type: CONST.FETCH_SERVICE_FAILED,
    payload: payload
})

export const fetchService = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.get('service/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchServiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchServiceFailed(error?.response));
    });
    return res;
}

/****************************************
 FETCH SERVICES
 ****************************************/
export const fetchServicesSuccess = (payload) => ({
    type: CONST.FETCH_SERVICES_SUCCESS,
    payload: payload
})

export const fetchServicesFailed = (payload) => ({
    type: CONST.FETCH_SERVICES_FAILED,
    payload: payload
})

export const fetchServices = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.get('service', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchServicesSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchServicesFailed(error?.response));
    });
    return res;
}


/****************************************
 ADD SERVICE
 ****************************************/
export const addServiceSuccess = (payload) => ({
    type: CONST.ADD_SERVICE_SUCCESS,
    payload: payload
})

export const addServiceFailed = (payload) => ({
    type: CONST.ADD_SERVICE_FAILED,
    payload: payload
})

export const addService = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('service', data).then((response) => {
        res['success'] = response;
        dispatch(addServiceSuccess(response));
        dispatch(updateInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updateInvoiceFailed(error?.response));// Update Invoice State as well to show error
        return dispatch(addServiceFailed(error?.response));
    });
    return res;
}

/****************************************
 UPDATE SERVICE
 ****************************************/
export const updateServiceSuccess = (payload) => ({
    type: CONST.UPDATE_SERVICE_SUCCESS,
    payload: payload
})

export const updateServiceFailed = (payload) => ({
    type: CONST.UPDATE_SERVICE_FAILED,
    payload: payload
})

export const updateService = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('service/' + data['service_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateServiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updateServiceFailed(error?.response));
    });
    return res;
}
/****************************************
 UPDATE SERVICE CHARGE
 ****************************************/
export const updateServiceChargeSuccess = (payload) => ({
    type: CONST.UPDATE_SERVICE_CHARGE_SUCCESS,
    payload: payload
})

export const updateServiceChargeFailed = (payload) => ({
    type: CONST.UPDATE_SERVICE_CHARGE_FAILED,
    payload: payload
})

export const updateServiceCharge = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('service/' + data['service_id'] + '/charge', data).then((response) => {
        res['success'] = response;
        dispatch(updateServiceChargeSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updateServiceChargeFailed(error?.response));
    });
    return res;
}


/****************************************
 DELETE SERVICE
 ****************************************/
export const deleteServiceSuccess = (payload) => ({
    type: CONST.DELETE_SERVICE_SUCCESS,
    payload: payload
})

export const deleteServiceFailed = (payload) => ({
    type: CONST.DELETE_SERVICE_FAILED,
    payload: payload
})

export const deleteService = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.delete('service/' + data['id']).then((response) => {
        res['success'] = response;
        dispatch(deleteServiceSuccess(data['id']));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(deleteServiceFailed(error?.response));
    });
    return res;
}





