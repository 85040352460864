import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";
import {fetchLoanDocuments} from "../../Documents";

export const setSelectedLoan = (payload) => ({
    type: CONST.SET_SELECTED_LOAN,
    payload: payload
})

/****************************************
 FETCH LOAN
 ****************************************/
export const fetchLoanSuccess = (payload) => ({
    type: CONST.FETCH_LOAN_SUCCESS,
    payload: payload
})

export const fetchLoanFailed = (payload) => ({
    type: CONST.FETCH_LOAN_FAILED,
    payload: payload
})

export const fetchLoan = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.get('loan/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchLoanSuccess(response));
        dispatch(fetchLoanDocuments(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchLoanFailed(error?.response));
    });
    return res;
}


/****************************************
 FETCH LOANS
 ****************************************/
export const fetchLoansSuccess = (payload) => ({
    type: CONST.FETCH_LOANS_SUCCESS,
    payload: payload
})

export const fetchLoansFailed = (payload) => ({
    type: CONST.FETCH_LOANS_FAILED,
    payload: payload
})

export const fetchLoans = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.get('loan', data).then((response) => {
        res['success'] = response;
        dispatch(fetchLoansSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchLoansFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD Loan
 ****************************************/
export const addLoanSuccess = (payload) => ({
    type: CONST.ADD_LOAN_SUCCESS,
    payload: payload
})

export const addLoanFailed = (payload) => ({
    type: CONST.ADD_LOAN_FAILED,
    payload: payload
})

export const addLoan = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('loan', data).then((response) => {
        res['success'] = response;
        dispatch(addLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addLoanFailed(error?.response));
    });
    return res;
}


/****************************************
 Update Loan - Details
 ****************************************/
export const updateLoanSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SUCCESS,
    payload: payload
})

export const updateLoanFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_FAILED,
    payload: payload
})

export const updateLoan = async (dispatch, data) => {

    data['is_active'] = 1;
    let res = {};
    await axiosPrivate.patch('loan/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateLoanFailed(error?.response));
    });
    return res;
}
/****************************************
 Verify a Loan
 ****************************************/
export const verifyLoanSuccess = (payload) => ({
    type: CONST.VERIFY_LOAN_SUCCESS,
    payload: payload
})

export const verifyLoanFailed = (payload) => ({
    type: CONST.VERIFY_LOAN_FAILED,
    payload: payload
})

export const verifyLoan = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('loan/verify/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(verifyLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(verifyLoanFailed(error?.response));
    });
    return res;
}
/****************************************
 Reject a Loan
 ****************************************/
export const rejectLoanSuccess = (payload) => ({
    type: CONST.REJECT_LOAN_SUCCESS,
    payload: payload
})

export const rejectLoanFailed = (payload) => ({
    type: CONST.REJECT_LOAN_FAILED,
    payload: payload
})

export const rejectLoan = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('loan/reject/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(rejectLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(rejectLoanFailed(error?.response));
    });
    return res;
}
/****************************************
 Return a Loan
 ****************************************/
export const returnLoanSuccess = (payload) => ({
    type: CONST.RETURN_LOAN_SUCCESS,
    payload: payload
})

export const returnLoanFailed = (payload) => ({
    type: CONST.RETURN_LOAN_FAILED,
    payload: payload
})

export const returnLoan = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('loan/return/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(returnLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(returnLoanFailed(error?.response));
    });
    return res;
}
/****************************************
 Approve a Loan
 ****************************************/
export const approveLoanSuccess = (payload) => ({
        type: CONST.VERIFY_LOAN_SUCCESS,
        payload: payload
    })

export const approveLoanFailed = (payload) => ({
    type: CONST.VERIFY_LOAN_FAILED,
    payload: payload
})

export const approveLoan = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('loan/approve/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(approveLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(approveLoanFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Loan
 ****************************************/
export const deleteLoanSuccess = (payload) => ({
    type: CONST.DELETE_LOAN_SUCCESS,
    payload: payload
})

export const deleteLoanFailed = (payload) => ({
    type: CONST.DELETE_LOAN_FAILED,
    payload: payload
})

export const deleteLoan = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.delete('loan/' + data['loan_id']).then((response) => {
        res['success'] = response;
        dispatch(deleteLoanSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteLoanFailed(error?.response));
    });
    return res;
}


/****************************************
 Submit for Loan Documentation
 ****************************************/
export const requestDocumentationSuccess = (payload) => ({
    type: CONST.REQUEST_DOCUMENTATION_SUCCESS,
    payload: payload
})

export const requestDocumentationFailed = (payload) => ({
    type: CONST.REQUEST_DOCUMENTATION_FAILED,
    payload: payload
})

export const requestDocumentation = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.patch('loan/requestDocumentation/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(requestDocumentationSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(requestDocumentationFailed(error?.response));
    });
    return res;
}

/****************************************
 Submit for Loan Documentation
 ****************************************/
export const returnDocumentationSuccess = (payload) => ({
    type: CONST.RETURN_DOCUMENTATION_SUCCESS,
    payload: payload
})

export const returnDocumentationFailed = (payload) => ({
    type: CONST.RETURN_DOCUMENTATION_FAILED,
    payload: payload
})

export const returnDocumentation = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.patch('loan/returnDocumentation/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(returnDocumentationSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(returnDocumentationFailed(error?.response));
    });
    return res;
}




/****************************************
 Loan Documentation Complete
 ****************************************/
export const documentationSuccess = (payload) => ({
    type: CONST.DOCUMENTATION_SUCCESS,
    payload: payload
})

export const documentationFailed = (payload) => ({
    type: CONST.DOCUMENTATION_FAILED,
    payload: payload
})

export const documented = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('loan/documentationComplete/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(documentationSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(documentationFailed(error?.response));
    });
    return res;
}

/****************************************
 Start Loan Disbursement
 ****************************************/
export const startDisbursementSuccess = (payload) => ({
    type: CONST.REQUEST_DISBURSEMENT_SUCCESS,
    payload: payload
})

export const startDisbursementFailed = (payload) => ({
    type: CONST.REQUEST_DISBURSEMENT_FAILED,
    payload: payload
})

export const requestDisbursement = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('loan/requestDisbursement/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(startDisbursementSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(startDisbursementFailed(error?.response));
    });
    return res;
}

/****************************************
  Loan Disbursement Complete
 ****************************************/
export const disbursedSuccess = (payload) => ({
    type: CONST.DISBURSEMENT_SUCCESS,
    payload: payload
})

export const disbursedFailed = (payload) => ({
    type: CONST.DISBURSEMENT_FAILED,
    payload: payload
})

export const disbursed = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.patch('loan/disbursementComplete/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(disbursedSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(disbursedFailed(error?.response));
    });
    return res;
}


/****************************************
 Submit Loan - For Approval Request
 ****************************************/
export const updateLoanStatusSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SUCCESS,
    payload: payload
})

export const updateLoanStatusFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_FAILED,
    payload: payload
})

export const updateLoanStatus = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.patch('loan/' + data['loan_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateLoanStatusSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateLoanStatusFailed(error?.response));
    });
    return res;
}