import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl, FormControlLabel, FormLabel, InputLabel,
    Radio, RadioGroup, Select,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openRightDrawer} from "../../../Setup/Redux/ConfigAction";
import {updateSetting} from "../Redux/Action";

export const SettingsForm = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.settings);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({})

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = () => {
        setDisabled(true);
        updateSetting(dispatch, data)
    };

    return(
        <Card sx={{ height: '100%', position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={()=> dispatch(openRightDrawer(false))}>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Edit Permission</Typography>
                }
            />
            <CardContent sx={{ flex:1 }}>
                { /* error message */ }
                <TextField
                    fullWidth
                    label="Permission Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                    size="large"
                    value={ data['name'] }
                    sx={{mb:3}}
                    helperText={items.error?.name}
                />
            </CardContent>
            <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                <Button disabled={disabled} color="primary" variant="contained" onClick={ handleSubmit }>Save</Button>
                <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(openRightDrawer(false))}>Close</Button>
            </CardActions>
        </Card>
    )
}
