import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DrawerHeader from "./DrawerHeader";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ShieldIcon from '@mui/icons-material/Shield';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';
import PaymentsIcon from '@mui/icons-material/Payments';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import PowerIcon from '@mui/icons-material/Power';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../../Auth";
import { openLeftDrawer } from "../../../Setup/Redux/ConfigAction";

const LeftDrawer = () => {

    const dispatch = useDispatch();
    const store = useSelector((store) => store);
    const auth = store.auth;
    const permissions = auth.user.permissions;
    const config = store.config;
    const theme = useTheme();
    const navigate = useNavigate();
    const handleLogout = () => (doLogout(dispatch, auth.token).then(()=>{
        navigate('/login');
    }));

    const open = config.openLeftDrawer;
    const drawerWidth = config.leftDrawerWidth;
    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(0),
        },
    });

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const handleLeftDrawerClose = () => {

        dispatch(openLeftDrawer(false));
    };

    const menu = [
        { permission:'dashboard-menu', name: 'Dashboard', icon: <DashboardIcon />, 'link':'/dashboard' },
        { permission:'business-menu', name: 'Business', icon: <StoreIcon />, 'link':'/business' },
        // { permission:'invoice-discounting-menu', name: 'Invoice Discounting', icon: <ReceiptIcon/>, 'link':'/invoice-discounting' },
        { permission:'invoices-menu', name: 'Invoices', icon: <ArticleIcon />, 'link':'/invoices' },
        { permission:'customers-menu', name: 'Customers', icon: <PeopleIcon />, 'link':'/customers' },
        { permission:'business-finance-menu', name: 'Business Finance', icon: <PaymentsIcon/>, 'link':'/business-finance' },
        { permission:'accounts-menu', name: 'Accounts', icon: <AttachMoneyIcon />, 'link':'/accounts' },
        { permission:'permission-menu', name: 'Permissions', icon: <PowerIcon/>, 'link':'/permissions' },
        { permission:'role-menu', name: 'Roles', icon: <ShieldIcon/>, 'link':'/roles' },
        { permission:'user-menu', name: 'Users', icon: <GroupIcon />, 'link':'/users' },
    ]

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleLeftDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{ display:'flex', flexDirection:'column', alignItems:'flex-start', height:'100%'}}>
                {menu.map(item => (
                    permissions.includes(item.permission) || auth.user.role_type === 'ADMIN' ?
                        <ListItem divider key={item.name} disablePadding sx={{ display: 'block' }} component={Link} to={item.link}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText color={theme.secondary} primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                        :''
                ))}

                <ListItem key='logout' disablePadding sx={{ display: 'block', marginTop:'auto' }} onClick={handleLogout}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>

        </Drawer>
    )
}

export default LeftDrawer;