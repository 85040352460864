import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {CustomerForm} from "../Forms/CustomerForm";
import { Grid, Stack, useMediaQuery} from "@mui/material";
import {
    addCustomer,
    deleteCustomer,
    clearErrors,
    proceed,
    setSelectedCustomer,
    CustomerDeleteModal
} from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function CustomerStep() {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.customers).length > 0 ? business.customers.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCustomer(dispatch, {business_id: business.selected.business_id, for_invoice_discounting: 1});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCustomer(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<CustomerForm />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<CustomerDeleteModal />, state:true}));
        dispatch(setSelectedCustomer(e.currentTarget.dataset.id));
    }


    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Customer Name', type: 'string', flex:1, minWidth:250},
            { field: 'credit_limit_requested', headerName:'Credit Limit Req', type: 'string', flex:1, minWidth:140,
                valueGetter: (params) => {
                    return (params.row?.config.find((o) => o.config_type === 'credit_limit_requested'))?.config_value;
                }
            },
            { field: 'dealing_since', headerName:'Dealing Since', type: 'string', flex:1, minWidth: 120},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', flex:1, minWidth:120},
            { field: 'currency', headerName:'Currency', type: 'string', flex:1, minWidth:80},
            {
                flex:1,
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                minWidth:120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.customer_id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={objects}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { left: ['company_name'], right: ['actions'] }
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Customer</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}