import './App.css';
import { AllRoutes as Routes} from './Routing/Routes';
import { BrowserRouter } from "react-router-dom";
import {persistor, store} from "./Setup/Redux/Store";
import {Provider} from "react-redux";
import React from "react";
import {PersistGate} from "redux-persist/integration/react";
import { LicenseInfo } from '@mui/x-license-pro';

function App() {

    LicenseInfo.setLicenseKey('425787fbae2d8ba54f7080a7f88eb395Tz03MDM0MixFPTE3MjA1MjcwOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
