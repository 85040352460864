import React from 'react'
import Typography from "@mui/material/Typography";
import {Card, CardActions, CardContent} from "@mui/material";
import Button from "@mui/material/Button";
import {RegisterBusinessModal} from "../../../Business/Components/Modals/RegisterBusinessModal";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {useDispatch, useSelector} from "react-redux";
import {proceed} from "../../../Business";
import {LoanDocumentForm} from "../../../BusinessFinance";

export const RegisterBusinessCard = () => {

    const dispatch = useDispatch();
    const auth = useSelector(store => store.auth);

    const handleRegisterBtnClick = () => {
        dispatch(setFsModal({component: <RegisterBusinessModal />, state:true}))
        dispatch(proceed(false));
    }
    return (
        <>
            <Card>
                <CardContent sx={{p:3}}>
                    <Typography sx={{ fontSize: 18, mb:3 }} variant="h3" color="text.primary" gutterBottom>
                        Get Started
                    </Typography>
                    <Typography variant="body1">
                        Welcome, { auth.user.first_name}
                    </Typography>
                    <Typography>
                        In order to apply for our product(s), it is mandatory to provide all the information requested during business registration.
                    </Typography>
                    <Typography>
                        Please follow the steps and update all the sections applicable.
                    </Typography>
                </CardContent>
                <CardActions sx={{p:3}}>
                    <Button  color={'info'} variant="contained" size={"large"} onClick={() => handleRegisterBtnClick()}>Register Business</Button>
                </CardActions>
            </Card>

        </>
    );
}
