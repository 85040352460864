import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED CUSTOMER
 ****************************************/
export const setSelectedCustomer = (payload) => ({
    type: CONST.SET_SELECTED_CUSTOMER,
    payload: payload
})

/****************************************
 FETCH CUSTOMER
 ****************************************/
export const fetchCustomerSuccess = (payload) => ({
    type: CONST.FETCH_CUSTOMER_SUCCESS,
    payload: payload
})

export const fetchCustomerFailed = (payload) => ({
    type: CONST.FETCH_CUSTOMER_FAILED,
    payload: payload
})

export const fetchCustomer = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('customer/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchCustomerFailed(error?.response));
    });
    return res;
}

/****************************************
 FETCH CUSTOMERS
 ****************************************/
export const fetchCustomersSuccess = (payload) => ({
    type: CONST.FETCH_CUSTOMERS_SUCCESS,
    payload: payload
})

export const fetchCustomersFailed = (payload) => ({
    type: CONST.FETCH_CUSTOMERS_FAILED,
    payload: payload
})

export const fetchCustomers = async (dispatch, data) => {
    let res = {}
    //TODO: Check why params are sent
    await axiosPrivate.get('customer', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchCustomersSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchCustomersFailed(error?.response));
    });
    return res;
}
/****************************************
 ADD Business CUSTOMER
 ****************************************/
export const addCustomerSuccess = (payload) => ({
    type: CONST.ADD_CUSTOMER_SUCCESS,
    payload: payload
})

export const addCustomerFailed = (payload) => ({
    type: CONST.ADD_CUSTOMER_FAILED,
    payload: payload
})

export const addCustomer = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('customer', data).then((response) => {
        res['success'] = response;
        dispatch(addCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addCustomerFailed(error?.response));
    });
    return res;
}
/****************************************
 Update Business CUSTOMER
 ****************************************/
export const updateCustomerSuccess = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_SUCCESS,
    payload: payload
})

export const updateCustomerFailed = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_FAILED,
    payload: payload
})

export const updateCustomer = async (dispatch, data) => {
    let res = {}
    data['status'] = 'SUBMITTED';
    await axiosPrivate.patch('customer/' + data['customer_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateCustomerFailed(error?.response));
    });
    return res;
}
/****************************************
 Verify a Customer
 ****************************************/
export const verifyCustomerSuccess = (payload) => ({
    type: CONST.VERIFY_CUSTOMER_SUCCESS,
    payload: payload
})

export const verifyCustomerFailed = (payload) => ({
    type: CONST.VERIFY_CUSTOMER_FAILED,
    payload: payload
})

export const verifyCustomer = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('customer/verify/' + data['customer_id'], data).then((response) => {
        res['success'] = response;
        dispatch(verifyCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(verifyCustomerFailed(error?.response));
    });
    return res;
}
/****************************************
 Reject a Customer
 ****************************************/
export const rejectCustomerSuccess = (payload) => ({
    type: CONST.REJECT_CUSTOMER_SUCCESS,
    payload: payload
})

export const rejectCustomerFailed = (payload) => ({
    type: CONST.REJECT_CUSTOMER_FAILED,
    payload: payload
})

export const rejectCustomer = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('customer/reject/' + data['customer_id'], data).then((response) => {
        res['success'] = response;
        dispatch(rejectCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(rejectCustomerFailed(error?.response));
    });
    return res;
}
/****************************************
 Return a Customer
 ****************************************/
export const returnCustomerSuccess = (payload) => ({
    type: CONST.RETURN_CUSTOMER_SUCCESS,
    payload: payload
})

export const returnCustomerFailed = (payload) => ({
    type: CONST.RETURN_CUSTOMER_FAILED,
    payload: payload
})

export const returnCustomer = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('customer/return/' + data['customer_id'], data).then((response) => {
        res['success'] = response;
        dispatch(returnCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(returnCustomerFailed(error?.response));
    });
    return res;
}
/****************************************
 Approve a Customer
 ****************************************/
export const approveCustomerSuccess = (payload) => ({
    type: CONST.VERIFY_CUSTOMER_SUCCESS,
    payload: payload
})

export const approveCustomerFailed = (payload) => ({
    type: CONST.VERIFY_CUSTOMER_FAILED,
    payload: payload
})

export const approveCustomer = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('customer/approve/' + data['customer_id'], data).then((response) => {
        res['success'] = response;
        dispatch(approveCustomerSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(approveCustomerFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business CUSTOMER
 ****************************************/
export const deleteCustomerSuccess = (payload) => ({
    type: CONST.DELETE_CUSTOMER_SUCCESS,
    payload: payload
})

export const deleteCustomerFailed = (payload) => ({
    type: CONST.DELETE_CUSTOMER_FAILED,
    payload: payload
})

export const deleteCustomer = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.delete('customer/' + id).then((response) => {
        res['success'] = response;
        dispatch(deleteCustomerSuccess(id));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteCustomerFailed(error?.response));
    });
    return res;
}