import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected: {}
};

const roleReducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_SELECTED_ROLE:
            return {
                ...state,
                selected: state.list.filter(item => item.role_id === action.payload)[0],
            }
        case CONST.FETCH_ROLE_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Role loaded successfully"],
                code:action.payload.status
            }
        case CONST.FETCH_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_ROLES_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                error: [],
                message: ["Roles loaded successfully"],
                code:action.payload.status
            }
        case CONST.FETCH_ROLES_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_ROLE_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: {},
                error: [],
                message: ["Role added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_ROLE_SUCCESS:
            const indexU = state.list.findIndex(role => role.role_id === action.payload.data.role_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Role updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }

        case CONST.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.role_id !== action.payload),
                selected: {},
                error: [],
                message: ["Role deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default roleReducer ;