import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));


export const ProgressBar = () => {
    const value = 51;

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            <BorderLinearProgress variant="determinate" value={value} />
            <Typography
                variant="body2"
                color="textSecondary"
                sx={{ position: 'absolute', top: '-20px', left: '0%', width: '100%', textAlign: 'right' }}
            >
                {value}% Consumed
            </Typography>
        </Box>
    );
};