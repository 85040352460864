import CONST from "./Constant";

const INITIAL_STATE = {
    loans:[],
    activities:[],
    tasks:[],
    customer:[],
    invoices:[],
    stats:[],
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){

        case CONST.FETCH_STATS_SUCCESS:
            return {
                ...state,
                customers: action.payload.data?.customers,
                invoices: action.payload.data?.invoices,
                loans: action.payload.data?.loans,
                business: action.payload.data?.business,
                businessCreditStats: action.payload.data?.businessCreditStats,
                error:[],
                code:200
            }
        case CONST.FETCH_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default dashboardReducer ;