import React from "react";
import {
    FormControl,
    Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
    setSelectedBankBorrowing,
    updateBankBorrowing
} from "../../index";
import AttachDoc from "../../../Documents/Components/AttachDoc";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";
import BankAutocomplete from "../BankAutocomplete";
import Toastr from "../../../Components/Common/Toastr";
import MenuItem from "@mui/material/MenuItem";
import MuiDatepicker from "../Datepicker";

export const BankBorrowingForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState(false);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(business.bankBorrowings.selected);
    },[])

    const handleSubmit = async () => {
        setDisabled(true);
        await updateBankBorrowing(dispatch, data).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}));
        dispatch(setSelectedBankBorrowing(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Bank Borrowings
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>

                                <BankAutocomplete setDisabled={setDisabled} setData={setData} data={data} />

                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-currency">Currency</InputLabel>
                                    <Select
                                        defaultValue={data?.currency || ""}
                                        name="currency"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Currency"
                                        onChange={(e) => handleChange(e)}
                                        helperText={business?.error?.currency}
                                    >
                                        <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                                        <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                                        <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                                        <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    label="Borrowing Limit / Amount"
                                    name="borrowing_limit"
                                    defaultValue={data?.borrowing_limit}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.borrowing_limit}
                                    onBlur={handleChange} />

                                <TextField
                                    fullWidth
                                    label="Outstanding Amount as on Date "
                                    name="outstanding"
                                    defaultValue={data?.outstanding}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.outstanding}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Overdue Amount (if any)"
                                    name="overdue"
                                    defaultValue={data?.overdue}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.overdue}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Monthly Installment Amount (EMI)"
                                    name="emi"
                                    defaultValue={data?.emi}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.emi}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.borrowing_start_date} past={true} setDisabled={setDisabled} label={"Borrowing Start Date"} name="borrowing_start_date" data={data} state={setData} />

                                <MuiDatepicker error={business?.error?.borrowing_end_date} past={false} setDisabled={setDisabled} label={"Borrowing End Date"} name="borrowing_end_date" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Relationship Manager Name"
                                    name="rm_name"
                                    defaultValue={data?.rm_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.rm_name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Relationship Manager Email"
                                    name="rm_email"
                                    defaultValue={data?.rm_email}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.rm_email}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Relationship Manager Phone/Mobile"
                                    name="rm_mobile"
                                    defaultValue={data?.rm_mobile}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.rm_mobile}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    name="remarks"
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />

                                <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateBankBorrowing} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Loan Agreement / Facility Offer Letter"} entity_name={"bankBorrowings"} entity_id={data?.id} name="agreement_copy_doc" data={data} state={setData} />

                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

