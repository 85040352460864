import React,{ useRef, useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Stack,
    TextField
} from "@mui/material";
import ContainerUI from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom";
import { doLogin } from "../Redux/authAction";
import {useDispatch} from "react-redux";
import {isBrowser} from "react-device-detect";
import {styled, useTheme} from "@mui/material/styles";
import Toastr from "../../Components/Common/Toastr";
import BlockUI from "../../Components/Common/BlockUI";
import {setFsModal, showBlockUI} from "../../../Setup/Redux/ConfigAction";
import PoweredBy from "../../Components/Common/PoweredBy";

const Login = () => {
    return (
        <>{isBrowser ? <BrowserView /> : <MobileView />}</>
    )
}

const Container = styled(ContainerUI)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
    }
}));

const BrowserView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();
    const theme = useTheme();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState({});

    useEffect(() => {
        dispatch(showBlockUI(false));
        userRef.current.focus();
    }, [])

    const handleSubmit = () => {
        dispatch(showBlockUI(true));
        doLogin(dispatch, {username, password}).then((res) => {
            dispatch(showBlockUI(false));
            dispatch(setFsModal({component:null, state:false}));
            setResponse(res);
            if (res['success']) {
                navigate('/dashboard');
            }
        });
    }

    return (<Container maxWidth={'xxl'} component="main" sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}} >

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:1, display:'flex', justifyContent:'center',alignItems:'center'}} />

                    <Box sx={{p:2,flexGrow:0.75}}>
                        <Stack direction={"column"} spacing={2} sx={{ minWidth: '310px' }} alignItems={"center"}>

                            <Box sx={{marginBottom:'20px'}}>
                                <img src={'/images/ifund-logo.png'} width={200}  alt={"Ifund Logo"}/>
                            </Box>

                            <Card sx={{marginTop:'80px', border:'none'}}>
                                <CardHeader title={"Sign In"} sx={{borderBottom:'none!important'}} />
                                <CardContent>
                                    <Stack spacing={2} sx={{minWidth:300}}>
                                        <TextField
                                            type="text"
                                            id="username"
                                            ref={userRef}
                                            onChange={(e) => setUsername(e.target.value)}
                                            value={username}
                                            required
                                            label="Username"
                                            inputProps={{
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            sx={{ width: '100%'}}
                                        />
                                        <TextField
                                            type="password"
                                            id="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            ref={userRef}
                                            required
                                            label="Password"
                                            inputProps={{
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            sx={{ width: '100%'}}
                                        />
                                    </Stack>
                                </CardContent>
                                <CardActions sx={{display:'flex', flexDirection:'column', p:2, pb:0, borderTop:'none!important'}} >

                                    <Button color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Sign In</Button>

                                    <Stack direction={"row"} spacing={2} justifyContent="space-between">
                                        <Link to={'/forgot-password'} className={'mb-3 d-block text-right'}>Forgot Password</Link>
                                        <Link to={'/register'}>Register Now</Link>
                                    </Stack>
                                </CardActions>
                            </Card>
                            <BlockUI />
                        </Stack>
                    </Box>

                    <Box sx={{p:2}}>
                        { Object.keys(response).length > 0 && <Toastr response={response} /> }
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>)
}
const MobileView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();
    const theme = useTheme();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState(false);

    useEffect(() => {
        //userRef.current.focus();
    }, [])

    const handleSubmit = () => {

        doLogin(dispatch, {username,password}).then((res) => {
            setResponse(res);
            if(res['success']){
                navigate('/dashboard');
            }
        });
    }

    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={'/images/ifund-logo.png'} width={150} />
                    </Box>

                    <Box sx={{p:2,flexGrow:0.75}}>
                        <Stack spacing={2} sx={{ minWidth: '310px' }}>
                            <TextField
                                type="text"
                                id="username"
                                ref={userRef}
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                required
                                label="Username"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                            <TextField
                                type="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                ref={userRef}
                                required
                                label="Password"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                            <Button color="primary" className={'text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Sign in</Button>

                            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                                <Link to={'/forgot-password'} className={'mb-3 d-block text-right'}>Forgot Password</Link>
                                <Link to={'/register'}>Register Now</Link>
                            </Stack>

                            <BlockUI />
                            { Object.keys(response).length > 0 && <Toastr response={response} /> }
                        </Stack>
                    </Box>
                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>)
}

export default Login