import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";
import {fetchBusinessDocuments} from "../../Documents/index";

export const setSelectedBusiness = (payload) => ({
    type: CONST.SET_SELECTED_BUSINESS,
    payload: payload
})


/****************************************
 FETCH BUSINESS
 ****************************************/
export const fetchBusinessSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_SUCCESS,
    payload: payload
})

export const fetchBusinessFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_FAILED,
    payload: payload
})

export const fetchBusiness = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('business/' + id).then((response) => {
        dispatch(fetchBusinessSuccess(response));
        dispatch(fetchBusinessDocuments(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 FETCH BUSINESSES
 ****************************************/
export const fetchBusinessesSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESSES_SUCCESS,
    payload: payload
})

export const fetchBusinessesFailed = (payload) => ({
    type: CONST.FETCH_BUSINESSES_FAILED,
    payload: payload
})

export const fetchBusinesses = async (dispatch, data) => {

    await axiosPrivate.get('business', data).then((response) => {
        dispatch(fetchBusinessesSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchBusinessesFailed(error?.response));
    });
}


/****************************************
 ADD Business
 ****************************************/
export const addBusinessSuccess = (payload) => ({
    type: CONST.ADD_BUSINESS_SUCCESS,
    payload: payload
})

export const addBusinessFailed = (payload) => ({
    type: CONST.ADD_BUSINESS_FAILED,
    payload: payload
})

export const addBusiness = async (dispatch, data) => {
    let res = {};

    await axiosPrivate.post('business', data).then((response) => {
        dispatch(addBusinessSuccess(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 Update Business - Details
 ****************************************/
export const updateBusinessSuccess = (payload) => ({
    type: CONST.UPDATE_BUSINESS_SUCCESS,
    payload: payload
})

export const updateBusinessFailed = (payload) => ({
    type: CONST.UPDATE_BUSINESS_FAILED,
    payload: payload
})

export const updateBusiness = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('business/' + data['business_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateBusinessFailed(error?.response));
    });

    return res;
}


/****************************************
 Verify a Business
 ****************************************/
export const verifyBusinessSuccess = (payload) => ({
    type: CONST.VERIFY_BUSINESS_SUCCESS,
    payload: payload
})

export const verifyBusinessFailed = (payload) => ({
    type: CONST.VERIFY_BUSINESS_FAILED,
    payload: payload
})

export const verifyBusiness = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('business/verify/' + data['business_id'], data).then((response) => {
        res['success'] = response;
        dispatch(verifyBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(verifyBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 Reject a Business
 ****************************************/
export const rejectBusinessSuccess = (payload) => ({
    type: CONST.REJECT_BUSINESS_SUCCESS,
    payload: payload
})

export const rejectBusinessFailed = (payload) => ({
    type: CONST.REJECT_BUSINESS_FAILED,
    payload: payload
})

export const rejectBusiness = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('business/reject/' + data['business_id'], data).then((response) => {
        res['success'] = response;
        dispatch(rejectBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(rejectBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 Reject a Business
 ****************************************/
export const exportBusinessSuccess = (payload) => ({
    type: CONST.EXPORT_BUSINESS_SUCCESS,
    payload: payload
})

export const exportBusinessFailed = (payload) => ({
    type: CONST.EXPORT_BUSINESS_FAILED,
    payload: payload
})

export const exportBusiness = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('business/export', data).then((response) => {
        res['success'] = response;
        dispatch(exportBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(exportBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 Return a Business
 ****************************************/
export const returnBusinessSuccess = (payload) => ({
    type: CONST.RETURN_BUSINESS_SUCCESS,
    payload: payload
})

export const returnBusinessFailed = (payload) => ({
    type: CONST.RETURN_BUSINESS_FAILED,
    payload: payload
})

export const returnBusiness = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('business/return/' + data['business_id'], data).then((response) => {
        res['success'] = response;
        dispatch(returnBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(returnBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 Approve a Business
 ****************************************/
export const approveBusinessSuccess = (payload) => ({
    type: CONST.VERIFY_BUSINESS_SUCCESS,
    payload: payload
})

export const approveBusinessFailed = (payload) => ({
    type: CONST.VERIFY_BUSINESS_FAILED,
    payload: payload
})

export const approveBusiness = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('business/approve/' + data['business_id'], data).then((response) => {
        res['success'] = response;
        dispatch(approveBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(approveBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 DELETE Business
 ****************************************/
export const deleteBusinessSuccess = (payload) => ({
    type: CONST.DELETE_BUSINESS_SUCCESS,
    payload: payload
})

export const deleteBusinessFailed = (payload) => ({
    type: CONST.DELETE_BUSINESS_FAILED,
    payload: payload
})

export const deleteBusiness = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.delete('business/' + data['business_id']).then((response) => {
        res['success'] = response;
        dispatch(deleteBusinessSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteBusinessFailed(error?.response));
    });
    return res;
}


/****************************************
 Submit Business - For Approval Request
 ****************************************/
export const updateBusinessStatusSuccess = (payload) => ({
    type: CONST.UPDATE_BUSINESS_SUCCESS,
    payload: payload
})

export const updateBusinessStatusFailed = (payload) => ({
    type: CONST.UPDATE_BUSINESS_FAILED,
    payload: payload
})

export const updateBusinessStatus = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.patch('business/' + data['business_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateBusinessStatusSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateBusinessStatusFailed(error?.response));
    });
    return res;
}
