import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { setFsModal } from "../../../Setup/Redux/ConfigAction";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function FsModal(props) {

    const config = useSelector((store) => store.config);
    const dispatch = useDispatch();
    const open = config.openFsModal;
    const component = config.fsModalComponent;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={open}
                onClose={() => dispatch(setFsModal({component:null,state:false})) }
                aria-labelledby="responsive-dialog-title"
            >
                {props.component ?? component}
            </Dialog>
        </>
    );
}