import * as React from 'react';
import {Container} from "@mui/material";
import {useSelector} from "react-redux";

const CustomContainer = ({children}) => {

    const config = useSelector((store) => store.config );

    return (
        <>
            <Container component="main" sx={{
                display:'flex', flexDirection:'column', flexGrow: 1,
                mt: config.appBarHeight + 'px',
                maxWidth: {
                    xs: '100vw',
                    sm: '90vw',
                    md: '92vw',
                    lg: '95vw',
                    xl: '55vw',
                    xxl: '70vw',
                },
                minWidth: {
                    xl: '1500px',
                },
                m:'80px auto 0', p:3
            }}>
                {children}
            </Container>
        </>
    );
}

export default CustomContainer;



