import * as React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { fetchPermissions } from "../../Permissions";
import {Chip} from "@mui/material";


export default function RoleTable(props) {

    const items = useSelector((store) => store.permissions);

    const dispatch = useDispatch();

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        fetchPermissions(dispatch, filters);

        return ()=>{
            isMounted = false;
            controller.abort()
        }
    },[filters]);

    const columns = React.useMemo(
        () => [
            { field: 'name', headerName:'Permissions Name', type: 'string', minWidth:200 },
            { field: 'label', headerName:'Label', type: 'string', width: 300 },
            { field: 'type', headerName:'Type', type: 'string', width: 130,
                renderCell: (params) => ( params.value === 'menu' ? <Chip label="Menu" size="small" color="primary" /> : <Chip label="Feature" size="small" color="warning" />)
            },
            { field: 'group', headerName:'Group', type: 'string', width: 180 },
            { field: 'is_active', headerName:'Status', flex:1, type: 'string', width: 120,
                renderCell: (params) => ( params.value === 1 ? <Chip label="Active" size="small" color="info" /> : <Chip label="Suspend" size="small" color="error" />)
            },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', minWidth:200,
                valueGetter: ({ value }) => value && new Date(value).toLocaleString(),
            },
            { field: 'updated_at', headerName:'Updated At', type: 'dateTime', minWidth:200,
                valueGetter: ({ value }) => value && new Date(value).toLocaleString(),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width: 120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={props.deleteItem(params.id)}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={props.editItem(params.id)}
                    />,
                ],
            },
        ],
        [props.deleteItem, props.editItem],
    );

    return (
        <div style={{ flex: '1 1 100%', width: '100%', borderTop:'1px solid #ddd' }}>
            <DataGrid
                getRowId={(row)=>row.permission_id}
                pageSize={filters.pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                columns={columns}
                rows={items.list} sx={{ border:'none'}}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'created_at', sort: 'desc' }],
                    },
                }}
            />
        </div>
    );
}