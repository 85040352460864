import React, {useState} from 'react'
import { Grid, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { approveInvoice,} from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";

export const ApproveInvoiceModal = () => {

    const invoice = useSelector((store) => store.invoices.selected);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [credit, setCredit] = useState(0);
    const [response, setResponse] = useState({});
    const handleSubmit = async () => {

        let data = {
            'credit_limit': credit,
            'invoice_id': invoice.invoice_id
        }
        setDisabled(true);
        await approveInvoice(dispatch, data).then((res)=>{
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setFsModal({component:null, state:false}));
            }
            //props.setAction('issue-loan');
        });
    };

    const handleCreditChange = (e) => {
        setDisabled(e.target.value <= 0);
        setCredit(e.target.value);
    }

    return(
        <>
            <DialogTitle>Approve Invoice</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <DialogContentText>
                            Are you sure, you want to approve this invoice?
                        </DialogContentText>
                        <TextField
                            placeholder={"Enter the Credit Limit"}
                            fullWidth
                            required
                            onBlur={handleCreditChange}
                            defaultValue={invoice.invoice_amount}
                            type={"number"}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Approve Invoice</Button>
            </DialogActions>
        </>
    )
}
