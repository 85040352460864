import * as React from 'react';
import {Alert} from "@mui/material";
import {useEffect, useState} from "react";

export default function CustomAlertV2(props) {
    console.log(props);
    const [msg, setMsg] = useState({});
    const status = { '201':'success', '200':'success', '422':'warning', '403':'error' }

    useEffect(() => {

        setMsg({
            'code':props.response.hasOwnProperty('error') ? props?.response?.error?.status : props?.response?.success?.status,
            'message':props.response.hasOwnProperty('error') ? props?.response?.error?.data?.message : props?.response?.success?.data?.message,
        });
    }, [props.response])

    return (
        <>
            {
                msg.code &&
                <Alert color={status[msg.code]} sx={{ mb:3}}>
                    { msg.message ?? 'Action Successfully completed' }
                </Alert>
            }
        </>
    );
}


