import * as React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid
} from "@mui/material";
import { useSelector} from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {styled} from "@mui/material/styles";
import {currencyFormatter} from "../../../../Setup/Config/currencyFormat";

export const LoanDetailsPanel = () => {

    const loans = useSelector(state => state.loans);

    const CustomList = styled(List)({
        color: 'darkslategray',
        '& .MuiListItem-root': {
            padding: '0!important',
            borderBottom:'1px solid #f4f4f4',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return(
        <>
            { loans !== undefined &&
            <Card sx={{mb:2}}>
                <CardHeader title={"Request Details"} titleTypographyProps={{variant:'subtitle1' }}  />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <CustomList sx={{p:0}}>
                                <ListItem>
                                    <ListItemText primary={"Assigned To"} secondary={loans.selected.assigned_to?.email} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Amount"} secondary={currencyFormatter.format(loans.selected.amount)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Start Date"} secondary={loans.selected.start_date} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"End Date"} secondary={loans.selected.end_date} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Payment Method"} secondary={loans.selected.payment_method} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Re-Payment Method"} secondary={loans.selected.repayment_method} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Status"} secondary={loans.selected.status} />
                                </ListItem>
                            </CustomList>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            }
        </>
    )
}