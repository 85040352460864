const CONST = {
    'FETCH_DOCUMENTS_SUCCESS':'DOCUMENTS/FETCH_DOCUMENTS_SUCCESS',
    'FETCH_DOCUMENTS_FAILED':'DOCUMENTS/FETCH_DOCUMENTS_FAILED',
    'UPLOAD_DOCUMENT_SUCCESS':'DOCUMENTS/UPLOAD_DOCUMENT_SUCCESS',
    'UPLOAD_DOCUMENT_FAILED':'DOCUMENTS/UPLOAD_DOCUMENT_FAILED',
    'REMOVE_DOCUMENT_SUCCESS':'DOCUMENTS/REMOVE_DOCUMENT_SUCCESS',
    'REMOVE_DOCUMENT_FAILED':'DOCUMENTS/REMOVE_DOCUMENT_FAILED',
    'FETCH_BUSINESS_DOCUMENTS_SUCCESS':'DOCUMENTS/FETCH_BUSINESS_DOCUMENTS_SUCCESS',
    'FETCH_BUSINESS_DOCUMENTS_FAILED':'DOCUMENTS/FETCH_BUSINESS_DOCUMENTS_FAILED',
    'FETCH_LOAN_DOCUMENTS_SUCCESS':'DOCUMENTS/FETCH_LOAN_DOCUMENTS_SUCCESS',
    'FETCH_LOAN_DOCUMENTS_FAILED':'DOCUMENTS/FETCH_LOAN_DOCUMENTS_FAILED',
}
export default CONST;