import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import { doLogout } from "../../Auth";



/****************************************
 FETCH USER STATS
 ****************************************/
export const fetchStatsSuccess = (payload) => ({
    type: CONST.FETCH_STATS_SUCCESS,
    payload: payload
})

export const fetchStatsFailed = (payload) => ({
    type: CONST.FETCH_STATS_FAILED,
    payload: payload
})

export const fetchStats = async (dispatch) => {
    let res = {}
    await axiosPrivate.get('dashboard/stats').then((response) => {
        res['success'] = response;
        dispatch(fetchStatsSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchStatsFailed(error?.response));
    });
    return res;
}
