import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl, FormControlLabel, FormLabel,
    InputLabel, Radio, RadioGroup, Select,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState, useEffect} from "react";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {openCommonModal, openRightDrawer} from "../../../Setup/Redux/ConfigAction";
import {addUser, updateUser, changePassword, deleteUser} from "../index";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomAlert from "../../Components/Common/CustomAlert";

export const AddUser = () => {
    const dispatch = useDispatch();
    const items = useSelector((store)=> store.users);
    const roles = useSelector((store) => store.roles);
    const [data, setData] = useState();

    const [msg, setMsg] = useState({});

    useEffect(() => {
        setMsg({
            'code':items.code,
            'message':items.message,
            'error':items.error,
        });
    }, [items])

    useEffect(() => {
        setMsg({
            'code':'',
            'message':'',
            'error':'',
        });
    }, [data])


    useEffect(()=>{
        //setErrors(items.error);
        //dispatch(openRightDrawer(false));
    },[items.error]);

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async() => {
        await addUser(dispatch, data)
    };

    return(
        <Card sx={{ height: '100%', borderRadius:0, position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={ () => dispatch(openRightDrawer(false)) }>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Add User</Typography>
                }
            />
            <CardContent sx={{ flex:1, overflow:'auto' }}>
                <CustomAlert msg={msg} />
                <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.first_name}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.last_name}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    type="email"
                    helperText={items.error?.email}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    type="password"
                    helperText={items.error?.password}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    type="tel"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3, flex:1}}
                    helperText={items.error?.phone}
                    onChange={(e) => handleChange(e)}
                />

                <FormControl sx={{ width:'100%', mb:3  }}>
                    <InputLabel id="add-user-form-label-office">Role</InputLabel>
                    <Select
                        defaultValue={""}
                        name="role_id"
                        MenuProps={{
                            style: {zIndex: 35001}
                        }}
                        fullWidth
                        label="Role"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="">None</MenuItem>
                        {roles.list.map((role) => (
                            <MenuItem key={ role.role_id } value={ role.role_id }> {role.role_name} </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ width:'100%', mb:3  }}>
                    <InputLabel id="add-user-form-label-office">Class</InputLabel>
                    <Select
                        defaultValue={""}
                        name="role_type"
                        MenuProps={{
                            style: {zIndex: 35001}
                        }}
                        fullWidth
                        label="Class"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem key={ "ADMIN" } value={ "ADMIN" }> { "Admin" } </MenuItem>
                        <MenuItem key={ "USER" } value={ "USER" }> { "User" } </MenuItem>
                        <MenuItem key={ "CLIENT" } value={ "CLIENT" }> { "Client" } </MenuItem>
                    </Select>
                </FormControl>

            </CardContent>
            <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                <Button variant={'contained'} color={'primary'} onClick={ handleSubmit }>Add User</Button>
                <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(openRightDrawer(false))}>Close</Button>
            </CardActions>
        </Card>
    )
}

export const EditUser = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.users);
    const roles = useSelector((store) => store.roles);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({})

    const [msg, setMsg] = useState({});

    useEffect(() => {
        setMsg({
            'code':items.code,
            'message':items.message,
            'error':items.error,
        });
    }, [items])

    useEffect(() => {
        setMsg({
            'code':'',
            'message':'',
            'error':'',
        });
    }, [data])

    useEffect(() => {
        setData(items.selected);
    },[items.selected])

    const handleChange = (e) => {

        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = () => {
        setDisabled(true);
        updateUser(dispatch, data)
    };

    return(
        <>
            { data !== undefined && Object.keys(data).length > 0 ?
                <Card sx={{ height: '100%', position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
                <CardHeader
                    action={
                        <IconButton aria-label="share" onClick={()=> dispatch(openRightDrawer(false))}>
                            <CloseIcon />
                        </IconButton>
                    }
                    avatar={
                        <Typography>Edit User</Typography>
                    }
                />
                <CardContent sx={{ flex:1 }}>
                    <CustomAlert msg={msg} />
                    <TextField
                        fullWidth
                        label="First Name"
                        name="first_name"
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                        size="large"
                        value={ data['first_name'] }
                        sx={{mb:3}}
                        helperText={items.error?.first_name}
                    />
                    <TextField
                        fullWidth
                        label="Last Name"
                        name="last_name"
                        onChange={(e) => handleChange(e)}
                        value={ data['last_name'] }
                        variant="outlined"
                        size="large"
                        sx={{mb:3}}
                        helperText={items.error?.last_name}
                    />

                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={ data['email'] }
                        variant="outlined"
                        size="large"
                        sx={{mb:3}}
                        helperText={items.error?.email}
                    />
                    <TextField
                        fullWidth
                        label="Phone"
                        name="phone"
                        onChange={(e) => handleChange(e)}
                        value={ data['phone'] }
                        variant="outlined"
                        size="large"
                        sx={{mb:3, flex:1}}
                        helperText={items.error?.phone}
                    />
                    <FormControl sx={{ width:'100%', mb:3  }}>
                        <InputLabel id="add-user-form-label-office">Role</InputLabel>
                        <Select
                            value={ data['role_id'] }
                            name="role_id"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Role"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem value="">None</MenuItem>
                            {roles.list.map((role) => (
                                <MenuItem key={ role.role_id } value={ role.role_id }> {role.role_name} </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width:'100%', mb:3  }}>
                        <InputLabel id="add-user-form-label-office">Role Type</InputLabel>
                        <Select
                            value={ data['role_type'] }
                            name="role_type"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Role Type"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem key={ "ADMIN" } value={ "ADMIN" }> { "Admin" } </MenuItem>
                            <MenuItem key={ 'USER' } value={ 'USER' }> { 'User' } </MenuItem>
                            <MenuItem key={ 'CLIENT' } value={ 'CLIENT' }> { 'Client' } </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel id="status-label">Status</FormLabel>
                        <RadioGroup
                            row
                            value={ data['is_active'] }
                            onChange={(e) => handleChange(e)}
                            aria-labelledby="status-label"
                            name="is_active"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Active" />
                            <FormControlLabel value="0" control={<Radio />} label="Suspend" />
                        </RadioGroup>
                    </FormControl>

                </CardContent>
                <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                    <Button disabled={disabled} color="primary" variant="contained" onClick={ handleSubmit }>Save</Button>
                    <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(openRightDrawer(false))}>Close</Button>
                </CardActions>
            </Card>
            :''}
        </>
    )
}

export const ChangePassword = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.users);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({
        user_id:'',
        password : '',
    })

    useEffect(() => {
        setData(items.selected);
    },[items.selected])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    const handleSubmit = async () => {

        setDisabled(true);
        await changePassword(dispatch, data);
        dispatch(openCommonModal(false));
    };

    return(
        <>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Please type the new password.
                </DialogContentText>
                <TextField
                    fullWidth
                    label="New Password"
                    name="password"
                    type="password"
                    onChange={(e) => handleChange(e)}
                    id="password"
                    value={ data['password'] }
                    variant="outlined"
                    size="large"
                    sx={{my:1, flex:1}}
                    helperText={items.error?.password}
                />
            </DialogContent>
            <DialogActions sx={{mb:2,mr:2}}>
                <Button onClick={() => dispatch(openCommonModal(false))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Change Password</Button>
            </DialogActions>
        </>
    )
}

export const DeleteUser = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.users);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        await deleteUser(dispatch, {id : items.selected.user_id || ''});
        dispatch(openCommonModal(false));
    };

    return(
        <>
            <DialogTitle>Delete User</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to remove this user?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(openCommonModal(false))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Delete User</Button>
            </DialogActions>
        </>
    )
}