import React from 'react'
import Typography from "@mui/material/Typography";
import {Card, CardActions, CardContent, CardHeader, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export const LoanMetricsCard = () => {

    const loans = useSelector(store => store.dashboard.loans);

    return (
        <>
            <Card sx={{display:'flex', height:'100%', justifyItems:'space-between', flexDirection:'column'}}>
                <CardContent sx={{p:3, flex:1}}>
                    <Grid>
                        <Typography sx={{ fontSize: '1rem', mb:2 }} variant="h3" color="text.primary">
                            Business Finances
                        </Typography>
                        <Typography sx={{ fontSize: 30, mb:1, fontWeight:'bold' }} variant="h4" color="text.primary" gutterBottom>
                            {0}
                        </Typography>
                    </Grid>
                </CardContent>
                <CardActions sx={{py:1,px:2,fontSize:'.75rem'}}>
                    {/*<Link sx={{fontSize:'.6rem'}} to={'/loans'}>*/}
                    {/*    See all Loan*/}
                    {/*</Link>*/}
                    <Typography sx={{fontSize:'.8rem'}}>
                        See all
                    </Typography>
                </CardActions>
            </Card>
        </>
    );
}
