import CONST from "./Constant";

const INITIAL_STATE = {
    files:[],
    error:[],
    message:[],
    code:'',
};

const documentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case CONST.FETCH_LOAN_DOCUMENTS_SUCCESS:
        case CONST.FETCH_BUSINESS_DOCUMENTS_SUCCESS:
            return {
                ...state,
                files: action.payload?.data?.documents,
                message: "Documents Loaded Successfully",
                code:action.payload.status,
            }
        case CONST.UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                files:[...state.files, action.payload.data],
                message: ["Document Uploaded Successfully"],
                code:action.payload.status,
            };
        case CONST.UPLOAD_DOCUMENT_FAILED:
            return {
                ...state,
                error: [],
                message: [],
                code:'',
            }
        case CONST.REMOVE_DOCUMENT_SUCCESS:
            return {
                ...state,
                files: state.files.filter((i) => i.document_id !== action.payload),
                error: [],
                message: [],
                code:200,
            }
        case CONST.REMOVE_DOCUMENT_FAILED:
            return {
                ...state,
                error: [],
                message: [],
                code:500,
            }
        default:
            return state;
    }
}

export default documentReducer ;