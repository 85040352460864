import React, { useState } from 'react'
import {Container, Fab, Paper} from "@mui/material";
import DataTable from './Table';
import AddIcon from "@mui/icons-material/Add";
import RightDrawer from "../../Components/Drawers/RightDrawer";
import Modal from "../../Components/Common/Modal";
import Typography from "@mui/material/Typography";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import { useDispatch } from "react-redux";
import {AddUser, EditUser, DeleteUser, ChangePassword} from './Forms';
import {openRightDrawer, openCommonModal} from "../../../Setup/Redux/ConfigAction";
import { fetchUser, setSelectedUser } from "../index";
import {fetchRoles} from "../../Roles";

export const Users = () => {

    const dispatch = useDispatch();
    const [formName, setFormName] = useState(null);

    const changePassword = React.useCallback(
        (id) => () => {
            dispatch(openCommonModal(true));
            setFormName('ChangePassword');
            dispatch(setSelectedUser(id));
        },
        [dispatch],
    );

    const addItem = () => {
        fetchRoles(dispatch);
        dispatch(openRightDrawer(true));
        setFormName('AddUser');
    };

    const editItem = React.useCallback(
        (id) => () => {
            dispatch(openRightDrawer(true));
            fetchUser(dispatch, id);
            fetchRoles(dispatch);
            setFormName('EditUser');
        },
        [dispatch],
    );

    const deleteItem = React.useCallback(

        (id) => () => {
            dispatch(openCommonModal(true));
            setFormName('DeleteUser');
            dispatch(setSelectedUser(id));
        },
        [dispatch],
    );

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Paper sx={{ p:2, mb:2}} variant="outlined">
                    <IconBreadcrumbs items={'DASHBOARD/USERS'} />
                </Paper>

                <Paper sx={{ display:'flex', flexDirection:'column', flex:1}}>

                    <Typography
                        sx={{ flex: '1', p:2 }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Users
                    </Typography>

                    <DataTable editItem={editItem} deleteItem={deleteItem} changePassword={changePassword} />

                    <Fab onClick={addItem} size="big" color="error" aria-label="add" sx={{ position: 'absolute', bottom: 50, right:50 }}>
                        <AddIcon />
                    </Fab>
                    {
                        (()=>{
                            switch (formName) {
                                case 'AddUser':
                                    return <RightDrawer component={<AddUser />} />
                                case 'EditUser':
                                    return <RightDrawer component={<EditUser />} />
                                case 'ChangePassword':
                                    return <Modal component={<ChangePassword />} />
                                case 'DeleteUser':
                                    return <Modal component={<DeleteUser />} />
                                default:
                                    return false
                            }
                        })()
                    }
                </Paper>
            </Container>
        </>
    );
}
