import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";

/****************************************
 SET SELECTED OWNERSHIP
 ****************************************/
export const setSelectedOwnership = (payload) => ({
    type: CONST.SET_SELECTED_OWNERSHIP,
    payload: payload
})
/****************************************
 FETCH OWNERSHIP
 ****************************************/
export const fetchOwnershipSuccess = (payload) => ({
    type: CONST.FETCH_OWNERSHIPS_SUCCESS,
    payload: payload
})

export const fetchOwnershipFailed = (payload) => ({
    type: CONST.FETCH_OWNERSHIPS_FAILED,
    payload: payload
})

export const fetchOwnership = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('business/'+ id + '/ownership').then((response) => {
        res ['success'] = response;
        dispatch(fetchOwnershipSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchOwnershipFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD Business Ownership
 ****************************************/
export const addOwnershipSuccess = (payload) => ({
    type: CONST.ADD_OWNERSHIP_SUCCESS,
    payload: payload
})

export const addOwnershipFailed = (payload) => ({
    type: CONST.ADD_OWNERSHIP_FAILED,
    payload: payload
})

export const addOwnership = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('ownership', data).then((response) => {
        res['success'] = response;
        dispatch(addOwnershipSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addOwnershipFailed(error?.response));
    });
    return res;
}


/****************************************
 Update Business Ownership
 ****************************************/
export const updateOwnershipSuccess = (payload) => ({
    type: CONST.UPDATE_OWNERSHIP_SUCCESS,
    payload: payload
})

export const updateOwnershipFailed = (payload) => ({
    type: CONST.UPDATE_OWNERSHIP_FAILED,
    payload: payload
})

export const updateOwnership = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('ownership/' + data['id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateOwnershipSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateOwnershipFailed(error?.response));
    });
    return res;
}

/****************************************
 DELETE Business Ownership
 ****************************************/
export const deleteOwnershipSuccess = (payload) => ({
    type: CONST.DELETE_OWNERSHIP_SUCCESS,
    payload: payload
})

export const deleteOwnershipFailed = (payload) => ({
    type: CONST.DELETE_OWNERSHIP_FAILED,
    payload: payload
})

export const deleteOwnership = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.delete('ownership/' + id).then((response) => {
        res['success'] = response;
        dispatch(deleteOwnershipSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteOwnershipFailed(error?.response));
    });
    return res;
}