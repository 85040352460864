import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected: {},
    error:[],
    message:[]
};

const accountReducer = (state = INITIAL_STATE, action) => {

    switch (action.type){

        case CONST.RESET_ALL:
            return INITIAL_STATE;
        case CONST.SET_SELECTED_ACCOUNT:
            return {
                ...state,
                selected: state.list.filter(item => item.id === action.payload)[0],
            }
        case CONST.FETCH_ACCOUNT_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Account loaded successfully"],
                code:action.payload.status,
            }
        case CONST.FETCH_ACCOUNT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_ACCOUNTS_SUCCESS:
            return {
                ...state,
                list:action.payload.data,
                error: [],
                code:action.payload.status,
                message: ["Accounts loaded successfully"],
            }
        case CONST.FETCH_ACCOUNTS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_ACCOUNT_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: {},
                error: [],
                message: ["Account added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_ACCOUNT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_ACCOUNT_SUCCESS:
            const indexU = state.list.findIndex(o => o.account_id === action.payload.data.account_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Account updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ACCOUNT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.account_id !== action.payload),
                selected: {},
                error: [],
                message: ["Account deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_ACCOUNT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default accountReducer ;