import React from 'react'
import {Fab, Grid, Paper} from "@mui/material";
import DataTable from './Table';
import RightDrawer from "../../Components/Drawers/RightDrawer";
import Typography from "@mui/material/Typography";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import { useDispatch } from "react-redux";
import { SettingsForm} from './Forms';
import { fetchSettings } from "../Redux/Action";

export const Settings = () => {

    const dispatch = useDispatch();
    fetchSettings(dispatch);

    return (
        <>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Paper sx={{ p:2, mb:2}} variant="outlined">
                        <IconBreadcrumbs items={'DASHBOARD/SETTINGS'} />
                    </Paper>
                </Grid>
                <Grid item lg={6} xs={12} >
                    <Paper sx={{ display:'flex', flexDirection:'column', flex:1}}>

                        <Typography
                            sx={{ flex: '1', p:2 }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Settings
                        </Typography>

                        <SettingsForm />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
