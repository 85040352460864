import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import LayoutProvider from "../Providers/LayoutProvider";
import {useSelector} from "react-redux";


const PrivateRoutes = ({ allowedRoles }) => {

    const auth = useSelector((store) => store.auth); // determine if authorized, from context or however you're doing it
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page

    return auth.token && allowedRoles.includes(auth.user?.role_type) ?
                (<LayoutProvider>
                    <Outlet />
                </LayoutProvider>)
                : <Navigate to="/login" />;
}

export default PrivateRoutes;