import * as React from 'react';
import {useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {FormControlLabel, FormGroup, Grid, Stack, TextField, useMediaQuery} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {addLoan} from "../../Redux/LoanAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toastr from "../../../Components/Common/Toastr";
import {useEffect, useState} from "react";
import {updateService} from "../../../Services";
import {currencyFormatter} from "../../../../Setup/Config";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";

export const IssueLoanModal = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const loans = useSelector(state => state.loans);
    const service = useSelector(state => state.services.selected);
    const business = useSelector(state => state.business);
    const [showFees, setShowFees] = useState(false);
    const business_config = business.config;

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [data, setData] = useState();
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setShowFees(false);
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleReviewBtn = () => {

        setShowFees(data?.amount !== '' && data?.max_allowed_tenor !== '' && data?.profit_rate !== '' && data?.no_of_repayments !== '' && data?.profit_rate !== '');

        const vat = 0.05;

        let profit_fee = (parseFloat(data?.amount) * (parseFloat(data?.profit_rate_config) / 100) * (parseFloat(data?.max_allowed_tenor) / 30) );
        profit_fee += profit_fee * vat;

        let processing_fee = (parseFloat(data?.amount) * parseFloat(data?.processing_fee_config) / 100);
        processing_fee += processing_fee * vat;

        let document_fee = parseFloat(data?.document_fee_config);
        document_fee += document_fee * vat;

        let due_diligence_fee = parseFloat(data?.due_diligence_fee_config);
        due_diligence_fee += due_diligence_fee * vat;

        let account_setup_fee = parseFloat(data?.account_setup_fee_config);
        account_setup_fee += account_setup_fee * vat;

        setData(data => ({
            ...data,
            'profit_fee': profit_fee,
            'processing_fee': processing_fee,
            'document_fee': document_fee,
            'due_diligence_fee': due_diligence_fee,
            'account_setup_fee': account_setup_fee,
        }));
    }

    useEffect(() => {

        setDisabled(business.selected.status !== 'APPROVED');

        setData(data => ({
            ...data,
            'start_date': moment().set({'hour':0, 'minute':0, 'second':0}).format('YYYY-MM-DD'),
            'service_id': service.service_id,
            'amount': service.amount ?? 0,
            'service_type': service.service_type,
            'profit_rate_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'profit_rate')?.config_value : 0,
            'processing_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'processing_fee')?.config_value : 0,
            'document_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'document_fee')?.config_value : 0,
            'due_diligence_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'due_diligence_fee')?.config_value : 0,
            'account_setup_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'account_setup_fee')?.config_value : 0,
            'no_of_repayments': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'no_of_repayments')?.config_value : 0,
            'max_allowed_tenor': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'max_allowed_tenor')?.config_value : 0,
        }));
    },[]);

    const handleSubmit = async () => {

        setDisabled(true);

        data['service_type'] = 'RECEIVABLE_DISCOUNTING';
        data['business_id'] = business.selected.business_id;
        data['end_date'] = moment(data.start_date).add(data?.max_allowed_tenor, 'days').format('YYYY-MM-DD');
        data['repayment_period'] = data?.max_allowed_tenor;
        data['repayment_frequency'] = data?.no_of_repayments;
        data['fee_included'] = data['fee_included'] ?? 0;
        data['fee_clubbed'] = data['fee_clubbed'] ?? 0;
        data['fees'] = {
            'profit_fee': data['profit_fee'],
            'processing_fee': data['processing_fee'],
            'document_fee': data['document_fee'],
            'due_diligence_fee': data['due_diligence_fee'],
            'account_setup_fee': data['account_setup_fee'],
        }

        //Remove the below data points as these will be treated under data['fees']
        await addLoan(dispatch, data).then((res)=>{
            setResponse(res);
            //After the loan is created attach the loan with the invoice.
            if(res['success']){
                dispatch(setFsModal({component:null, state:false}));
                navigate('/business-finance/' + res['success'].data.loan_id);
            }
        });
    };

    const handleSave = async () => {

        setDisabled(true);
        let payload = {}
        payload['service_id'] = data['service_id'];
        payload['amount'] = data['amount'];
        payload['status'] = "VERIFIED";

        await updateService(dispatch, payload).then((res)=>{
            setResponse(res);
            if(res['success']){
                dispatch(setFsModal({component:null, state:false}))
            }
        });
    }

    return (
        <>
            { data !== undefined &&
            <Dialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={true}
                onClose={ () => dispatch(setFsModal({component:null, state:false})) }
            >
                <DialogTitle>Request Details</DialogTitle>
                { business.selected.status === 'APPROVED' ?
                    <DialogContent>
                        { data &&
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction={'column'} spacing={2} sx={{mt:1}}>
                                        <TextField
                                            fullWidth
                                            label="Request Amount"
                                            name="amount"
                                            defaultValue={data?.amount}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={loans?.error?.amount}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <MuiDatepicker setDisabled={setDisabled} disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')} label={"Start Date"} name="start_date" data={data} state={setData} />

                                        <TextField
                                            fullWidth
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            label="Tenor"
                                            name="max_allowed_tenor"
                                            defaultValue={data?.max_allowed_tenor}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={loans?.error?.max_allowed_tenor}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Forfaiting/ Discounting Fee % (profit %)"
                                            name="profit_rate_config"
                                            defaultValue={data?.profit_rate_config}
                                            inputlabelprops={{ shrink: true }}
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            helperText={loans?.error?.profit_rate}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Processing Fee"
                                            name="processing_fee_config"
                                            defaultValue={data?.processing_fee_config}
                                            inputlabelprops={{ shrink: true }}
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            helperText={loans?.error?.processing_fee_config}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <TextField
                                            fullWidth
                                            label="No of Repayment"
                                            name="no_of_repayments"
                                            defaultValue={data?.no_of_repayments}
                                            inputlabelprops={{ shrink: true }}
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            helperText={loans?.error?.no_of_repayments}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Documentation Fee"
                                            name="document_fee_config"
                                            defaultValue={data?.document_fee_config}
                                            inputlabelprops={{ shrink: true }}
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            helperText={loans?.error?.document_fee}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Due Diligence Fee"
                                            name="due_diligence_fee_config"
                                            defaultValue={data?.due_diligence_fee_config}
                                            inputlabelprops={{ shrink: true }}
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            helperText={loans?.error?.due_diligence_fee}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Account Setup Fee"
                                            name="account_setup_fee_config"
                                            defaultValue={data?.account_setup_fee_config}
                                            inputlabelprops={{ shrink: true }}
                                            disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')}
                                            helperText={loans?.error?.account_setup_fee}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <FormGroup>
                                            <FormControlLabel disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')} control={<Checkbox name="fee_included" onChange={(e) => handleChange(e)} />} label="Include fee in Request" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormControlLabel disabled={!((auth.user.permissions).includes('issue-loan') || auth.user.role_type === 'ADMIN')} control={<Checkbox name="fee_clubbed" onChange={(e) => handleChange(e)} />} label="Service Charge Single Payment" />
                                        </FormGroup>

                                        {showFees &&
                                            <>
                                                <Divider>Fees</Divider>
                                                <List>
                                                    <ListItem>
                                                        <ListItemText primary={"Forfaiting / Discounting Fee "} secondary={currencyFormatter.format(data?.profit_fee)}/>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={"Processing Fees"} secondary={currencyFormatter.format(data?.processing_fee)}/>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={"Documentation Fee"} secondary={currencyFormatter.format(data?.document_fee)}/>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={"Due Diligence Fee"} secondary={currencyFormatter.format(data?.due_diligence_fee)}/>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={"Account Setup Fee"} secondary={currencyFormatter.format(data?.account_setup_fee)}/>
                                                    </ListItem>
                                                </List>
                                            </>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    :
                    <DialogContent>
                        <Typography>Loan cannot be issued as the business is not Approved.</Typography>
                    </DialogContent>
                }
                <DialogActions sx={{p:2, justifyContent:"space-between"}}>
                    { data['service_type'] !== 'INVOICE_DISCOUNTING' ?
                    <Button variant={"contained"} color={"secondary"} target={"_blank"} component={Link} to={ "/business/" + business.selected.business_id }>Show Business</Button>
                        :
                    <Button variant={"contained"} color={"secondary"} target={"_blank"} component={Link} to={ "/invoices/" + service?.invoice_id }>Show Invoice</Button>
                    }
                    <Stack direction={"row"} spacing={2}>
                        <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                        <Button
                            disabled={disabled}
                            variant={"contained"}
                            color={'info'}
                            onClick={() => {
                                if (auth.user.role_type === 'ADMIN' || auth.user.permissions.includes('issue-loan')) {
                                    showFees ? handleSubmit() : handleReviewBtn();
                                } else if (auth.user.permissions.includes('update-service')) {
                                    showFees ? handleSave() : handleReviewBtn();
                                }
                            }}
                        >
                            {showFees ? "Save" : "Review"}
                        </Button>
                    </Stack>
                </DialogActions>
                { response && <Toastr response={response} /> }
            </Dialog>
            }
        </>
    );
}