import React from 'react'
import {Container, Grid} from "@mui/material";
import { useSelector} from "react-redux";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import {InvoiceMetricsCard} from "./Client/InvoiceMetricsCard";
import {CustomerMetricsCard} from "./Client/CustomerMetricsCard";
import {LoanMetricsCard} from "./Client/LoanMetricsCard";
import {ServiceRequestsPanel} from "../../Services/Components/Panels/ServiceRequestsPanel";
import {UpcomingPayments} from "../../Accounts";
import {ServiceRequestMetricsCard} from "./Client/ServiceRequestMetricsCard";
import {MyInvoicesPanel} from "../../Invoices";
import {MyCustomersPanel} from "../../Customers";

export const DashboardAdmin = () => {

    const auth = useSelector(store => store.auth);

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Grid spacing={4} container direction="row" justifyContent="top" alignItems="center">
                    <Grid spacing={2} item container direction="row">
                        <Grid item xs={12}>
                            <IconBreadcrumbs items={'DASHBOARD'} />
                        </Grid>
                    </Grid>
                    <>
                        <Grid spacing={2} item container direction="row" justifyContent="top">

                            <Grid item md={2} xs={6}>
                                <InvoiceMetricsCard />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <InvoiceMetricsCard />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <InvoiceMetricsCard />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <CustomerMetricsCard />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <LoanMetricsCard />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <ServiceRequestMetricsCard />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} item container direction="row" justifyContent="top">
                            <Grid item md={5} xs={12}>
                                <UpcomingPayments />
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <ServiceRequestsPanel />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <MyInvoicesPanel />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <MyCustomersPanel />
                            </Grid>
                        </Grid>
                    </>

                </Grid>
            </Container>
        </>
    );
}
