import * as React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { fetchRoles } from "../../Roles";
import {Chip, Stack} from "@mui/material";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";


export default function RoleTable(props) {


    const dispatch = useDispatch();
    const items = useSelector((store) => store.roles);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        page: 0,
        keyword:'',
    });

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchRoles(dispatch, filters).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const columns = React.useMemo(
        () => [
            { field: 'role_name', headerName:'Role Name', type: 'string', flex:1, minWidth:180 },
            { field: 'role_level', headerName:'Role Level', align:'center', headerAlign:'center', type: 'string', flex:1, minWidth: 80 },
            { field: 'is_active', headerName:'Status', type: 'string', flex:1, minWidth: 80,
                renderCell: (params) => ( params.value === 1 ? <Chip label="Active" size="small" color="info" /> : <Chip label="Suspend" size="small" color="error" />)
            },
            { field: 'permissions', headerName:'Permissions', type: "string", flex:1, minWidth: 500,
                renderCell: (params) => (
                    <Stack direction="row" spacing={0.25}>
                        {(params?.row?.permissions)?.map((p) => (
                            <Chip label={p.name} key={p.permission_id} />
                        ))}
                    </Stack>
                )
            },
            { field: 'users_count', align:'center', headerAlign:'center', headerName:'Users', type: 'string', flex:1, minWidth: 80 },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', flex:1, minWidth: 150,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            { field: 'updated_at', headerName:'Updated At', type: 'dateTime', flex:1, minWidth: 150,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth: 120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={props.deleteItem(params.id)}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={props.editItem(params.id)}
                    />,
                ],
            },
        ],
        [props.deleteItem, props.editItem],
    );

    return (
        <div style={{ flex: '1 1 100%', width: '100%', borderTop:'1px solid #ddd' }}>
            <DataGridPro
                autoHeight={true}
                getRowId={(row) => row.role_id}
                columns={columns}
                rows={items.list}
                sx={{ border:'none'}}
                rowCount={response?.success?.data?.total ?? 0}
                pageSizeOptions={[5, 10, 25, 100]}
                pagination
                loading={isLoading}
                paginationMode="server"
                paginationModel={filters}
                onPaginationModelChange={(params) => setFilters({...filters, page:params.page, pageSize: params.pageSize})}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'group', sort: 'asc' }],
                    },
                    pinnedColumns: {  right: ['actions'] },
                    pagination: { paginationModel: { ...filters } }
                }}
            />
        </div>
    );
}
