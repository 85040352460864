import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Container, Grid, Paper, StepContent, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "react-redux";
import {useRef, useState} from "react";

import OwnershipDetailStep from "./WizardSteps/OwnershipDetailStep";
import BankDetailStep from "./WizardSteps/BankDetailStep";
import FinancialDetailStep from "./WizardSteps/FinancialDetailStep";
import BankBorrowingStep from "./WizardSteps/BankBorrowingStep";
import CreditCardStep from "./WizardSteps/CreditCardStep";
import ChequesDetailsStep from "./WizardSteps/ChequesDetailsStep";
import OtherBorrowingStep from "./WizardSteps/OtherBorrowingStep";
import VatReturnStep from "./WizardSteps/VatReturnStep";
import WpsDetailStep from "./WizardSteps/WpsDetailStep";
import CompanyLocationStep from "./WizardSteps/CompanyLocationStep";
import CustomerSupplierStep from "./WizardSteps/CustomerSupplierStep";
import CustomerStep from "./WizardSteps/CustomerStep";
import {CompanyDetailForm} from "./Forms/CompanyDetailForm";

import {
    fetchBankDetails,
    fetchBankBorrowings,
    fetchCreditCards,
    fetchPersonalBorrowings,
    fetchFinancials,
    fetchOwnership,
    fetchCheques,
    fetchVatReturns,
    fetchWpsDetails,
    fetchAddresses,
    fetchCustomerSuppliers,
    updateBusinessStatus, fetchCustomers,
    BusinessSubmitModal
} from "../index";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Toastr from "../../Components/Common/Toastr";
import { setFsModal } from "../../../Setup/Redux/ConfigAction";

const steps = [
    {"step":1,"name":'Company Details',"desc":"Comprehensive Insights about the Company","form":"business-details", hide: false},
    {"step":2,"name":'Ownership Details',"desc":"Provide details of partners/investors","form":"partners", hide: false},
    {"step":3,"name":'Financial Performance',"desc":"Mention last two years audit/in-house key financials numbers & interim YTD report","form":"financials", hide: false},
    {"step":4,"name":'Top Customers & Suppliers',"desc":"Provide information on your top 5 customers and suppliers","form":"top-customers-suppliers", hide: false},
    {"step":5,"name":'Bank Accounts',"desc":"Provide details of your bank accounts for your business transactions", hide: false},
    {"step":6,"name":'Bank Borrowings',"desc":"Complete the following data for each Bank Borrowing", hide: false},
    //{"step":7,"name":'Credit Cards',"desc":"Essential details for personal/corporate credit-card","form":"credit-cards", hide: true},
    //{"step":8,"name":'Other Borrowings',"desc":"Non-Institutional Borrowing: Provide Details of loans from family, friends, and others (if applicable)","form":"personal-borrowings", hide: true},
    {"step":9,"name":'Returned Cheques',"desc":"Provide details of any returned cheques (if applicable)","form":"cheques", hide: false},
    {"step":10,"name":'VAT Return Filings',"desc":"Provide your VAT returns filing information for last 4 quarters","form":"vat-returns", hide: false},
    {"step":11,"name":'WPS Details',"desc":"Provide details of your salary payment for the last 3 months","form":"wps-details", hide: false},
    //{"step":12,"name":'Company Locations',"desc":"Provide your company addresses (Office / Warehouse)","form":"addresses", hide: true},
    {"step":13,"name":'Invoice Discounting',"desc":"Provide details of customers for Invoice Discounting","form":"customers", hide: false},
];

export function Wizard() {

    const theme = useTheme();
    const childRef = useRef();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const business = useSelector((store) => store.business);
    const dispatch = useDispatch();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [response, setResponse] = useState(false);
    const isStepOptional = (step) => {
        return step === 100;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleSubmit = async () =>{
        dispatch(setFsModal({component:<BusinessSubmitModal />, state:true}));
        setShowConfirmModal(true);
    }
    const handleNext = async () => {

        let r = {};
        if(steps[activeStep].form === 'business-details'){
            await childRef.current.handleSubmit().then((res)=>{
                r = res
                setResponse(res)
            });
        }

        if(r !== false && !r['error']){
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
        else{
            return true;
        }


        switch (steps[activeStep + 1].name) {
            case "Ownership Details":
                fetchOwnership(dispatch, business.selected.business_id);
                break;
            case "Financial Performance":
                fetchFinancials(dispatch, business.selected.business_id);
                break;
            case "Bank Accounts":
                fetchBankDetails(dispatch, business.selected.business_id);
                break;
            case "Bank Borrowings":
                fetchBankBorrowings(dispatch, business.selected.business_id);
                break;
            case "Credit Cards":
                fetchCreditCards(dispatch, business.selected.business_id);
                break;
            case "Other Borrowings":
                fetchPersonalBorrowings(dispatch, business.selected.business_id);
                break;
            case "Returned Cheques":
                fetchCheques(dispatch, business.selected.business_id);
                break;
            case "VAT Return Filings":
                fetchVatReturns(dispatch, business.selected.business_id);
                break;
            case "WPS Details":
                fetchWpsDetails(dispatch, business.selected.business_id);
                break;
            case "Company Locations":
                fetchAddresses(dispatch, business.selected.business_id);
                break;
            case "Top Customers & Suppliers":
                fetchCustomerSuppliers(dispatch, {business_id:business.selected.business_id});
                break;
            case "Invoice Discounting":
                fetchCustomers(dispatch, {for_invoice_discounting:1, business_id:business.selected.business_id});
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleJump = (index) => {
        //setActiveStep(index);
    };

    const handleExit = async () => {
        if(steps[activeStep].form === 'business-details'){
            await childRef.current.handleSubmit().then((res)=>{
                navigate('/dashboard');
            });
        }
        navigate('/dashboard');
    }

    return (
        <>
            <Container maxWidth="lg" sx={{px:0}}>
                <Grid container spacing={2} elevated={3}>
                    <Grid item xs={12} md={3}>
                        <Paper sx={{ display:'flex', flexDirection:'column', flex:1, p:2}} >
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.name} onClick={()=>{handleJump(index)}}>
                                        <StepLabel
                                            optional={
                                                index === 12 ? (
                                                    <Typography variant="caption">(Customers)</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.name}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.descs}</Typography>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            <Button onClick={handleExit} color={'warning'} variant={"contained"} sx={{mt:4}}>
                                Save & Continue Later
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={9} >
                        <Paper sx={{ display:'flex', flexDirection:'column', flex:1, p:2}}>
                            {activeStep === steps.length ? (
                                <>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you're finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ mt: 0, mb: 1, }} variant="h6">{steps[activeStep].name}</Typography>
                                    <Typography sx={{ mt: 0, mb: 1, }} color={"gray"} variant="p">{steps[activeStep].desc}</Typography>
                                    <Divider sx={{m:2}}></Divider>
                                    {
                                        (()=>{
                                            switch (steps[activeStep].name) {
                                                case 'Company Details':
                                                    return <CompanyDetailForm ref={childRef} setResponse={setResponse} business={business} />
                                                case 'Ownership Details':
                                                    return <OwnershipDetailStep business={business} />
                                                case 'Financial Performance':
                                                    return <FinancialDetailStep business={business} />
                                                case 'Bank Accounts':
                                                    return <BankDetailStep business={business} />
                                                case 'Bank Borrowings':
                                                    return <BankBorrowingStep business={business} />
                                                case 'Credit Cards':
                                                    return <CreditCardStep business={business} />
                                                case 'Other Borrowings':
                                                    return <OtherBorrowingStep business={business} />
                                                case 'Returned Cheques':
                                                    return <ChequesDetailsStep business={business} />
                                                case 'VAT Return Filings':
                                                    return <VatReturnStep business={business} />
                                                case 'WPS Details':
                                                    return <WpsDetailStep business={business} />
                                                case 'Company Locations':
                                                    return <CompanyLocationStep />
                                                case 'Top Customers & Suppliers':
                                                    return <CustomerSupplierStep business={business} />
                                                case 'Invoice Discounting':
                                                    return <CustomerStep business={business} />
                                                default:
                                                    return false
                                            }
                                        })()
                                    }
                                    <Divider sx={{m:2}}></Divider>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>Back</Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {isStepOptional(activeStep) && (
                                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                Skip
                                            </Button>
                                        )}


                                            <Button onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext} color={activeStep === steps.length - 1 ? 'info':'primary'} variant={"contained"}>
                                                {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
                                            </Button>

                                    </Box>
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
