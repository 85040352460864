import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Card, CardContent, CardHeader, List, ListItem, IconButton, ListItemText, Typography, Collapse } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import {removeDocument} from "../Redux/DocumentAction";
import {Panel, PanelList} from "../../Components";

export default function DocumentsList({ entityId, tab, setResponse }) {

    const documents = useSelector((store) => store.documents.files) || [];
    const [isExpanded, setIsExpanded] = useState(true);
    const auth = useSelector((store) => store.auth);
    const dispatch = useDispatch();

    const handleRemoveImage = async (e) => {
        const document_id = e.currentTarget.dataset.id;
        await removeDocument(dispatch, setResponse, document_id).then((res) => {
            setResponse(res);
        });
    };

    const filteredItems = documents
        .filter(i => i.entity_name === tab && i.entity_id === entityId && !['export','report'].includes(i.type))
        .sort((a, b) => a.type.localeCompare(b.type))
        .map(i => (
            <ListItem key={i.document_id} secondaryAction={
                (['delete-business-documents'].some(i => auth.user.permissions.includes(i)) || auth.user.role_type === 'ADMIN') &&
                <IconButton onClick={handleRemoveImage} data-id={ i?.document_id } >
                    <DeleteIcon />
                </IconButton>
            }>
                <ListItemText
                    primary={<Typography sx={{ textTransform: 'capitalize' }}>{i.type.replaceAll('_', ' ')}</Typography>}
                    secondary={<Link to={process.env.REACT_APP_BASE_URL + 'document/' + i.document_id} target={"_blank"} rel="noopener noreferrer">{i.doc_name}</Link>}
                />
            </ListItem>
        ));

    const DocumentList = styled(List)(({ theme }) => ({
        display:'flex',
        flexDirection:'column',
        '& .MuiListItem-root:lastChild': {
            borderBottom:'none',
        },
        '& .MuiListItem-root': {
            justifyContent:'space-between',
            padding: '8px!important',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',  // Aligns items vertically
            maxWidth: '100%',
            overflow: 'hidden',
            marginBottom: '1px',
            paddingBottom: '0px!important',
            backgroundColor:'#f8f8f8',
            borderBottom:'1px solid #ddd',
            '& .MuiSvgIcon-root': {
                width: '20px',
            },
            '& .MuiListItemText-root':{
                margin: '0px',
                '& p:first-child':{
                    lineHeight: '1',
                    fontSize:'0.8rem',
                    paddingLeft:'8px'
                },
                '& a': {
                    flex:1,
                    fontSize: '12px',
                    paddingLeft: '8px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginLeft: '0px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                    width: '90%',
                    display:'inline-block',
                },
            },
            '& .MuiIconButton-root': {
                padding: '0px!important',
                marginLeft: '0',
            }
        }
    }));

    return (
        <>
            <Panel>
                <CardHeader title="Documents"
                    action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }
                />
                <Collapse in={isExpanded}>
                    <CardContent sx={{ p:0 }}>
                        {filteredItems.length > 0 ?
                            <DocumentList dense={false} sx={{ padding: 0 }}>
                                {filteredItems}
                            </DocumentList>
                        :
                            <Typography sx={{ padding: '8px' }}>No Documents Available</Typography>
                        }
                    </CardContent>
                </Collapse>
            </Panel>
        </>
    );
}
