import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {  deleteInvoice, } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";

export const DeleteInvoiceModal = () => {

    const dispatch = useDispatch();
    const invoice = useSelector((store) => store.invoices.selected);
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        setDisabled(true);
        await deleteInvoice(dispatch, invoice.invoice_id).then((res) => {
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setFsModal({component:null, state:false}));
            }
        });
    };

    return(
        <>
            <DialogTitle>Delete Invoice</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to remove this invoice?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Delete Invoice</Button>
            </DialogActions>
        </>
    )
}