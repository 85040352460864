import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 FETCH CHEQUE
 ****************************************/
export const fetchConfigSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_CONFIG_SUCCESS,
    payload: payload
})

export const fetchConfigFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_CONFIG_FAILED,
    payload: payload
})

export const fetchConfig = async (dispatch, id) => {

    await axiosPrivate.get('business/'+ id + '/config').then((response) => {
        dispatch(fetchConfigSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchConfigFailed(error?.response));
    });
}
