const CONST = {
    'RESET_ALL':'USERS/RESET_ALL',
    'SET_SELECTED_USER':'USERS/SET_SELECTED_USER',
    'SET_SELECTED_USER_SUCCESS':'USERS/SET_SELECTED_USER_SUCCESS',
    'FETCH_USER_SUCCESS':'USERS/FETCH_USER_SUCCESS',
    'FETCH_USER_FAILED':'USERS/FETCH_USER_FAILED',
    'FETCH_USERS_SUCCESS':'USERS/FETCH_USERS_SUCCESS',
    'FETCH_USERS_FAILED':'USERS/FETCH_USERS_FAILED',
    'REGISTER_USER_SUCCESS':'USERS/REGISTER_USER_SUCCESS',
    'REGISTER_USER_FAILED':'USERS/REGISTER_USER_FAILED',
    'CAPTURE_LEAD_SUCCESS':'USERS/CAPTURE_LEAD_SUCCESS',
    'CAPTURE_LEAD_FAILED':'USERS/CAPTURE_LEAD_FAILED',
    'LOAD_USERS':'USERS/LOAD_USERS',
    'LOAD_USERS_FAILED':'USERS/LOAD_USERS_FAILED',
    'VERIFY_EMAIL_SUCCESS':'USERS/VERIFY_EMAIL_SUCCESS',
    'VERIFY_USER_FAILED':'USERS/VERIFY_USER_FAILED',
    'FORGOT_PASSWORD_SUCCESS':'USERS/FORGOT_PASSWORD_SUCCESS',
    'FORGOT_PASSWORD_FAILED':'USERS/FORGOT_PASSWORD_FAILED',
    'FORGOT_PASSWORD_LOADING':'USERS/FORGOT_PASSWORD_LOADING',
    'RESET_PASSWORD_FAILED':'USERS/RESET_PASSWORD_FAILED',
    'RESET_PASSWORD_LOADING':'USERS/RESET_PASSWORD_LOADING',
    'RESET_PASSWORD_SUCCESS':'USERS/RESET_PASSWORD_SUCCESS',
    'SEND_VERIFICATION_EMAIL_SUCCESS':'USERS/SEND_VERIFICATION_EMAIL_SUCCESS',
    'SEND_VERIFICATION_EMAIL_FAILED':'USERS/SEND_VERIFICATION_EMAIL_FAILED',
    'ADD_USER_SUCCESS':'USERS/ADD_USER_SUCCESS',
    'ADD_USER_FAILED':'USERS/ADD_USER_FAILED',
    'UPDATE_USER_SUCCESS':'USERS/UPDATE_USER_SUCCESS',
    'UPDATE_USER_FAILED':'USERS/UPDATE_USER_FAILED',
    'DELETE_USER_SUCCESS':'USERS/DELETE_USER_SUCCESS',
    'DELETE_USER_FAILED':'USERS/DELETE_USER_SUCCESS',
    'CHANGE_PASSWORD_SUCCESS':'USERS/CHANGE_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_FAILED':'USERS/CHANGE_PASSWORD_FAILED',
    "ADD_USER_SERVICE_SUCCESS":'USERS/ADD_USER_SERVICE_SUCCESS',
    "ADD_USER_SERVICE_FAILED":'USERS/ADD_USER_SERVICE_FAILED',
}

export default CONST;