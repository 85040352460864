import * as React from 'react';
import {Alert} from "@mui/material";
import {useEffect, useState} from "react";

export default function CustomAlert(props) {


    const status = { '201':'success', '200':'success', '422':'warning', '403':'error' }
    const [msg, setMsg] = useState({});

    useEffect(() => {

        setMsg({
            'code':props?.business?.code,
            'message':props?.business?.message,
        });

    }, [props.business])

    useEffect(() => {

        setMsg({
            'code':'',
            'message':'',
        });
    }, [])

    return (
        <>
            {
                msg.code && msg.message !== ['Ownership Added Successfully'] ?
                (<Alert severity="error" color={status[msg.code]} sx={{ mb:3}}>
                    { msg.message }
                </Alert>)
                :''
            }
        </>
    );
}


