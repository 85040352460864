import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


export const proceed = (payload) => ({
    type: CONST.CLEAR_PROCEED_STATE,
    payload:payload,
})
export const clearErrors = (payload) => ({
    type: CONST.CLEAR_ERROR_STATE,
    payload:payload,
})
export const setSelectedInvoice = (payload) => ({
    type: CONST.SET_SELECTED_INVOICE,
    payload: payload
})

/****************************************
 FETCH INVOICE
 ****************************************/
export const fetchInvoiceSuccess = (payload) => ({
    type: CONST.FETCH_INVOICE_SUCCESS,
    payload: payload
})

export const fetchInvoiceFailed = (payload) => ({
    type: CONST.FETCH_INVOICE_FAILED,
    payload: payload
})

export const fetchInvoice = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.get('invoice/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchInvoiceFailed(error?.response));
    });
    return res;
}


/****************************************
 FETCH INVOICES
 ****************************************/
export const fetchInvoicesSuccess = (payload) => ({
    type: CONST.FETCH_INVOICES_SUCCESS,
    payload: payload
})

export const fetchInvoicesFailed = (payload) => ({
    type: CONST.FETCH_INVOICES_FAILED,
    payload: payload
})

export const fetchInvoices = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.get('invoice', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchInvoicesSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchInvoicesFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD Invoice
 ****************************************/
export const addInvoiceSuccess = (payload) => ({
    type: CONST.ADD_INVOICE_SUCCESS,
    payload: payload
})

export const addInvoiceFailed = (payload) => ({
    type: CONST.ADD_INVOICE_FAILED,
    payload: payload
})

export const addInvoice = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('invoice', data).then((response) => {
        res['success'] = response;
        dispatch(addInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addInvoiceFailed(error?.response));
    });
    return res;
}


/****************************************
 Update Invoice - Details
 ****************************************/
export const updateInvoiceSuccess = (payload) => ({
    type: CONST.UPDATE_INVOICE_SUCCESS,
    payload: payload
})

export const updateInvoiceFailed = (payload) => ({
    type: CONST.UPDATE_INVOICE_FAILED,
    payload: payload
})

export const updateInvoice = async (dispatch, data) => {

    data['is_active'] = 1;
    let res = {}
    await axiosPrivate.patch('invoice/' + data['invoice_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateInvoiceFailed(error?.response));
    });
    return res;
}
/****************************************
 Verify a Invoice
 ****************************************/
export const verifyInvoiceSuccess = (payload) => ({
    type: CONST.VERIFY_INVOICE_SUCCESS,
    payload: payload
})

export const verifyInvoiceFailed = (payload) => ({
    type: CONST.VERIFY_INVOICE_FAILED,
    payload: payload
})

export const verifyInvoice = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('invoice/verify/' + data['invoice_id'], data).then((response) => {
        res['success'] = response;
        dispatch(verifyInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(verifyInvoiceFailed(error?.response));
    });
    return res;
}
/****************************************
 Reject a Invoice
 ****************************************/
export const rejectInvoiceSuccess = (payload) => ({
    type: CONST.REJECT_INVOICE_SUCCESS,
    payload: payload
})

export const rejectInvoiceFailed = (payload) => ({
    type: CONST.REJECT_INVOICE_FAILED,
    payload: payload
})

export const rejectInvoice = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('invoice/reject/' + data['invoice_id'], data).then((response) => {
        res['success'] = response;
        dispatch(rejectInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(rejectInvoiceFailed(error?.response));
    });
    return res;
}
/****************************************
 Return a Invoice
 ****************************************/
export const returnInvoiceSuccess = (payload) => ({
    type: CONST.RETURN_INVOICE_SUCCESS,
    payload: payload
})

export const returnInvoiceFailed = (payload) => ({
    type: CONST.RETURN_INVOICE_FAILED,
    payload: payload
})

export const returnInvoice = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('invoice/return/' + data['invoice_id'], data).then((response) => {
        res['success'] = response;
        dispatch(returnInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(returnInvoiceFailed(error?.response));
    });
    return res;
}

/****************************************
 Confirm a Invoice
 ****************************************/
export const confirmInvoiceSuccess = (payload) => ({
    type: CONST.CONFIRM_INVOICE_SUCCESS,
    payload: payload
})

export const confirmInvoiceFailed = (payload) => ({
    type: CONST.CONFIRM_INVOICE_FAILED,
    payload: payload
})

export const confirmInvoice = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('invoice/confirm/' + data['invoice_id'], data).then((response) => {
        res['success'] = response;
        dispatch(confirmInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(confirmInvoiceFailed(error?.response));
    });
    return res;
}

/****************************************
 Approve a Invoice
 ****************************************/
export const approveInvoiceSuccess = (payload) => ({
        type: CONST.APPROVE_INVOICE_SUCCESS,
        payload: payload
    })

export const approveInvoiceFailed = (payload) => ({
    type: CONST.APPROVE_INVOICE_FAILED,
    payload: payload
})

export const approveInvoice = async ({dispatch, data}) => {
    //TODO check return
    console.log(data)
    await axiosPrivate.patch('invoice/approve/' + data['invoice_id'], data).then((response) => {
        dispatch(approveInvoiceSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(approveInvoiceFailed(error?.response));
    });
    return data['invoice_id'];
}
/****************************************
 DELETE Invoice
 ****************************************/
export const deleteInvoiceSuccess = (payload) => ({
    type: CONST.DELETE_INVOICE_SUCCESS,
    payload: payload
})

export const deleteInvoiceFailed = (payload) => ({
    type: CONST.DELETE_INVOICE_FAILED,
    payload: payload
})

export const deleteInvoice = async (dispatch, invoice_id) => {
    let res = {};
    await axiosPrivate.delete('invoice/' + invoice_id).then((response) => {
        res['success'] = response;
        dispatch(deleteInvoiceSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteInvoiceFailed(error?.response));
    });
    return res;
}


/****************************************
 Submit Invoice - For Approval Request
 ****************************************/
export const updateInvoiceStatusSuccess = (payload) => ({
    type: CONST.UPDATE_INVOICE_SUCCESS,
    payload: payload
})

export const updateInvoiceStatusFailed = (payload) => ({
    type: CONST.UPDATE_INVOICE_FAILED,
    payload: payload
})

export const updateInvoiceStatus = async (dispatch, data) => {
    let res;
    await axiosPrivate.patch('invoice/' + data['invoice_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateInvoiceStatusSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateInvoiceStatusFailed(error?.response));
    });
    return res;
}



/****************************************
 Upload Documents - COMMON to all entity
 ****************************************/
export const uploadDocumentSuccess = (payload) => {
    return {
    type: CONST.UPLOAD_DOCUMENT_SUCCESS,
    payload: payload
}}

export const uploadDocumentFailed = (payload) => ({
    type: CONST.UPLOAD_DOCUMENT_FAILED,
    payload: payload
})
export const uploadDocument = async (dispatch, props, file) => {

    let res = {}
    let payload = props.data;

    let data = new FormData();
    data.append('file', file);
    data.append('invoice_id', props.data.invoice_id);
    data.append('entity_id', props.entity_id);
    data.append('entity_name', props.entity_name);
    data.append('type', props.name);
    data.append('doc_size', file.size);

    //data.append('owner_id', '3939');

    let config = {
        method: 'post',
        maxBodyLength: 3000,
        url: 'document',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'content-type': file.type,
        },
        data : data
    };
    await axiosPrivate(config).then((response) => {
        console.log(response);
        res['success'] = response;

        dispatch(uploadDocumentSuccess(response));
        console.log("reached2");;
        payload[props.name] = response?.data?.document_id;
        console.log(payload);
        props.updateFunction(dispatch, payload);
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(uploadDocumentFailed(error?.response));
    });
    return res;
}


/****************************************
 Remove Documents - COMMON to all entity
 ****************************************/
export const removeDocumentSuccess = (payload) => ({
    type: CONST.REMOVE_DOCUMENT_SUCCESS,
    payload: payload
})

export const removeDocumentFailed = (payload) => ({
    type: CONST.REMOVE_DOCUMENT_FAILED,
    payload: payload
})
export const removeDocument = async (dispatch, props, document_id) => {

    let res = {}
    let payload = props.data;

    await axiosPrivate.delete('document/' + document_id).then((response) => {
        res['success'] = response;
        dispatch(removeDocumentSuccess(document_id));
        payload[props.name] = null;
        props.updateFunction(dispatch, payload);
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(removeDocumentFailed(error?.response));
    });
    return res;
}

