import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {OwnershipForm} from "../Forms/OwnershipForm";
import {Grid, Stack, useMediaQuery} from "@mui/material";
import { addOwnership, deleteOwnership, clearErrors, proceed, setSelectedOwnership,OwnershipDeleteModal } from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {countries} from "../../../../Setup/Config";

export default function OwnershipDetailStep() {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.ownership).length > 0 ? business.ownership.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setFsModal({component:null, state:false}))
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addOwnership(dispatch, {business_id:business.selected.business_id});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedOwnership(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<OwnershipForm business={business} />, state:true}))
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<OwnershipDeleteModal />, state:true}))
        dispatch(setSelectedOwnership(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'name', headerName:'Partner Name', type: 'string', flex:1, minWidth: 200},
            { field: 'type', headerName:'Type', type: 'string', flex:1, minWidth: 100},
            { field: 'holding', headerName:'Holding', type: 'string', flex:1, minWidth: 100 },
            { field: 'nationality', headerName:'Nationality', type: 'string', flex:1, minWidth:200,
                valueFormatter: ({value}) => value && (countries.filter((obj) => obj.iso3 === value))[0].name
            },
            { field: 'passport_no', headerName:'Passport No', type: 'string', flex:1, minWidth: 120 },
            { field: 'aecb_score', headerName:'AECB Score', type: 'string', flex:1, minWidth: 120 },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth:120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    rowHeight={50}
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={objects} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Partner</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}