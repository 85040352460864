import React from 'react'
import { Routes as Switch, Route } from 'react-router-dom'
import { Dashboard } from "../Modules/Dashboard";
import {Business, BusinessView} from "../Modules/Business";
import { Profile } from "../Modules/Profile";
import { Settings } from "../Modules/Settings";
import { Users } from "../Modules/Users";
import { Permissions } from "../Modules/Permissions";
import { Roles } from "../Modules/Roles";
import Login from '../Modules/Auth/Components/Login'
import { Register } from "../Modules/Auth";
import { ForgotPassword } from "../Modules/Auth";
import  EmailVerification  from "../Modules/Auth/Components/EmailVerification";

import PrivateRoutes from './PrivateRoutes'
import {RegisterVerify,ForgotPasswordReset} from "../Modules/Auth";
import {Invoices, InvoiceView} from "../Modules/Invoices";
import {Customers, CustomerView} from "../Modules/Customers";
import {Accounts} from "../Modules/Accounts";

import {Loan, LoanView} from "../Modules/BusinessFinance";
import {PublicRoutes} from "./PublicRoutes";
import {ServiceInvoiceUpload} from "../Modules/Services/Components/ServiceInvoiceUpload";


const AllRoutes = () => {

    return (
        <Switch>
            <Route element={<PrivateRoutes allowedRoles={['ADMIN']}/>} >
                <Route exact path='/permissions' element={<Permissions />}/>
                <Route exact path='/roles' element={<Roles />}/>
                <Route exact path='/users' element={<Users />}/>
            </Route>

            <Route element={<PrivateRoutes allowedRoles={['ADMIN','USER']} />} >

            </Route>

            <Route element={<PrivateRoutes allowedRoles={['ADMIN','USER','CLIENT']} />} >
                <Route exact path='/' element={<Dashboard />}/>
                <Route exact path='/dashboard' element={<Dashboard />}/>
                <Route exact path='/profile' element={<Profile />}/>
                <Route exact path='/settings' element={<Settings />}/>
                <Route exact path='/business' element={<Business />}/>
                <Route exact path='/business/:business_id' element={<BusinessView />}/>

                <Route exact path='/invoices' element={<Invoices />}/>
                <Route exact path='/invoices/:invoice_id' element={<InvoiceView />}/>
                <Route exact path='/customers' element={<Customers />}/>
                <Route exact path='/customers/:customer_id' element={<CustomerView />}/>

                <Route exact path='/accounts' element={<Accounts />}/>

                <Route exact path='/business-finance' element={<Loan />}/>
                <Route exact path='/business-finance/:loan_id' element={<LoanView />}/>

                <Route exact path={'/services/invoice-upload'} element={<ServiceInvoiceUpload />}/>
            </Route>

            <Route element={<PublicRoutes />} >
                <Route exact path='/register' element={<Register/>}/>
                <Route exact path='/forgot-password' element={<ForgotPassword/>}/>
                <Route exact path='/reset-password/:token' element={<ForgotPasswordReset/>}/>
                <Route exact path='/verify/:token' element={<RegisterVerify/>}/>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/email-verification/:token' element={<EmailVerification/>}/>
            </Route>

        </Switch>
    )
}

export { AllRoutes };
