import moment from "moment/moment";
import * as React from "react";

export const checkExpiryAndDisplayDate = (expiryDate) => {
    if (expiryDate) {
        const formattedDate = moment(expiryDate).format("DD/MM/YYYY");
        const currentDate = moment();
        const isExpired = moment(expiryDate).isBefore(currentDate);

        if (isExpired) {
            // If the date is expired, display it in red color
            return <span style={{ color: 'red' }}>{formattedDate}</span>;
        } else {
            // If the date is not expired, display it normally
            return formattedDate;
        }
    } else {
        return "-";
    }
};