import React, {useEffect, useState} from 'react'
import {
    CardContent,
    CardHeader,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {DataGridPro,
    useGridSelector,
    useGridApiContext,
    gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";

import {fetchServices, setSelectedService} from "../../index";
import moment from "moment";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PreviewIcon from '@mui/icons-material/Preview';
import {fetchBusiness} from "../../../Business";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {NewServiceRequestModal} from "../Modals/NewServiceRequestModal";
import {LoanDetail} from "../../../BusinessFinance";
import {IssueLoanModal} from "../../../BusinessFinance";
import {currencyFormatter} from "../../../../Setup/Config";
import {Panel} from "../../../Components";
import {SkeletonTable} from "../../../Components/Common/SkeletonTable";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";
import {useNavigate} from "react-router-dom";
import {Chip} from "../../../Components/Common/Chip";

export const ServiceRequestsPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const services = useSelector((store) => store.services);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const business = useSelector((store) => store.business.list);
    const [filters, setFilters] = React.useState({
        pageSize: '',
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {
        setShowSkeleton(true);
        await fetchServices(dispatch, filters).then((res) => {
            setShowSkeleton(false);
        })
    }

    const viewServiceRequest = async (params) => {
        console.log(params)
        dispatch(setSelectedService(params.row.service_id));
        if(params.row.service_type === 'INVOICE_DISCOUNTING'){
            navigate('/invoices/' + params.row.invoice_id)
        }else {
            await fetchBusiness(dispatch, params.row.business_id).then((res) => {
                dispatch(setFsModal({component: <IssueLoanModal/>, state: true}));
            });
        }
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => row.loan !== null ? <LoanDetail data={row.loan} /> : null,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const serviceTypeCode = {
        'RECEIVABLE_DISCOUNTING': 'RD',
        'INVOICE_DISCOUNTING': 'ID',
    };

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
                ),
            },
            { field: 'business_name', headerName:'Business', type: 'string', minWidth:200, flex: 1,
                valueGetter: (params) => {
                    return params.row?.business?.name;
                }
            },
            { field: 'service_type', headerName: 'Service Type', type: 'string', flex: 1, minWidth: 80,},
            { field: 'amount', headerName:'Amount', type: 'number', flex:1, minWidth: 100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'status', headerAlign:'center', align:'center',  headerName:'Status', type: 'string', flex:1, minWidth:100,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'PENDING':'warning',
                        'VERIFIED':'info',
                        'PROCESSING':'primary',
                        'ACTIVE':'success',
                        'REJECTED':'error',
                        'CANCELLED':'secondary',
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    >
                    </Chip>)
                },
            },
            { field: 'created_at', headerName:'Created At', type: 'string', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).from(),},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth:80,
                getActions: (params) => {
                    return [
                        <GridActionsCellItem
                            hidden={!((["VERIFIED","PENDING"].includes(params.row.status)) && auth.user.role_type !== "CLIENT") }
                            icon={<PreviewIcon sx={{zIndex:-1}} />}
                            label="Edit"
                            data-id={params.id}
                            data-action={"ViewItem"}
                            onClick={ () => viewServiceRequest(params) }
                        />
                    ];
                },
            },
        ],
        [],
    );

    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Service Requests'} action={ (business.filter((value) => value.status === 'APPROVED').length > 0 && auth.user.role_type === "CLIENT")  && <Button size={"small"} onClick={() => dispatch(setFsModal({component:<NewServiceRequestModal />, state:true}))} variant={"outlined"} color={"primary"}>New Request</Button>} />
                <CardContent sx={{p:0}}>
                    { !showSkeleton ?
                        <DataGridPro
                            getRowId={(row) => row.service_id}
                            pageSize={filters.pageSize}
                            pageSizeOptions={[5, 10, 25]}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            rows={services.list || []}
                            sx={{ border:'none'}}
                            rowThreshold={0}
                            getDetailPanelContent={getDetailPanelContent}
                            getDetailPanelHeight={getDetailPanelHeight}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 5 } },
                                pinnedColumns: { right: [ 'status','actions'] },
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                columns:{
                                    columnVisibilityModel:{
                                        actions: auth.user.role_type !== "CLIENT",
                                    }
                                }
                            }}
                            autoHeight={true}
                            pagination={true}
                        />
                        :
                        <SkeletonTable rows={5} cols={4} />
                    }
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );
    const shouldShowToggle = !['PENDING','VERIFIED'].includes(props.row.status);

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};
