const CONST = {
    'CLEAR_PROCEED_STATE':'INVOICE/CLEAR_PROCEED_STATE',
    'CLEAR_ERROR_STATE':'INVOICE/CLEAR_ERROR_STATE',

    'FETCH_APPROVED_BUSINESSES_SUCCESS':'INVOICE/FETCH_APPROVED_BUSINESSES_SUCCESS',
    'FETCH_APPROVED_BUSINESSES_FAILED':'INVOICE/FETCH_APPROVED_BUSINESSES_FAILED',

    'SET_SELECTED_INVOICE':'INVOICE/SET_SELECTED_INVOICE',
    'SET_SELECTED_INVOICE_SUCCESS':'INVOICE/SET_SELECTED_INVOICE_SUCCESS',
    'FETCH_INVOICE_SUCCESS':'INVOICE/FETCH_INVOICE_SUCCESS',
    'FETCH_INVOICE_FAILED':'INVOICE/FETCH_INVOICE_FAILED',
    'FETCH_INVOICES_SUCCESS':'INVOICE/FETCH_INVOICES_SUCCESS',
    'FETCH_INVOICES_FAILED':'INVOICE/FETCH_INVOICES_FAILED',
    'ADD_INVOICE_SUCCESS':'INVOICE/ADD_INVOICE_SUCCESS',
    'ADD_INVOICE_FAILED':'INVOICE/ADD_INVOICE_FAILED',
    'UPDATE_INVOICE_SUCCESS':'INVOICE/UPDATE_INVOICE_SUCCESS',
    'UPDATE_INVOICE_FAILED':'INVOICE/UPDATE_INVOICE_FAILED',
    'SUBMIT_INVOICE_FOR_APPROVAL_SUCCESS':'INVOICE/SUBMIT_INVOICE_FOR_APPROVAL_SUCCESS',
    'SUBMIT_INVOICE_FOR_APPROVAL_FAILED':'INVOICE/SUBMIT_INVOICE_FOR_APPROVAL_FAILED',
    'VERIFY_INVOICE_SUCCESS':'INVOICE/VERIFY_INVOICE_SUCCESS',
    'VERIFY_INVOICE_FAILED':'INVOICE/VERIFY_INVOICE_FAILED',
    'RETURN_INVOICE_SUCCESS':'INVOICE/RETURN_INVOICE_SUCCESS',
    'RETURN_INVOICE_FAILED':'INVOICE/RETURN_INVOICE_FAILED',
    'CONFIRM_INVOICE_SUCCESS':'INVOICE/CONFIRM_INVOICE_SUCCESS',
    'CONFIRM_INVOICE_FAILED':'INVOICE/CONFIRM_INVOICE_FAILED',
    'APPROVE_INVOICE_SUCCESS':'INVOICE/APPROVE_INVOICE_SUCCESS',
    'APPROVE_INVOICE_FAILED':'INVOICE/APPROVE_INVOICE_FAILED',
    'REJECT_INVOICE_SUCCESS':'INVOICE/REJECT_INVOICE_SUCCESS',
    'REJECT_INVOICE_FAILED':'INVOICE/REJECT_INVOICE_FAILED',
    'DELETE_INVOICE_SUCCESS':'INVOICE/DELETE_INVOICE_SUCCESS',
    'DELETE_INVOICE_FAILED':'INVOICE/DELETE_INVOICE_FAILED',

    'FETCH_DOCUMENTS_SUCCESS':'BUSINESS/FETCH_DOCUMENTS_SUCCESS',
    'FETCH_DOCUMENTS_FAILED':'BUSINESS/FETCH_DOCUMENTS_FAILED',
    'UPLOAD_DOCUMENT_SUCCESS':'BUSINESS/UPLOAD_DOCUMENT_SUCCESS',
    'UPLOAD_DOCUMENT_FAILED':'BUSINESS/UPLOAD_DOCUMENT_FAILED',
    'REMOVE_DOCUMENT_SUCCESS':'BUSINESS/REMOVE_DOCUMENT_SUCCESS',
    'REMOVE_DOCUMENT_FAILED':'BUSINESS/REMOVE_DOCUMENT_FAILED',
}

export default CONST;