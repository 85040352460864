import * as React from 'react';
import {Card, CardContent, CardHeader, Chip, Collapse, IconButton} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import AuditDetailTemplate from "../AuditDetailTemplate";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useState} from "react";

export function BusinessPropertiesPanel() {

    const business = useSelector((state) => state.business.selected);
    const [isExpanded, setIsExpanded] = useState(false);

    const CustomList = styled(List)({
        color: 'darkslategray',
        '& .MuiListItem-root': {
            padding: '0!important',
            borderBottom:'1px solid #f4f4f4',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return (
        <>
            { business !== undefined &&
                <Card>
                    <CardHeader title={"Properties"} titleTypographyProps={{variant: 'headline'}}
                        action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }
                    />
                    <Collapse in={isExpanded}>
                        <CardContent>
                            <CustomList>
                                <ListItem>
                                    <ListItemText primary={"Status"} />
                                    <ListItemIcon><Chip color={"primary"} label={business?.status} size={"small"} /></ListItemIcon>
                                </ListItem>
                                {(business?.assigned_to !== null && business?.assigned_to !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Assigned"} secondary={<AuditDetailTemplate type={"assigned"} data={business} />}/>
                                    </ListItem>
                                }
                                {(business?.created_by !== null && business?.created_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Created"} secondary={<AuditDetailTemplate type={"created"} data={business} />}/>
                                    </ListItem>
                                }
                                {(business?.updated_by !== null && business?.updated_by !== undefined) &&
                                <ListItem>
                                    <ListItemText primary={"Last Updated"} secondary={<AuditDetailTemplate type={"updated"} data={business} />}/>
                                </ListItem>
                                }
                                {(business?.verified_by !== null && business?.verified_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Verified"} secondary={<AuditDetailTemplate type={"verified"} data={business} />}/>
                                    </ListItem>
                                }
                                {(business?.approved_by !== null && business?.approved_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Approved"} secondary={<AuditDetailTemplate type={"approved"} data={business} />}/>
                                    </ListItem>
                                }
                            </CustomList>
                        </CardContent>
                    </Collapse>
                </Card>
            }
        </>
    );
}
