import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {OwnershipForm} from "../Forms/OwnershipForm";
import {Card, CardContent, CardHeader, useMediaQuery} from "@mui/material";
import { addOwnership, deleteOwnership, clearErrors, proceed, setSelectedOwnership, OwnershipDeleteModal } from "../../index";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {currencyFormatter, countries, checkExpiryAndDisplayDate} from "../../../../Setup/Config";

export default function OwnershipCard(props) {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.ownership).length > 0 ? business.ownership.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setFsModal({component:null, state:false}));
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addOwnership(dispatch, {business_id:business.selected.business_id});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedOwnership(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<OwnershipForm business={business} />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<OwnershipDeleteModal />, state:true}));
        dispatch(setSelectedOwnership(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.id);
    }

    const columns = React.useMemo(
        () => [
            { field: 'name', headerName:'Partner Name', type: 'string', minWidth: 300, flex:1},
            { field: 'type', headerName:'Type', type: 'string', minWidth: 80, flex:1  },
            { field: 'holding', headerAlign:'center', align:'center', headerName:'Holding', type: 'string', valueFormatter: ({ value }) => `${value}%`, minWidth: 80, flex:1 },
            { field: 'eid_no', headerName:'Emirates ID No', type: 'string', minWidth: 150, flex:1 },
            { field: 'eid_expiry_date', headerName:'EID Expiry', type: 'dateTime', flex: 1, minWidth: 100, renderCell: ({value}) => (<Typography>{checkExpiryAndDisplayDate(value)}</Typography>), valueFormatter: ({ value }) => checkExpiryAndDisplayDate(value),},
            { field: 'visa_no', headerName:'Visa No', type: 'string', minWidth: 130, flex:1 },
            { field: 'visa_issued_by', headerName:'Visa Sponsor Name', type: 'string', minWidth: 230, flex:1},
            { field: 'visa_expiry_date', headerName:'Visa Expiry', type: 'string', minWidth: 100, flex:1, renderCell: ({value}) => (<Typography>{checkExpiryAndDisplayDate(value)}</Typography>), valueFormatter: ({ value }) => checkExpiryAndDisplayDate(value), },
            { field: 'date_of_birth', headerName:'Date of Birth', type: 'string', minWidth: 100, flex:1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'nationality', headerAlign:'center', align:'center', headerName:'Nationality', type: 'string', flex:1, minWidth:180, valueFormatter: ({value}) =>
                    value && (countries.filter((obj) => obj.iso3 === value))[0].name
            },
            { field: 'passport_no', headerAlign:'center', align:'center', headerName:'Passport #', type: 'string', minWidth: 100, flex:1 },
            { field: 'passport_expiry_date', headerAlign:'center', align:'center', headerName:'Passport Expiry', type: 'dateTime', minWidth: 120, flex:1, renderCell: ({value}) => (<Typography>{checkExpiryAndDisplayDate(value)}</Typography>), valueFormatter: ({ value }) => checkExpiryAndDisplayDate(value)},
            { field: 'current_address', headerName:'Current Address', type: 'string', minWidth: 180, flex:1},
            { field: 'home_address', headerName:'Home Address', type: 'string', minWidth: 220, flex:1},
            { field: 'residential_status', headerAlign:'center', align:'center', headerName:'Residential Status', type: 'string', minWidth: 150, flex:1},
            { field: 'in_uae_since', headerAlign:'center', align:'center', headerName:'Residing in UAE Since', type: 'string', minWidth: 200, flex:1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")},
            { field: 'mobile', headerAlign:'center', align:'center', headerName:'Mobile', type: 'string', minWidth: 150, flex:1 },
            { field: 'email', headerAlign:'center', align:'center', headerName:'Email', type: 'string', minWidth: 220, flex:1 },
            { field: 'aecb_score', headerAlign:'center', align:'center', headerName:'AECB Score', type: 'string', minWidth: 100, flex:1 },
            { field: 'aecb_score_date', headerAlign:'center', align:'center', headerName:'AECB Score Date', type: 'string', minWidth: 130, flex:1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'life_insurance_value', headerAlign:'center', align:'center', headerName:'LIC Value', type: 'number', minWidth: 120, flex:1, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'personal_net_worth', headerAlign:'center', align:'center', headerName:'Personal Net Worth', type: 'number', minWidth: 160, flex:1, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            {
                field: 'actions',
                width: 120,
                type: 'actions',
                headerName:'Action',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />
                    ,
                ],
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Partners Details'}
                    action={
                        (
                            (['add-business-ownerships'].some(i => auth.user.permissions.includes(i)) && ['DRAFT','SUBMITTED'].some(i => business.selected.status.includes(i))) ||
                            ['su-edit-business-ownership'].some(i => auth.user.permissions.includes(i)) ||
                            auth.user.role_type === 'ADMIN'
                        )
                        &&
                        <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>
                    }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { objects !== undefined &&  Object.keys(objects).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={objects} sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left:['name'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: ((['DRAFT','RETURNED'].includes(business.selected.status) && auth.user.role_type === 'CLIENT') || (['SUBMITTED','PROCESSING','PENDING'].includes(business.selected.status) && auth.user.role_type !== 'CLIENT') || auth.user.role_type === 'ADMIN' || ['su-edit-business-ownership'].some(i => auth.user.permissions.includes(i))),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}
