import {combineReducers} from "redux";
import authReducer from '../../Modules/Auth/Redux/authReducer';
import businessReducer from '../../Modules/Business/Redux/BusinessReducer';
import configReducer from './ConfigReducer';
import userReducer from '../../Modules/Users/Redux/UserReducer';
import roleReducer from "../../Modules/Roles/Redux/RoleReducer";
import permissionReducer from "../../Modules/Permissions/Redux/PermissionReducer";

import dashboardReducer from "../../Modules/Dashboard/Redux/DashboardReducer";
import serviceReducer from "../../Modules/Services/Redux/ServiceReducer";
import accountReducer from "../../Modules/Accounts/Redux/AccountReducer";
import invoiceReducer from "../../Modules/Invoices/Redux/InvoiceReducer";
import loanReducer from "../../Modules/BusinessFinance/Redux/LoanReducer";
import documentReducer from "../../Modules/Documents/Redux/DocumentReducer";


const appReducer = combineReducers({
    auth:authReducer,
    accounts:accountReducer,
    dashboard:dashboardReducer,
    documents:documentReducer,
    config:configReducer,
    users:userReducer,
    roles:roleReducer,
    permissions:permissionReducer,
    business:businessReducer,
    invoices:invoiceReducer,
    loans:loanReducer,
    services:serviceReducer,
});

export default appReducer;