import React,{ useRef, useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import {Alert, Card, CardActions, CardContent, CardHeader, Container, Paper, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Divider from '@mui/material/Divider';
import { doVerify } from "../../Users";
import {Link, useParams} from "react-router-dom";
import {isBrowser} from "react-device-detect";
import BlockUI from "../../Components/Common/BlockUI";
import Toastr from "../../Components/Common/Toastr";
import PoweredBy from "../../Components/Common/PoweredBy";

export const RegisterVerify = () => {

    return (
        <>
            {isBrowser ? <BrowserView /> : <MobileView /> }
        </>
    )
}

const BrowserView = () => {

    const { token } = useParams();
    const dispatch = useDispatch();
    const [disabled,setDisabled] = useState();
    const [response, setResponse] = useState({});

    const [data, setData] = useState({
        token : token,
    })

    useEffect(() => {
        setDisabled(true);
        verifyAccount();
    },[])

    let verifyAccount = async () => {

        doVerify(dispatch, data).then((res) => {
            setResponse(res);
            if(res['success']){
                setDisabled(false);
            }
        });
    }

    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}} >

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:1, display:'flex', justifyContent:'center',alignItems:'center'}} />

                    <Box sx={{p:2,flexGrow:0.75}}>
                        <Stack direction={"column"} spacing={2} sx={{ minWidth: '310px' }} alignItems={"center"}>

                            <Box sx={{marginBottom:'20px'}}>
                                <img src={'/images/ifund-logo.png'} width={200} />
                            </Box>

                            <Card sx={{marginTop:'80px', border:'none'}}>
                                <CardHeader title={"Registration Validated"} sx={{borderBottom:'none!important'}} />
                                <CardContent>
                                    <Stack spacing={2} sx={{minWidth:300}}>
                                        <Typography>Your account is successfully validated. Please proceed to login.</Typography>
                                    </Stack>
                                </CardContent>
                                <CardActions sx={{display:'flex', flexDirection:'column', p:2, borderTop:'none!important'}} >

                                    <Link disabled={disabled} color="warning" to={'/login'} sx={{ textDecoration:'none'}}>
                                        <Button disabled={disabled} color="primary" sx={{ width:'100%', fontSize:'15px'}} className={' text-capitalize p-3'} variant="contained" >Login</Button>
                                    </Link>

                                </CardActions>
                            </Card>
                            <BlockUI />
                            { Object.keys(response).length > 0 && <Toastr response={response} /> }
                        </Stack>
                    </Box>

                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>
    )
}

const MobileView = () => {

    const { token } = useParams();
    const dispatch = useDispatch();
    const [disabled,setDisabled] = useState();
    const [response, setResponse] = useState();

    const [data, setData] = useState({
        token : token,
    })

    useEffect(() => {
        setDisabled(true);
        verifyAccount();
    },[])

    let verifyAccount = async () => {

        doVerify(dispatch, data).then((res) => {
            setResponse(res);
            if(res['success']){
                setDisabled(false);
            }
        });
    }


    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={'/images/ifund-logo.png'} width={150} />
                    </Box>

                    <Box sx={{p:2,flexGrow:0.75}}>
                        <Stack spacing={2} sx={{ minWidth: '310px' }}>
                            <Stack spacing={2} sx={{minWidth:300}}>
                                <Typography>Your account is successfully validated. Please proceed to login.</Typography>
                            </Stack>

                            <Link disabled={disabled} color="warning" to={'/login'} sx={{ textDecoration:'none'}}>
                                <Button disabled={disabled} color="primary" sx={{ width:'100%', fontSize:'15px'}} className={' text-capitalize p-3'} variant="contained" >Login</Button>
                            </Link>

                            <BlockUI />
                            { Object.keys(response).length > 0 && <Toastr response={response} /> }
                        </Stack>
                    </Box>

                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>

    )
}
