const CONST = {
    'RESET_ALL':'ACCOUNTS/RESET_ALL',
    'SET_SELECTED_ACCOUNT':'ACCOUNTS/SET_SELECTED_ACCOUNT',
    'SET_SELECTED_ACCOUNT_SUCCESS':'ACCOUNTS/SET_SELECTED_ACCOUNT_SUCCESS',
    'FETCH_ACCOUNT_SUCCESS':'ACCOUNTS/FETCH_ACCOUNT_SUCCESS',
    'FETCH_ACCOUNT_FAILED':'ACCOUNTS/FETCH_ACCOUNT_FAILED',
    'FETCH_ACCOUNTS_SUCCESS':'ACCOUNTS/FETCH_ACCOUNTS_SUCCESS',
    'FETCH_ACCOUNTS_FAILED':'ACCOUNTS/FETCH_ACCOUNTS_FAILED',
    'ADD_ACCOUNT_SUCCESS':'ACCOUNTS/ADD_ACCOUNT_SUCCESS',
    'ADD_ACCOUNT_FAILED':'ACCOUNTS/ADD_ACCOUNT_FAILED',
    'UPDATE_ACCOUNT_SUCCESS':'ACCOUNTS/UPDATE_ACCOUNT_SUCCESS',
    'UPDATE_ACCOUNT_FAILED':'ACCOUNTS/UPDATE_ACCOUNT_FAILED',
    'DELETE_ACCOUNT_SUCCESS':'ACCOUNTS/DELETE_ACCOUNT_SUCCESS',
    'DELETE_ACCOUNT_FAILED':'ACCOUNTS/DELETE_ACCOUNT_SUCCESS',
}

export default CONST;