import React from "react";
import {
    FormControl, Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {setSelectedOwnership, updateOwnership} from "../../index";
import MuiDatepicker from "../Datepicker";
import MenuItem from "@mui/material/MenuItem";
import AttachDoc from "../../../Documents/Components/AttachDoc";
import Toastr from "../../../Components/Common/Toastr";
import SelectCountry from "../../../Components/Common/SelectCountry";

export const OwnershipForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [passportLabel, setPassportLabel] = useState("Passport No");
    const [passportExpiryDate, setPassportExpiryDate] = useState("Passport Expiry Date");

    const handleChange = (e) => {
        setDisabled(false);

        if(e.target.name === 'type' && e.target.value === 'Company'){
            setPassportLabel("License No");
            setPassportExpiryDate("License Expiry Date");
        }else{
            setPassportLabel("Passport No");
            setPassportExpiryDate("Passport Expiry Date");
        }

        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(business.ownership.selected);
    }, [])

    const handleSubmit = async () => {
        setDisabled(true);
        await updateOwnership(dispatch, data).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}));
        dispatch(setSelectedOwnership(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Ownership Details
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>
                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="add-user-form-label-office">Owner Type</InputLabel>
                                    <Select
                                        defaultValue={data?.type || ""}
                                        name="type"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Owner Type"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem key={"Agent"} value={'Agent'}>Agent</MenuItem>
                                        <MenuItem key={"Company"} value={'Company'}>Company</MenuItem>
                                        <MenuItem key={"Investor"} value={'Investor'}>Investor</MenuItem>
                                        <MenuItem key={"Manager"} value={'Manager'}>Manager</MenuItem>
                                        <MenuItem key={"Partner"} value={'Partner'}>Partner</MenuItem>
                                        <MenuItem key={"Signatory"} value={'Signatory'}>Signatory</MenuItem>
                                        <MenuItem key={"Sponsor"} value={'Sponsor'}>Sponsor</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Owner Name"
                                    name="name"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    type={"number"}
                                    required
                                    label="Shares Percentage Holding"
                                    name="holding"
                                    inputProps={{
                                        min: 0, max: 100,
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.holding}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.holding}
                                    onBlur={handleChange} />
                                <MuiDatepicker error={business?.error?.date_of_birth}  setDisabled={setDisabled} label={"Date of Birth"} name="date_of_birth" past={true} data={data} state={setData} />

                                <SelectCountry error={business?.error?.nationality} default={"ARE"} disabled={disabled} label={"Nationality"} name={"nationality"} data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label={ passportLabel }
                                    name="passport_no"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.passport_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.passport_no}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.passport_expiry_date} setDisabled={setDisabled} label={passportExpiryDate } name="passport_expiry_date" past={false} data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Passport Copy"} entity_name={"ownership"} entity_id={data?.id} name="passport_doc" data={data} state={setData} />

                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-residential_status">Residential Status</InputLabel>
                                    <Select
                                        defaultValue={data?.residential_status || ""}
                                        name="residential_status"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="residential_status"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem key={"Resident"} value={'Resident'}>Resident</MenuItem>
                                        <MenuItem key={"Non-Resident"} value={'Non-Resident'}>Non-Resident</MenuItem>
                                    </Select>
                                </FormControl>

                                <MuiDatepicker error={business?.error?.in_uae_since}  setDisabled={setDisabled} label={"Residing in UAE Since"} name="in_uae_since" past={true} data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Visa No"
                                    name="visa_no"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.visa_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.visa_no}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Visa Sponsor Name "
                                    name="visa_issued_by"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.visa_issued_by}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.visa_issued_by}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.visa_expiry_date} past={false} setDisabled={setDisabled} label={"Visa Expiry Date"} name="visa_expiry_date" data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Visa Copy"} entity_name={"ownership"} entity_id={data?.id} name="visa_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Emirates ID Number"
                                    name="eid_no"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.eid_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.eid_no}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.eid_expiry_date} setDisabled={setDisabled} label={"Emirates ID Expiry Date"} past={false} name="eid_expiry_date" data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Emirates ID Copy"} entity_name={"ownership"} entity_id={data?.id} name="eid_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Mobile"
                                    name="mobile"
                                    placeholder={"e.g. 009715XXXXXXXX"}
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.mobile}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.mobile}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.email}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.email}
                                    onBlur={handleChange} />

                                <TextField
                                    fullWidth
                                    label="Current Residence Address"
                                    name="current_address"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.current_address}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.current_address}
                                    onBlur={handleChange} />
                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Residence Utility Bill"} entity_name={"ownership"} entity_id={data?.id} name="current_utility_bill_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Home Country Address"
                                    name="home_address"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.home_address}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.home_address}
                                    onBlur={handleChange} />
                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Home Country Utility Bill"} entity_name={"ownership"} entity_id={data?.id} name="home_utility_bill_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Owner AECB Score"
                                    name="aecb_score"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.aecb_score}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.aecb_score}
                                    onBlur={handleChange} />
                                <MuiDatepicker error={business?.error?.aecb_score_date} setDisabled={setDisabled} onBlur={handleChange} label={"Owner AECB Report Date"} past={true} name="aecb_score_date" data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Owner AECB Report"} entity_name={"ownership"} entity_id={data?.id} name="aecb_score_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Life Insurance Value"
                                    name="life_insurance_value"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.life_insurance_value}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.life_insurance_value}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Personal Net Worth"
                                    name="personal_net_worth"
                                    inputProps={{
                                        autoComplete: 'none'
                                    }}
                                    defaultValue={data?.personal_net_worth}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.personal_net_worth}
                                    onBlur={handleChange} />
                                <AttachDoc setResponse={setResponse} updateFunction={updateOwnership} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Personal Net Worth Doc"} entity_name={"ownership"} entity_id={data?.id} name="personal_net_worth_doc" data={data} state={setData} />


                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

