import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import { doLogout } from "../../Auth";


/****************************************
 SELECT PERMISSION
 ****************************************/
export const setSelectedPermission = (payload) => ({
    type: CONST.SET_SELECTED_PERMISSION,
    payload: payload
})

/****************************************
 FETCH PERMISSION
 ****************************************/
export const fetchPermissionSuccess = (payload) => ({
    type: CONST.FETCH_PERMISSION_SUCCESS,
    payload: payload
})

export const fetchPermissionFailed = (payload) => ({
    type: CONST.FETCH_PERMISSION_FAILED,
    payload: payload
})

export const fetchPermission = async (dispatch, id) => {

    await axiosPrivate.get('permission/' + id).then((response) => {
        dispatch(fetchPermissionSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchPermissionFailed(error?.response));
    });
}

/****************************************
 FETCH PERMISSIONES
 ****************************************/
export const fetchPermissionsSuccess = (payload) => ({
    type: CONST.FETCH_PERMISSIONS_SUCCESS,
    payload: payload
})

export const fetchPermissionsFailed = (payload) => ({
    type: CONST.FETCH_PERMISSIONS_FAILED,
    payload: payload
})

export const fetchPermissions = async (dispatch, data) => {
    let res = {}
    console.log(data)
    await axiosPrivate.get('permission', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchPermissionsSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchPermissionsFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD PERMISSION
 ****************************************/
export const addPermissionSuccess = (payload) => ({
    type: CONST.ADD_PERMISSION_SUCCESS,
    payload: payload
})

export const addPermissionFailed = (payload) => ({
    type: CONST.ADD_PERMISSION_FAILED,
    payload: payload
})

export const addPermission = async (dispatch, data) => {
    await axiosPrivate.post('permission', data).then((response) => {
        dispatch(addPermissionSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addPermissionFailed(error?.response));
    });
}


/****************************************
 UPDATE PERMISSION
 ****************************************/
export const updatePermissionSuccess = (payload) => ({
    type: CONST.UPDATE_PERMISSION_SUCCESS,
    payload: payload
})

export const updatePermissionFailed = (payload) => ({
    type: CONST.UPDATE_PERMISSION_FAILED,
    payload: payload
})

export const updatePermission = async (dispatch, data) => {

    await axiosPrivate.patch('permission/' + data['permission_id'], data).then((response) => {
        dispatch(updatePermissionSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updatePermissionFailed(error?.response));
    });
}


/****************************************
 DELETE PERMISSION
 ****************************************/
export const deletePermissionSuccess = (payload) => ({
    type: CONST.DELETE_PERMISSION_SUCCESS,
    payload: payload
})

export const deletePermissionFailed = (payload) => ({
    type: CONST.DELETE_PERMISSION_FAILED,
    payload: payload
})

export const deletePermission = async (dispatch, data) => {
    await axiosPrivate.delete('permission/' + data['id']).then((response) => {
        //TODO::prepare response
        dispatch(deletePermissionSuccess(data['id']));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(deletePermissionFailed(error?.response));
    });
}