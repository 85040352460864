import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import {useDispatch, useSelector} from "react-redux";
import {openCommonModal} from "../../../Setup/Redux/ConfigAction";

export default function Modal(props) {

    const config = useSelector((store) => store.config);
    const dispatch = useDispatch();
    const open = config.openCommonModal;

    return (
        <>
            <Dialog open={open} onClose={ () => dispatch(openCommonModal(false)) } >
                {props.component}
            </Dialog>
        </>
    );
}