import * as React from 'react';
import Button from "@mui/material/Button";
import {Card, CardContent, CardHeader, Grid, Stack} from "@mui/material";

import Typography from "@mui/material/Typography";
import {CompanyDetailForm} from "../Forms/CompanyDetailForm";
import {useSelector} from "react-redux";
import {useRef, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import {countries, checkExpiryAndDisplayDate} from "../../../../Setup/Config/index";
import {CardList} from "../../../Components";

export default function CompanyDetailCard(props) {

    const childRef = useRef();
    const business = useSelector((store) => store.business.selected);
    const auth = useSelector((store) => store.auth);
    const [action, setAction] = useState();
    const [response, setResponse] = useState(false);

    const handleActionBtn = async (e) => {
        setAction(e.target.dataset.action)
    }

    const handleSaveBtn = async (e) => {
        if(e.target.dataset.action === 'Save'){
            let r = {};
            await childRef.current.handleSubmit().then((res) => {
                r = res
                setResponse(res)
                if(res['success']){
                    setAction(e.target.dataset.action)
                }
            });
        }
    }

    return (
        <>
            { props.business &&
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Company Details'}
                    action={
                        (
                            (['verify-business', 'return-business'].some(i => auth.user.permissions.includes(i)) && ['DRAFT','SUBMITTED'].some(i => business.status.includes(i))) ||
                            ['su-edit-business-details'].some(i => auth.user.permissions.includes(i)) ||
                            auth.user.role_type === 'ADMIN'
                        )
                            &&
                        <Button variant="outlined" size={"small"} data-id={props.business.business_id} data-action={action === "Edit" ? "Save" : "Edit"} onClick={action === 'Edit' ? handleSaveBtn : handleActionBtn}>{action === "Edit" ? "Save" : "Edit"}</Button>
                    }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd'}}>
                    { action !== 'Edit'?
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                <Typography mb={1}>Company Registration Details</Typography>
                                <Stack spacing={2}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Company Name"} secondary={business.name || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Trade License Number"} secondary={business.trade_license_number || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Trade License Authority"} secondary={business.trade_license_authority || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Trade License Issue Date"} secondary={business.trade_license_issue_date ? moment(business.trade_license_issue_date).format("DD/MM/YYYY") : "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Trade License Expiry Date"} secondary={ checkExpiryAndDisplayDate(business.trade_license_expiry_date) } />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"AECB Score"} secondary={business.aecb_score || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"AECB Date"} secondary={business.aecb_date ? moment(business.aecb_date).format("DD/MM/YYYY") : "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Last MOA Date"} secondary={business.last_moa_date ? moment(business.last_moa_date).format("DD/MM/YYYY") : "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Tenancy Expiry Date"} secondary={ checkExpiryAndDisplayDate(business.tenancy_expiry_date) }/>
                                        </ListItem>
                                    </CardList>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Typography mb={1}>Company Address</Typography>
                                <Stack spacing={2}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Phone"} secondary={business.phone || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Fax"} secondary={business.fax || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Email"} secondary={business.email || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Website"} secondary={business.website || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Linkedin"} secondary={business.linkedin || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Twitter"} secondary={business.twitter || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Instagram"} secondary={business.instagram || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Facebook"} secondary={business.facebook || "-"}/>
                                        </ListItem>
                                    </CardList>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Typography mb={1}>Contact Communication Details</Typography>
                                <Stack spacing={2}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Unit No / Floor Number"} secondary={business.ca_address_1 || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Building/Street Name"} secondary={business.ca_address_2 || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Landmark"} secondary={business.ca_address_3 || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Area"} secondary={business.ca_address_4 || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"PO Box"} secondary={business.ca_po_box || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"City"} secondary={business.ca_city || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"State"} secondary={business.ca_state || "-"}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Country"} secondary={(countries.filter((obj) => obj.iso3 === business.ca_country))[0]?.name || "-"}/>
                                        </ListItem>
                                    </CardList>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <CardList >
                                    <ListItem>
                                        <ListItemText primary={"Details"} secondary={business.business_details || "-"}/>
                                    </ListItem>
                                </CardList>
                            </Grid>
                        </Grid>
                        :
                        <CompanyDetailForm ref={childRef} setResponse={setResponse} />
                    }
                </CardContent>
            </Card>
            }
        </>
    );
}
