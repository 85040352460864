import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal} from "../../../../Setup/Redux/ConfigAction";
import {CustomerSupplierForm} from "../Forms/CustomerSupplierForm";
import {countries, currencyFormatter} from "../../../../Setup/Config";
import {Card, CardContent, CardHeader, Chip, Stack, useMediaQuery} from "@mui/material";
import {
    addCustomerSupplier,
    deleteCustomerSupplier,
    clearErrors,
    proceed,
    setSelectedCustomerSupplier,
    CustomerSupplierDeleteModal
} from "../../index";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function CustomerSupplierCard(props) {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.customersSuppliers).length > 0 ? business.customersSuppliers.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const [formTitle, setFormTitle] = useState();
    const [subTitle, setSubTitle] = useState();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{

        setFormTitle(e.target.dataset.form_title);
        setSubTitle(e.target.dataset.sub_title);

        if(e.target.dataset.action === 'AddItem'){
            await addCustomerSupplier(dispatch, {business_id:business.selected.business_id, is_customer:parseInt(e.target.dataset.is_customer)});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCustomerSupplier(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<CustomerSupplierForm formName={formTitle} formSubTitle={subTitle} />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<CustomerSupplierDeleteModal />, state:true}));
        dispatch(setSelectedCustomerSupplier(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.id);
    }

    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Name', type: 'string', flex:1, minWidth:220},
            { field: 'is_customer', headerName:'Type', type: 'string', flex:1, minWidth:100,
                renderCell: (params) => {
                    const type = {
                        0:'warning',
                        1:'info',
                    }
                    const val = {
                        0:'Supplier',
                        1:'Customer',
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={val[params.value]}
                        color={type[params.value]}
                    >
                    </Chip>)
                },
            },
            { field: 'dealing_since', headerName:'Dealing Since', type: 'string', flex:1, minWidth: 120,},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', flex:1, minWidth:120},
            { field: 'currency', headerName:'Currency', type: 'string', flex:1, minWidth:80},
            { field: 'address', headerName:'Address', type: 'string', flex:1, minWidth:280},
            { field: 'company_contact_no', headerName:'Company Contact No', type: 'string', flex:1, minWidth:160},
            { field: 'country', headerName:'Country', type: 'string', flex:1, minWidth:180, valueFormatter: ({value}) =>
                    value && (countries.filter((obj) => obj.iso3 === value))[0].name
            },
            { field: 'company_website', headerName:'Company Website', type: 'string', flex:1, minWidth:160},
            { field: 'products_desc', headerName:'Product Description', type: 'string', flex:1, minWidth:220},
            { field: 'payment_method', headerName:'Payment Method', type: 'string', flex:1, minWidth:120},
            { field: 'amount_previous', headerName:'Amount Previous', type: 'number', flex:1, minWidth:150, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'amount_projected', headerName:'Amount Projected', type: 'number', flex:1, minWidth:150, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'avg_invoice_value', headerName:'Avg Invoice Value', type: 'number', flex:1, minWidth:160, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'no_of_invoice', headerName:'No Of Invoice', type: 'string', flex:1, minWidth:120},
            { field: 'cp_name', headerName:'Contact Person Name', type: 'string', flex:1, minWidth:160},
            { field: 'cp_phone', headerName:'Contact Person Phone', type: 'string', flex:1, minWidth:160},
            { field: 'cp_mobile', headerName:'Contact Person Mobile', type: 'string', flex:1, minWidth:160},
            { field: 'cp_email', headerName:'Contact Person Email', type: 'string', flex:1, minWidth:180},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Customers & Suppliers'}
                    action={
                        (
                            (['add-business-customers-suppliers'].some(i => auth.user.permissions.includes(i)) && ['DRAFT','SUBMITTED'].some(i => business.selected.status.includes(i))) ||
                            ['su-edit-business-customer-supplier'].some(i => auth.user.permissions.includes(i)) ||
                            auth.user.role_type === 'ADMIN'
                        )
                        &&
                        <Stack spacing={1} direction={"row"}>
                            <Button variant="outlined" size={"small"} data-id={"draft"} data-form_title={"Customers"} data-sub_title={"Provide the details of your top customers"} data-is_customer={1} data-action={"AddItem"} onClick={handleActionBtn}>Add Customers</Button>
                            <Button variant="outlined" size={"small"} data-id={"draft"} data-form_title={"Suppliers"} data-sub_title={"Provide the details of your top suppliers"} data-is_customer={0} data-action={"AddItem"} onClick={handleActionBtn}>Add Suppliers</Button>
                        </Stack>
                    }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { objects !== undefined &&  Object.keys(objects).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.customer_id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={objects || []} sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left: ['company_name'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        card_id: false,
                                        actions: ((['DRAFT','RETURNED'].includes(business.selected.status) && auth.user.role_type === 'CLIENT') || (['SUBMITTED','PROCESSING','PENDING'].includes(business.selected.status) && auth.user.role_type !== 'CLIENT') || auth.user.role_type === 'ADMIN' || ['su-edit-business-customer-supplier'].some(i => auth.user.permissions.includes(i))),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}