import React, {createContext} from "react";
import AppBar from "../Modules/Components/Common/AppBar";
import LeftDrawer from "../Modules/Components/Drawers/LeftDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider } from '@mui/material/styles';
import {theme} from '../Setup/Theme/theme';
import CustomContainer from "../Modules/Components/Common/CustomContainer";
import FsModal from "../Modules/Components/Common/FsModal";
import {useSelector} from "react-redux";
import {FooterIfund} from "../Modules/Components/Common/FooterIfund";
import {AppBarIfund} from "../Modules/Components/Common/AppBarIfund";

export const LayoutContext = createContext({});

const LayoutProvider = ({children}) => {

    const auth = useSelector((store)=> store.auth);
    const layout = auth.user.role_type === 'CLIENT' ? PublicLayout : PrivateLayout;

    return <LayoutContext.Provider value>{layout(children)}</LayoutContext.Provider>
}

const PrivateLayout = (children) => {

    return (
        <ThemeProvider theme={ theme }>
            <Box sx={{ display: 'flex', flexGrow:1, backgroundColor:theme.body }}>
                <CssBaseline />
                <AppBar  />
                <LeftDrawer />
                <CustomContainer>
                    { children }
                </CustomContainer>
                <FsModal />
            </Box>
        </ThemeProvider>
    )
}

const PublicLayout = (children) => {

    return (
        <ThemeProvider theme={ theme }>
            <Box sx={{ display: 'flex', flexDirection:"column", flexGrow:1, backgroundColor:theme.body }}>
                <CssBaseline />
                <AppBarIfund  />
                <CustomContainer>
                    { children }
                </CustomContainer>
                <FsModal />
                <FooterIfund />
            </Box>
        </ThemeProvider>
    )
}

export default LayoutProvider;