import * as React from 'react';
import {Card, CardContent, CardHeader, Chip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {updateServiceCharge} from "../../index";
import {currencyFormatter} from "../../../../Setup/Config";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useCallback, useState} from "react";

export const ServiceChargeTable = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const charges = useSelector(state => state.services.selected.charges);
    const business = useSelector(state => state.business);
    const loan = useSelector((state) => state.loans.selected);
    const [response, setResponse] = useState(false);

    const customer_banks = () => {
        let obj = [];
        if(business.bankDetails && (business.bankDetails.list)?.length > 0){
            business.bankDetails.list.map(i => {
                obj.push({'value':i.bank.bank_id,'label':i.bank.bank_name});
            });
        }
        return obj;
    }

    const signatories = () => {
        let obj = [];
        if(business.ownership && (business.ownership.list)?.length > 0) {
            business.ownership.list.map(i => {
                obj.push({'value': i.id, 'label': i.name});
            });
        }
        return obj;
    }

    const columns = [
        { field: 'charge_type', headerName: 'Type', type: 'string', flex:1, minWidth: 150,
            valueFormatter: ({ value }) => {
                return value.replace(/_/g, ' ');;
            }
        },
        { field: 'charge_amount', headerName: 'Charge', type: 'number', flex:1, minWidth: 100, editable: (['edit-service-charge-amount'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status !== 'ACTIVE', valueFormatter: ({ value }) => currencyFormatter.format(value) },
        { field: 'cheque_date', headerAlign:'center', align:'center', headerName: 'CHQ Date', type: 'date', minWidth: 120, editable: (['edit-service-charge-cheque-date'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status !== 'ACTIVE', flex:1,
            valueFormatter: (params) => {
                return params.value !== null && params.value !== ''? moment(params.value).format("DD/MM/YYYY") : '';
            },
        },
        { field: 'cheque_no', headerName: 'CHQ No', type: 'string', editable: (['edit-service-charge-cheque-no'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status !== 'ACTIVE', flex:1 , minWidth: 120},
        { field: 'bank_id', headerName: 'Bank', type: 'singleSelect', editable: (['edit-service-charge-bank-id'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status !== 'ACTIVE', flex:1, minWidth: 250,
            valueOptions: customer_banks(),
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                    ({ value: optionValue }) => value === optionValue
                );
                return option?.label;
            },
        },
        { field: 'cheque_signed_by', headerName: 'Signed By', type: 'singleSelect', editable: (['edit-service-charge-cheque-signed-by'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status !== 'ACTIVE', flex:1, minWidth: 200,
            valueOptions: signatories(),
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                    ({ value: optionValue }) => value === optionValue
                );
                return option?.label;
            },
        },
        { field: 'status', headerName:'Status', headerAlign:'center', align:'center', type: 'singleSelect', flex:1, minWidth: 120,
            valueOptions: ['PENDING','PAID','OVERDUE'],
            renderCell: (params) => {
                const status = {
                    'DRAFT':'secondary',
                    'PENDING':'warning',
                    'PAID':'success',
                    'ACTIVE':'primary',
                    'OVERDUE':'error'
                }
                return(<Chip
                    variant="contained"
                    sx={{fontWeight:'normal'}}
                    label={params.value}
                    color={status[params.value]}
                >
                </Chip>)
            },
        },
    ];

    const onCellEditCommit = async (newRow) => {

        let value = newRow?.colDef?.type === "date" ? (new Date(newRow.value).toISOString()).split('T')[0] : newRow.value;
            value = value === '1970-01-01' ? null : value;

        const data = {
            id:newRow.id,
            [newRow.field] : value,
        }
        await updateServiceCharge(dispatch,data).then((res)=>{
            console.log(res);
        });
    };


    const formatDateColumns = (newRow) => {
        const dateColumns = ['cheque_date', 'scheduled_date', 'repayment_date', /* add more date column names here */];

        dateColumns.forEach((column) => {
            if (newRow[column]) {
                newRow[column] = moment(newRow[column]).format("YYYY-MM-DD");
            }
        });
    };

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {
            formatDateColumns(newRow); // Format all date columns

            const response = await updateServiceCharge(dispatch, newRow);
            setResponse(response);
            return response['success'].data; // return the new row containing the row id
        },
        [],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.log(error)
    }, []);


    return (
        <Card>
            <CardHeader title={"Service Charge"} titleTypographyProps={{variant:'subtitle1'}} />
            <CardContent sx={{p:0}}>
                {charges !== undefined &&
                <DataGridPro
                    editMode={"cell"}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    //onCellEditCommit={onCellEditCommit}
                    getRowId={(row) => row.id}
                    rows={charges}
                    columns={columns}
                    hideFooter={true}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'scheduled_date', sort: 'asc' }],
                        },
                        pinnedColumns: { right: ['status'] },
                        columns: {
                            columnVisibilityModel: {
                                scheduled_date: true,
                                repayment_date: (auth.user.permissions).includes('show-loan-repayment-date') || auth.user.role_type === 'ADMIN',
                                last_reminder_date: (auth.user.permissions).includes('show-loan-schedule-last-reminder-date') || auth.user.role_type === 'ADMIN',
                                bank_id:(auth.user.permissions).includes('show-loan-schedule-bank') || auth.user.role_type === 'ADMIN',
                                amount:true,
                                balance:(auth.user.permissions).includes('show-loan-schedule-balance') || auth.user.role_type === 'ADMIN',
                                cheque_signed_by:(auth.user.permissions).includes('show-loan-schedule-cheque-signed-by') || auth.user.role_type === 'ADMIN',
                                cheque_no:(auth.user.permissions).includes('show-loan-schedule-cheque-no') || auth.user.role_type === 'ADMIN',
                                cheque_date:(auth.user.permissions).includes('show-loan-schedule-cheque-date') || auth.user.role_type === 'ADMIN',
                                cheque_deposit_date:(auth.user.permissions).includes('show-loan-schedule-cheque-deposit-date') || auth.user.role_type === 'ADMIN',
                                cheque_clearing_date:(auth.user.permissions).includes('show-loan-schedule-cheque-clearing-date') || auth.user.role_type === 'ADMIN',
                                cheque_bounced:(auth.user.permissions).includes('show-loan-schedule-cheque-bounced') || auth.user.role_type === 'ADMIN',
                                status:true,
                            }
                        }
                    }}
                    autoHeight={true}
                />
                }
            </CardContent>
        </Card>
    );
}

