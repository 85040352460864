// theme.js
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#01a3ff",
            contrastText:'#fff',
        },
        info: {
            main: "#3da7e3",
            contrastText:'#fff',
        },
        success:{
            main:"#9c0",
            light:'#9c0',
            dark:'#2d3411',
        },
        secondary: {
            main: "#7a7c98"
        },
        action:{
            "active": "rgba(0, 0, 0, 0.54)",
            "hover": "rgba(0, 0, 0, 0.04)",
            "hoverOpacity": 0.04,
            "selected": "rgba(0, 0, 0, 0.08)",
            "selectedOpacity": 0.08,
            "disabled": "rgba(0, 0, 0, 0.26)",
            "disabledBackground": "rgba(0, 0, 0, 0.12)",
            "disabledOpacity": 0.38,
            "focus": "rgba(0, 0, 0, 0.12)",
            "focusOpacity": 0.12,
            "activatedOpacity": 0.12
        }
    },
    typography: {
        fontFamily: 'Raleway, Arial',
    },
    body: '#f0f1f9',
    navbarBackgroundColor:'#01a3ff',
    navbarPublicColor:'#fbfbfc',
    authBackground : 'radial-gradient(at 50% 30%, #D7E1EC 10%, #FFFFFF 100%)',
    components: {
        MuiCardActions: {
            styleOverrides: {
                root: {
                    // Some CSS
                    background: 'transparent',
                    borderTop: '1px solid #eff2f5',
                    '& a':{
                        textDecoration:'none'
                    }
                },
            },
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    }
                },
            },
        },
        MuiFormLabel:{
            styleOverrides:{
                root:{
                    textTransform:'capitalize'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                // Global styles
                'a': {
                    textDecoration: 'none', // Remove text decoration for anchor tags
                },
                'a.publicMenu': {
                    color: '#333333',
                }
            },
        },
    },
});

