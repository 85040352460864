import * as React from 'react';
import {styled} from "@mui/material/styles";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";

export const InvoiceDetail = (props) => {

    const CustomList = styled(List)({
        color: 'darkslategray',
        display:'flex',
        paddingTop:'0!important',
        '& .MuiListItem-root': {
            padding: '1px!important',
            '& .MuiListItemText-root':{
                color:'gray',
                padding:'0 10px 5px',
                borderRadius:'4px',
                background: '#f8f8f8',
                marginBottom:'2px',
                marginTop:'0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return (
        <Box>{ props.data &&
            <CustomList>
                <ListItem>
                    <ListItemText primary={"Company Name"} secondary={<Link to={'/customers/' + props.data?.customer?.customer_id}>{props.data?.customer?.company_name}</Link> || '-'}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Business"} secondary={<Link to={'/business/' + props.data?.business?.business_id}>{props.data?.business?.name}</Link> || '-'}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Service"} secondary={<Link to={'/service/' + props.data?.service?.service_id}>{props.data?.service?.amount}</Link> || '-'}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Remarks"} secondary={props.data?.remarks || '-'}/>
                </ListItem>
            </CustomList>
        }
        </Box>
    )
}