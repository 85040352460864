import React from 'react'
import {Card, CardActions, CardContent, Grid, Stack} from "@mui/material";
import {useSelector} from "react-redux";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

export const MonthlyPaymentCard = () => {

    const auth = useSelector(store => store.auth);
    const businessCreditStats = useSelector(store => store.dashboard.businessCreditStats);

    const data = [
        { month: 'Jan', paid: 5, balance: 2 },
        { month: 'Feb', paid: 3, balance: 6 },
        { month: 'Mar', paid: 8, balance: 1 },
        { month: 'Apr', paid: 4, balance: 3 },
        { month: 'May', paid: 7, balance: 2 },
        { month: 'Jun', paid: 2, balance: 5 },
        { month: 'Jul', paid: 6, balance: 4 },
        { month: 'Aug', paid: 1, balance: 7 },
        { month: 'Sep', paid: 5, balance: 3 },
        { month: 'Oct', paid: 3, balance: 6 },
        { month: 'Nov', paid: 4, balance: 2 },
        { month: 'Dec', paid: 9, balance: 1 },
    ];

    const series = [
        { data: data.map(item => item.paid), stack: 'A', label: 'Paid' },
        { data: data.map(item => item.balance), stack: 'B', label: 'Balance' },
    ];

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle " fontSize={11} fill="#666" transform="rotate(-0)">
                    {payload.value}
                </text>
            </g>
        );
    };


    return (
        <>
            <Card>
                <CardContent sx={{p:3}}>
                    <BarChart
                        width={700}
                        sx={{ width: '100%'}}
                        height={300}
                        data={data}
                    >
                        <XAxis dataKey="month" tick={<CustomizedAxisTick />} />
                        <YAxis />
                        <Tooltip />
                        <Legend wrapperStyle={{ marginTop: '10px', fontSize: '11px' }} />
                        <Bar dataKey="paid" stackId="a" fill="#1a90ff" barSize={10} radius={[0, 0, 0, 0]}/>
                        <Bar dataKey="balance" stackId="a" fill="#82ca9d" barSize={10} radius={[10, 10, 0, 0]} />
                    </BarChart>
                </CardContent>
            </Card>
        </>
    );
}
