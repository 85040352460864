import React from "react";
import {
    Grid, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {setSelectedFinancial, updateFinancial} from "../../index";
import MuiDatepicker from "../Datepicker";
import Toastr from "../../../Components/Common/Toastr";
import AttachDoc from "../../../Documents/Components/AttachDoc";

export const FinancialForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(business.financials.selected);
    }, [])

    const handleSubmit = async () => {
        setDisabled(true);
        await updateFinancial(dispatch, data).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}));
        dispatch(setSelectedFinancial(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Financial Performance
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>

                                <TextField
                                    fullWidth
                                    label="Auditor Name"
                                    name="auditor_name"
                                    defaultValue={data?.auditor_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.auditor_name}
                                    onChange={handleChange}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.financial_year} past={true} setDisabled={setDisabled} label={"Financial Year Ending"} name="financial_year" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Total Revenue"
                                    name="total_revenue"
                                    defaultValue={data?.total_revenue}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.total_revenue}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Gross Profit"
                                    name="gross_profit"
                                    defaultValue={data?.gross_profit}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.gross_profit}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Staff Salary"
                                    name="staff_salary"
                                    defaultValue={data?.staff_salary}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.staff_salary}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Net Profit"
                                    name="net_profit"
                                    defaultValue={data?.net_profit}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.net_profit}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Equity"
                                    name="equity"
                                    defaultValue={data?.equity}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.equity}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Loan Liability"
                                    name="loan_liability"
                                    defaultValue={data?.loan_liability}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.loan_liability}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Trade Receivable"
                                    name="trade_receivable"
                                    defaultValue={data?.trade_receivable}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.trade_receivable}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Other Receivable"
                                    name="other_receivable"
                                    defaultValue={data?.other_receivable}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.other_receivable}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Trade Payable"
                                    name="trade_payable"
                                    defaultValue={data?.trade_payable}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.trade_payable}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Other Payable"
                                    name="other_payable"
                                    defaultValue={data?.other_payable}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.other_payable}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Stock"
                                    name="stock"
                                    defaultValue={data?.stock}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.stock}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Fixed Assets"
                                    name="fixed_assets"
                                    defaultValue={data?.fixed_assets}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.fixed_assets}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Bank Balance"
                                    name="bank_balance"
                                    defaultValue={data?.bank_balance}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.bank_balance}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Cash Balance"
                                    name="cash_balance"
                                    defaultValue={data?.cash_balance}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.cash_balance}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    name="remarks"
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />

                                <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateFinancial} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Audited Financial/Management Report"} entity_name={"financials"} entity_id={data?.id} name="financial_report_doc" data={data} state={setData} />

                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

