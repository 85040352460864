import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED WPS_DETAIL
 ****************************************/
export const setSelectedWpsDetail = (payload) => ({
    type: CONST.SET_SELECTED_WPS_DETAIL,
    payload: payload
})
/****************************************
 FETCH WPS_DETAIL
 ****************************************/
export const fetchWpsDetailsSuccess = (payload) => ({
    type: CONST.FETCH_WPS_DETAILS_SUCCESS,
    payload: payload
})

export const fetchWpsDetailsFailed = (payload) => ({
    type: CONST.FETCH_WPS_DETAILS_FAILED,
    payload: payload
})

export const fetchWpsDetails = async (dispatch, id) => {

    await axiosPrivate.get('business/'+ id + '/wpsDetail').then((response) => {
        dispatch(fetchWpsDetailsSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchWpsDetailsFailed(error?.response));
    });
}
/****************************************
 ADD Business WPS_DETAIL
 ****************************************/
export const addWpsDetailSuccess = (payload) => ({
    type: CONST.ADD_WPS_DETAIL_SUCCESS,
    payload: payload
})

export const addWpsDetailFailed = (payload) => ({
    type: CONST.ADD_WPS_DETAIL_FAILED,
    payload: payload
})

export const addWpsDetail = async (dispatch, data) => {

    await axiosPrivate.post('wpsDetail', data).then((response) => {
        dispatch(addWpsDetailSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        console.log(error);
        return dispatch(addWpsDetailFailed(error?.response));
    });
}
/****************************************
 Update Business WPS_DETAIL
 ****************************************/
export const updateWpsDetailSuccess = (payload) => ({
    type: CONST.UPDATE_WPS_DETAIL_SUCCESS,
    payload: payload
})

export const updateWpsDetailFailed = (payload) => ({
    type: CONST.UPDATE_WPS_DETAIL_FAILED,
    payload: payload
})

export const updateWpsDetail = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('wpsDetail/' + data['id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateWpsDetailSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateWpsDetailFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business WPS_DETAIL
 ****************************************/
export const deleteWpsDetailSuccess = (payload) => ({
    type: CONST.DELETE_WPS_DETAIL_SUCCESS,
    payload: payload
})

export const deleteWpsDetailFailed = (payload) => ({
    type: CONST.DELETE_WPS_DETAIL_FAILED,
    payload: payload
})

export const deleteWpsDetail = async (dispatch, id) => {

    await axiosPrivate.delete('wpsDetail/' + id).then((response) => {
        dispatch(deleteWpsDetailSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteWpsDetailFailed(error?.response));
    });
}
