const CONST = {
    'PERFORM_LOGIN_SUCCESS': 'APP/LOGIN_SUCCESS',
    'PERFORM_LOGIN_FAILED': 'APP/LOGIN_FAILED',
    'PERFORM_LOGIN_LOADING': 'APP/LOGIN_LOADING',
    'PERFORM_LOGOUT_SUCCESS': 'APP/LOGOUT_SUCCESS',
    'PERFORM_LOGOUT_FAILED': 'APP/LOGOUT_FAILED',
    'PERFORM_LOGOUT_LOADING': 'APP/LOGOUT_LOADING',
    'PERFORM_REFRESH_TOKEN': 'APP/REFRESH_TOKEN',
    'LOGIN_SUCCESS': 'AUTH/LOGIN_SUCCESS',
    'LOGIN_FAILED': 'AUTH/LOGIN_FAILED',
}

export default CONST;