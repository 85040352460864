import React, {useEffect, useState} from 'react'
import {CardContent, CardHeader, } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {fetchAccounts} from "../Redux/AccountAction";
import {currencyFormatter} from "../../../Setup/Config";
import {LoanDetail} from "../../BusinessFinance";
import {Panel} from "../../Components";
import {SkeletonTable} from "../../Components/Common/SkeletonTable";
import {Chip} from "../../Components/Common/Chip";

export const UpcomingPayments = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const accounts = useSelector((store) => store.accounts);
    const [showSkeleton, setShowSkeleton] = useState(true);

    const [filters, setFilters] = React.useState({
        pageSize: '',
        pageNo:1,
        keyword:'',
        status:['PENDING','PARTIAL']
    });

    useEffect(() => {
        fetch();
    },[])

    const fetch = async () => {
        setShowSkeleton(true);
        await fetchAccounts(dispatch, filters).then((res) => {
            setShowSkeleton(false);
        })
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => row.loan !== null ? <LoanDetail data={row.loan} /> : null,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            { field: 'service_type', headerName:'Payment for', type: 'string', flex:1, minWidth: 220,
                valueGetter: (params) => {
                    return (params.row?.service?.service_type);
                }
            },
            { field: 'amount', headerName:'Amount', type: 'number',flex:1, minWidth: 80, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'due_date', headerName:'Due Date', type: 'string',flex:1, minWidth: 100, valueFormatter: ({ value }) => value && moment(value).format('DD/MM/YYYY')},
            { field: 'status', headerAlign:'center', align:'center', headerName:'Status', type: 'string',flex:1, minWidth:100,
                renderCell: (params) => {

                    const status = {
                        'CHEQUE_BOUNCED':'error',
                        'CHEQUE_DEPOSITED':'primary',
                        'CHEQUE_ON_HOLD':'warning',
                        'PENDING':'warning',
                        'PAID':'success',
                        'PARTIAL':'warning',
                        'OVERDUE':'error'
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    ></Chip>)
                },
            },

        ],
        [],
    );

    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Upcoming Repayments'} />
                <CardContent sx={{p:0}}>
                    { !showSkeleton ?
                        <DataGridPro
                            getRowId={(row) => row.account_id}
                            pageSize={filters.pageSize}
                            pageSizeOptions={[5, 10, 25]}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            rows={accounts.list || []}
                            sx={{ border:'none'}}
                            rowThreshold={0}
                            getDetailPanelContent={getDetailPanelContent}
                            getDetailPanelHeight={getDetailPanelHeight}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 5 } },
                                pinnedColumns: { right: [ 'status','actions'] },
                                columns:{
                                    columnVisibilityModel:{
                                        actions: auth.user.role_type !== "CLIENT",
                                    }
                                }
                            }}
                            autoHeight={true}
                            pagination={true}
                        />
                        :
                        <SkeletonTable rows={5} cols={4} />
                    }
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
