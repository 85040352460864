import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import { OtherBorrowingForm } from "../Forms/OtherBorrowingForm";
import {Grid, Stack, useMediaQuery} from "@mui/material";
import {
    addPersonalBorrowing,
    deletePersonalBorrowing,
    clearErrors,
    proceed,
    setSelectedPersonalBorrowing,
    OtherBorrowingDeleteModal
} from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function OtherBorrowingStep() {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.personalBorrowings).length > 0 ? business.personalBorrowings.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addPersonalBorrowing(dispatch, {business_id:business.selected.business_id});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedPersonalBorrowing(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<OtherBorrowingForm />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<OtherBorrowingDeleteModal />, state:true}));
        dispatch(setSelectedPersonalBorrowing(e.currentTarget.dataset.id));
    }

    const currencyFormatter = new Intl.NumberFormat('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2});

    const columns = React.useMemo(
        () => [
            { field: 'lender_name', headerName:'Lender Name', type: 'string',width:220},
            { field: 'currency', headerName:'Currency', type: 'string',width:80 },
            { field: 'limit', headerName:'Borrowing Limit', type: 'number', width:130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'outstanding', headerName:'Outstanding', type: 'number', width:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'overdue', headerName:'Overdue', type: 'number', width:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
            <Grid container>
                <Grid item xs={12}>
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.id}
                        pageSize={filters.pageSize}
                        onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                        onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                        columns={columns}
                        hideFooterPagination
                        rows={objects} sx={{ border:'none'}}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            pinnedColumns: { right: ['actions'] }
                        }}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Other Borrowing</Button>
                    </Stack>
                </Grid>
            </Grid>
    );
}