import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


//setSelectedAccount
export const resetAccounts = (payload) => ({
    type: CONST.RESET_ALL,
    payload:null
})
export const setSelectedAccount = (payload) => ({
    type: CONST.SET_SELECTED_ACCOUNT,
    payload: payload
})

/****************************************
 FETCH PAYMENT
 ****************************************/
export const fetchAccountSuccess = (payload) => ({
    type: CONST.FETCH_ACCOUNT_SUCCESS,
    payload: payload
})

export const fetchAccountFailed = (payload) => ({
    type: CONST.FETCH_ACCOUNT_FAILED,
    payload: payload
})

export const fetchAccount = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.get('account/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchAccountSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchAccountFailed(error?.response));
    });
    return res;
}

/****************************************
 FETCH PAYMENTS
 ****************************************/
export const fetchAccountsSuccess = (payload) => ({
    type: CONST.FETCH_ACCOUNTS_SUCCESS,
    payload: payload
})

export const fetchAccountsFailed = (payload) => ({
    type: CONST.FETCH_ACCOUNTS_FAILED,
    payload: payload
})

export const fetchAccounts = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.get('account', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchAccountsSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchAccountsFailed(error?.response));
    });
    return res;
}


/****************************************
 ADD PAYMENT
 ****************************************/
export const addAccountSuccess = (payload) => ({
    type: CONST.ADD_ACCOUNT_SUCCESS,
    payload: payload
})

export const addAccountFailed = (payload) => ({
    type: CONST.ADD_ACCOUNT_FAILED,
    payload: payload
})

export const addAccount = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('account', data).then((response) => {
        res['success'] = response;
        dispatch(addAccountSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addAccountFailed(error?.response));
    });
    return res;
}
/****************************************
 UPDATE PAYMENT
 ****************************************/
export const updateAccountSuccess = (payload) => ({
    type: CONST.UPDATE_ACCOUNT_SUCCESS,
    payload: payload
})

export const updateLoanScheduleFailed = (payload) => ({
    type: CONST.UPDATE_ACCOUNT_FAILED,
    payload: payload
})

export const updateAccount = async (dispatch, data) => {

    data['is_active'] = 1;
    let res = {};

    await axiosPrivate.patch('account/' + data['account_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateAccountSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateLoanScheduleFailed(error?.response));
    });
    return res;
}


/****************************************
 DELETE PAYMENT
 ****************************************/
export const deleteAccountSuccess = (payload) => ({
    type: CONST.DELETE_ACCOUNT_SUCCESS,
    payload: payload
})

export const deleteAccountFailed = (payload) => ({
    type: CONST.DELETE_ACCOUNT_FAILED,
    payload: payload
})

export const deleteAccount = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.delete('account/' + data['id']).then((response) => {
        res['success'] = response;
        dispatch(deleteAccountSuccess(data['id']));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(deleteAccountFailed(error?.response));
    });
    return res;
}


