import CONST from "./constant";

export const INITIAL_STATE = {
    user:{},
    isLoggedIn:false,
    token:undefined,
    error:undefined,
    fetching:false
};

const authReducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.LOGIN_SUCCESS:
            return {
                user: action.payload.data?.user,
                isLoggedIn:true,
                token: action.payload.data?.access_token,
                error: undefined,
                fetching: false
            }
        case CONST.LOGIN_FAILED:
            return {
                fetching: false,
                error: action.payload
            }
        case CONST.PERFORM_LOGIN_LOADING:
            return {
                fetching: true
            }
        case CONST.PERFORM_LOGOUT_SUCCESS:
            return{
                user: undefined,
                isLoggedIn: false,
                token: undefined,
                fetching: false,
                error: undefined
            }
        case CONST.PERFORM_LOGOUT_FAILED:
            return {
                fetching: false,
                error: action.payload
            }
        case CONST.PERFORM_LOGOUT_LOADING:
            return {
                fetching: true
            }
        case CONST.PERFORM_REFRESH_TOKEN:
            return {
                user: action.payload.user,
                isLoggedIn: true,
                token: action.payload.access_token,
                error: undefined,
                fetching: false
            }
        default:
            return state;
    }
}

export default authReducer;