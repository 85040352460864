import {styled, useTheme} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect, useRef} from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {openLeftDrawer, setAppBarHeight} from "../../../Setup/Redux/ConfigAction";
import AccountMenu from "../Menu/AccountMenu";

//MAIN
const AppBar = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const config = useSelector((store) => store.config );
    const open = config.openLeftDrawer;
    const DrawerWidth = config.leftDrawerWidth;

    const AppBar = styled(MuiAppBar, {

        shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme, open }) => ({
        background:theme.navbarBackgroundColor,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: DrawerWidth,
            width: `calc(100% - ${DrawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const appBarRef = useRef(null)

    //To get the height of appbar and use it somewhere.
    useEffect(()=>{
        dispatch(setAppBarHeight(appBarRef.current.clientHeight));
    },[])

    return (
        <AppBar position="fixed" open={open} ref={appBarRef}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={ () => dispatch(openLeftDrawer(true))}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    Ifund - Invoice App
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
                <AccountMenu />
            </Toolbar>
        </AppBar>
    )
}

export default AppBar;