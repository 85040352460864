import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {fetchServices, setSelectedService} from "../../../Services";
import {fetchBusiness, fetchCustomer, fetchCustomers} from "../../../Business";
import {IssueLoanModal, LoanDetail} from "../../../BusinessFinance";
import {
    DataGridPro,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";
import { CardContent, CardHeader, Chip} from "@mui/material";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {SkeletonTable} from "../../../Components/Common/SkeletonTable";
import {Panel} from "../../../Components";
import {CustomerDetail} from "../CustomerDetail";
import {useNavigate} from "react-router-dom";


export const MyCustomersPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const customers = useSelector((store) => store.business.customers);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [filters, setFilters] = React.useState({
        pageSize: '',
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {
        setShowSkeleton(true);
        await fetchCustomers(dispatch, filters).then((res) => {
            setShowSkeleton(false);
        })
    }

    const viewItem = async (id) => {
        await fetchCustomer(dispatch, id);
        navigate('/customers/' + id)
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <CustomerDetail data={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
                ),
            },
            { field: 'company_name', headerName:'Company Name', type: 'string', minWidth:200, flex: 1,},
            { field: 'assigned_to', headerName:'Assigned to', type: 'string', minWidth:100, flex: 1,
                valueGetter: (params) => {
                    return params.row?.assigned_to[0]?.first_name;
                }
            },
            { field: 'assigned_at', headerName:'Assigned At', type: 'string', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).from(),
                valueGetter: (params) => {
                    return params.row?.assigned_to[0]?.created_at;
                }
            },
            { field: 'status', headerName:'Status', type: 'string', flex:1, minWidth:120,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'PENDING':'warning',
                        'SUBMITTED':'info',
                        'VERIFIED':'success',
                        'PROCESSING':'primary',
                        'APPROVED':'success',
                        'REJECTED':'error',
                        'CANCELLED':'secondary',
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    >
                    </Chip>)
                },
            },
        ],
        [],
    );


    return (
        <>
            <Panel>
                <CardHeader title={'Customers'}  />
                <CardContent sx={{p:0}}>
                    { !showSkeleton ?
                    <DataGridPro
                        getRowId={(row) => row.customer_id}
                        pageSize={filters.pageSize}
                        onCellClick={(params) => {
                            if (params.field === 'company_name') {
                                viewItem(params.row.customer_id);
                            }
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                        onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                        columns={columns}
                        rows={customers.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 5 } },
                            pinnedColumns: { right: [ 'status','actions'] },
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            columns:{
                                columnVisibilityModel:{
                                    actions: auth.user.role_type !== "CLIENT",
                                }
                            }
                        }}
                        autoHeight={true}
                        pagination={true}
                    />
                    :
                        <SkeletonTable rows={5} cols={4} />
                    }
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};

