import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import { doLogout } from "../../Auth";



/****************************************
 FETCH SETTING
 ****************************************/
export const fetchSettingSuccess = (payload) => ({
    type: CONST.FETCH_SETTING_SUCCESS,
    payload: payload
})

export const fetchSettingFailed = (payload) => ({
    type: CONST.FETCH_SETTING_FAILED,
    payload: payload
})

export const fetchSetting = async (dispatch, id) => {

    await axiosPrivate.get('setting/' + id).then((response) => {
        dispatch(fetchSettingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchSettingFailed(error?.response));
    });
}

/****************************************
 FETCH SETTINGES
 ****************************************/
export const fetchSettingsSuccess = (payload) => ({
    type: CONST.FETCH_SETTINGS_SUCCESS,
    payload: payload
})

export const fetchSettingsFailed = (payload) => ({
    type: CONST.FETCH_SETTINGS_FAILED,
    payload: payload
})

export const fetchSettings = async (dispatch, data) => {

    await axiosPrivate.get('settings', data).then((response) => {
        dispatch(fetchSettingsSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchSettingsFailed(error?.response));
    });
}

/****************************************
 ADD SETTING
 ****************************************/
export const addSettingSuccess = (payload) => ({
    type: CONST.ADD_SETTING_SUCCESS,
    payload: payload
})

export const addSettingFailed = (payload) => ({
    type: CONST.ADD_SETTING_FAILED,
    payload: payload
})

export const addSetting = async (dispatch, data) => {
    await axiosPrivate.post('setting', data).then((response) => {
        dispatch(addSettingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addSettingFailed(error?.response));
    });
}


/****************************************
 UPDATE SETTING
 ****************************************/
export const updateSettingSuccess = (payload) => ({
    type: CONST.UPDATE_SETTING_SUCCESS,
    payload: payload
})

export const updateSettingFailed = (payload) => ({
    type: CONST.UPDATE_SETTING_FAILED,
    payload: payload
})

export const updateSetting = async (dispatch, data) => {

    await axiosPrivate.patch('setting/' + data['id'], data).then((response) => {
        dispatch(updateSettingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updateSettingFailed(error?.response));
    });
}


/****************************************
 DELETE SETTING
 ****************************************/
export const deleteSettingSuccess = (payload) => ({
    type: CONST.DELETE_SETTING_SUCCESS,
    payload: payload
})

export const deleteSettingFailed = (payload) => ({
    type: CONST.DELETE_SETTING_FAILED,
    payload: payload
})

export const deleteSetting = async (dispatch, data) => {
    await axiosPrivate.delete('setting/' + data['id']).then((response) => {
        //TODO::prepare response
        dispatch(deleteSettingSuccess(data['id']));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(deleteSettingFailed(error?.response));
    });
}