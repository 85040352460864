import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card, CardContent, CardHeader} from "@mui/material";
import {fetchLoan, fetchLoans} from "../../Redux/LoanAction";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment";
import {currencyFormatter} from "../../../../Setup/Config";
import {Chip} from "../../../Components/Common/Chip";


export default function LoanTable() {

    const loans = useSelector((store) => store.loans);
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchLoans(dispatch);
    },[]);

    const viewItem = React.useCallback(
        (id) => async () => {
            await fetchLoan(dispatch, id);
            navigate('/business-finance/' + id);
        },
        [],
    );

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        fetchLoans(dispatch, filters);

        return ()=>{
            isMounted = false;
            controller.abort()
        }
    },[filters]);

    const columns = React.useMemo(
        () => [
            { field: 'business_name', headerName:'Business', type: 'string', minWidth:250, flex: 1,
                valueGetter: (params) => {
                    return params.row?.business?.name;
                }
            },
            { field: 'service_type', headerName:'Service Type', type: 'string', minWidth: 220, flex:1,
                valueGetter: (params) => {
                    return params.row?.service?.service_type;
                }
            },
            { field: 'amount', headerName:'Amount', type: 'number', flex:1, minWidth:80, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'fee_included', align:'center', headerAlign:"center", headerName:'Fee Included', type: 'singleSelect', flex:1, minWidth:120,
                valueOptions: [{"value":1, "label":"Yes"},{"value":0, "label":"No"}],
            },
            { field: 'fee_clubbed', align:'center', headerAlign:"center", headerName:'Fee Clubbed', type: 'singleSelect', flex:1, minWidth:120,
                valueOptions: [{"value":1, "label":"Yes"},{"value":0, "label":"No"}],
            },
            { field: 'start_date', headerName:'Start Date', type: 'string', flex:1, minWidth:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),
            },
            { field: 'end_date', headerName:'End Date', type: 'string', flex:1, minWidth:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY") },
            { field: 'repayment_period', align:'center', headerAlign:"center", headerName:'Period', type: 'string', flex:1, minWidth: 80 },
            { field: 'repayment_frequency', align:'center', headerAlign:"center", headerName:'Frequency', type: 'string', flex:1, minWidth: 90 },
            { field: 'status', align:'center', headerAlign:"center", headerName:'Status', type: 'string', flex:1, minWidth:220,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'SUBMITTED':'info',
                        'ACTIVE':'info',
                        'PAID':'success',
                        'DOCUMENTED':'info',
                        'PENDING':'warning',
                        'DOCUMENT_PENDING':'warning',
                        'RETURNED_DOCUMENTATION':'warning',
                        'DISBURSE_PENDING':'warning',
                        'DEFAULT':'error',
                        'CANCELLED':'error',
                        'DELETED':'error',
                        'PROCESSING':'primary',
                        'VERIFIED':'success',
                        'APPROVED':'success',
                        'RETURNED':'warning',
                        'REJECTED':'error'
                    }
                    return(<Chip
                                variant="contained"
                                sx={{fontWeight:'normal'}}
                                label={params.value}
                                color={status[params.value]}
                            />)
                },
            },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', flex:1, minWidth:160, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:MM:SS"), },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth: 100,
                getActions: (params) => [
                    (['DRAFT','RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT') ||
                    (['SUBMITTED','PROCESSING','PENDING'].includes(params.row.status) && auth.user.role_type !== 'CLIENT')
                        ?
                        <GridActionsCellItem
                            icon={<EditIcon sx={{zIndex:-1}} />}
                            label="Edit"
                            data-id={params.id}
                            data-action={"EditItem"}
                        />
                    :
                        <GridActionsCellItem
                            icon={<FindInPageIcon />}
                            label="View"
                            onClick={ viewItem(params.id) }
                        />
                    ,
                ],
            },
        ],
        [],
    );

    return (
            <>
                <Card>
                    <CardHeader title={"Business Finance"} />
                    <CardContent sx={{p:0}}>
                        <DataGridPro
                            getRowId={(row) => row.loan_id}
                            pageSize={filters.pageSize}
                            rowsPerPageOptions={[5, 10, 20]}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            rows={loans.list || []}
                            sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'start_date', sort: 'desc' }, { field: 'status', sort: 'asc' }],
                                },
                                pinnedColumns: { right: ['status','actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                    }
                                }
                            }}
                            autoHeight={true}
                        />
                    </CardContent>
                </Card>
            </>
        );
}
