import React from "react";
import {
    Grid, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import { setSelectedWpsDetail, updateWpsDetail } from "../../index";
import MuiDatepicker from "../Datepicker";
import AttachDoc from "../../../Documents/Components/AttachDoc";
import Toastr from "../../../Components/Common/Toastr";
import Typography from "@mui/material/Typography";

export const WpsDetailForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState(false);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(business.wpsDetails.selected);
    }, [])

    const handleSubmit = async () => {
        setDisabled(true);
        await updateWpsDetail(dispatch, data).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}))
        dispatch(setSelectedWpsDetail(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                WPS Details
                <Typography>Salary for last 3 months</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>

                                <MuiDatepicker error={business?.error?.salary_month} setDisabled={setDisabled} label={"Salary Month"} past={true} name="salary_month" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="No of Employees"
                                    name="no_of_staffs"
                                    defaultValue={data?.no_of_staffs}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.no_of_staffs}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Total Salary Amount"
                                    name="total_salary"
                                    defaultValue={data?.total_salary}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.total_salary}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Amount Paid"
                                    name="amount_paid"
                                    defaultValue={data?.amount_paid}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.amount_paid}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.payment_date} setDisabled={setDisabled} label={"Salary Payment Date"} past={true} name="payment_date" data={data} state={setData}></MuiDatepicker>

                                <TextField
                                    fullWidth
                                    label="Overdue Amount (if any)"
                                    name="overdue_salary_amount"
                                    defaultValue={data?.overdue_salary_amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.overdue_salary_amount}
                                    onBlur={handleChange} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateWpsDetail} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"WPS Statement"} entity_name={"wpsDetails"} entity_id={data?.id} name="wps_statement_doc" data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateWpsDetail} allowedFileType={['pdf']} onBlur={handleChange} label={"MOL List"} entity_name={"wpsDetails"} entity_id={data?.id} name="mol_list_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    name="remarks"
                                    multiline={true}
                                    rows={3}
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />
                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

