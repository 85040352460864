import * as React from 'react';
import { Grid, Paper, Stack, Tab, Tabs} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

import OwnershipCard from "./Cards/OwnershipCard";
import FinancialCard from "./Cards/FinancialCard";
import BankBorrowingCard from "./Cards/BankBorrowingCard";
import BankDetailCard from "./Cards/BankDetailCard";
import ChequeCard from "./Cards/ChequeCard";
import CreditCard from "./Cards/CreditCard";
import CustomerSupplierCard from "./Cards/CustomerSupplierCard";
import OtherBorrowingCard from "./Cards/OtherBorrowingCard";
import VatReturnCard from "./Cards/VatReturnCard";
import WpsDetailCard from "./Cards/WpsDetailCard";
import CompanyDetailCard from "./Cards/CompanyDetailCard";
import {useState} from "react";
import {
    fetchAddresses,
    fetchBankBorrowings,
    fetchBankDetails,
    fetchCheques,
    fetchCreditCards,
    fetchCustomers,
    fetchCustomerSuppliers,
    fetchFinancials,
    fetchOwnership,
    fetchPersonalBorrowings,
    fetchVatReturns,
    fetchWpsDetails,
    ActionButtons,
    BusinessConfigPanel,
    BusinessPropertiesPanel,
} from "../index";
import CompanyLocationCard from "./Cards/CompanyLocationCard";
import DocumentsList from "../../Documents/Components/DocumentsList";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import CustomerCard from "./Cards/CustomerCard";
import ReportsList from "../../Documents/Components/ReportsList";

export default function TabView() {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const auth = useSelector((store) => store.auth);
    const [tab, setTab] = useState("business");
    const [entityId, setEntityId] = useState(null);
    const [response, setResponse] = useState({});

    const handleChange = async (e, newValue) => {

        setTab(newValue);
        const business_id = business.selected.business_id;

        switch (newValue) {
            case 'ownership':
                await fetchOwnership(dispatch, business_id);
                break;
            case 'financials':
                await fetchFinancials(dispatch,business_id);
                break;
            case 'bankDetails':
                await fetchBankDetails(dispatch,business_id);
                break;
            case 'bankBorrowings':
                await fetchBankBorrowings(dispatch,business_id);
                break;
            case 'creditCards':
                await fetchCreditCards(dispatch,business_id);
                break;
            case 'personalBorrowings':
                await fetchPersonalBorrowings(dispatch,business_id);
                break;
            case 'cheques':
                await fetchCheques(dispatch,business_id);
                break;
            case 'vatReturns':
                await fetchVatReturns(dispatch,business_id);
                break;
            case 'wpsDetails':
                await fetchWpsDetails(dispatch,business_id);
                break;
            case 'addresses':
                await fetchAddresses(dispatch,business_id);
                break;
            case 'customersSuppliers':
                await fetchCustomerSuppliers(dispatch,{business_id, for_invoice_discounting:0});
                break;
            case 'customers':
                await fetchCustomers(dispatch,{business_id, for_invoice_discounting:1});
                break;
            default:
                console.log("-");
        }
    }

    return (
        <>
            { business !== undefined  &&
                <Grid container spacing={2} elevated={3}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <IconBreadcrumbs items={'DASHBOARD/BUSINESS'} />
                            <ActionButtons />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Stack spacing={2}>
                            <Paper>
                                <Tabs
                                    value={tab}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="Company Details" value="business"/>
                                    <Tab label="Partners" value="ownership"/>
                                    <Tab label="Financial Numbers" value="financials"/>
                                    <Tab label="Customers & Suppliers" value="customersSuppliers"/>
                                    <Tab label="Bank Details" value="bankDetails"/>
                                    <Tab label="Bank Borrowings" value="bankBorrowings"/>
                                    <Tab label="Credit Cards" value="creditCards"/>
                                    <Tab label="Other Borrowings" value="personalBorrowings"/>
                                    <Tab label="Returned Cheques" value="cheques"/>
                                    <Tab label="VAT Returns" value="vatReturns"/>
                                    <Tab label="WPS Details" value="wpsDetails"/>
                                    <Tab label="Company Locations" value="addresses"/>
                                    <Tab label="Customers" value="customers"/>
                                </Tabs>
                            </Paper>
                            {
                                (() => {
                                    switch (tab) {
                                        case 'business':
                                            return <CompanyDetailCard business={business}/>
                                        case 'ownership':
                                            return <OwnershipCard setEntityId={setEntityId} />
                                        case 'financials':
                                            return <FinancialCard setEntityId={setEntityId}/>
                                        case 'bankDetails':
                                            return <BankDetailCard setEntityId={setEntityId}/>
                                        case 'bankBorrowings':
                                            return <BankBorrowingCard setEntityId={setEntityId}/>
                                        case 'creditCards':
                                            return <CreditCard setEntityId={setEntityId}/>
                                        case 'personalBorrowings':
                                            return <OtherBorrowingCard setEntityId={setEntityId}/>
                                        case 'cheques':
                                            return <ChequeCard setEntityId={setEntityId}/>
                                        case 'vatReturns':
                                            return <VatReturnCard setEntityId={setEntityId}/>
                                        case 'wpsDetails':
                                            return <WpsDetailCard setEntityId={setEntityId}/>
                                        case 'addresses':
                                            return <CompanyLocationCard setEntityId={setEntityId}/>
                                        case 'customersSuppliers':
                                            return <CustomerSupplierCard setEntityId={setEntityId}/>
                                        case 'customers':
                                            return <CustomerCard setEntityId={setEntityId}/>
                                        default:
                                    }
                                })()
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={2}>
                            { ((auth.user.permissions).includes('show-business-documents') || auth.user.role_type === 'ADMIN') && <DocumentsList setResponse={setResponse} entityId={tab === 'business' ? business.selected.business_id : entityId } tab={tab}/> }
                            { ((auth.user.permissions).includes('show-business-reports') || auth.user.role_type === 'ADMIN') && <ReportsList /> }
                            <BusinessPropertiesPanel />
                            { ((auth.user.permissions).includes('show-business-configurations') || auth.user.role_type === 'ADMIN') && <BusinessConfigPanel /> }
                        </Stack>
                    </Grid>
                </Grid>
            }
        </>
    );
}
