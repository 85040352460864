import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import LocalFsModal from "../../Components/Common/LocalFsModal";
import {
    DocumentationCompleteModal,
    RequestDocumentationModal,
    RequestDisbursementModal,
    DisbursementCompleteModal
} from "../index";
import {ReturnDocumentationModal} from "./Modal/ReturnDocumentationModal";

export default function ActionButtons() {

    const dispatch = useDispatch();
    const loan = useSelector((store) => store.loans.selected);
    const [open, setOpen] = React.useState(false);
    const [openLocalModal, setOpenLocalModal] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedColor, setSelectedColor] = React.useState();
    const [options, setOptions] = React.useState([]);
    const auth = useSelector((store) => store.auth);
    const [action, setAction] = useState();

    const handleClick = (e) => {
        setOpenLocalModal(true);
        setAction(options[selectedIndex].key);
    };

    const modal = () => {
        switch (action) {
            case 'request-documentation':
                return <LocalFsModal open={openLocalModal} setOpen={setOpenLocalModal} component={<RequestDocumentationModal loan_id={loan.loan_id} setOpen={setOpenLocalModal} />}/>;
            case 'process-documentation':
                return <LocalFsModal open={openLocalModal} setOpen={setOpenLocalModal} component={<DocumentationCompleteModal loan_id={loan.loan_id} setOpen={setOpenLocalModal} />}/>;
            case 'request-disbursement':
                return <LocalFsModal open={openLocalModal} setOpen={setOpenLocalModal} component={<RequestDisbursementModal loan_id={loan.loan_id} setOpen={setOpenLocalModal} />}/>;
            case 'process-disbursement':
                return <LocalFsModal open={openLocalModal} setOpen={setOpenLocalModal} component={<DisbursementCompleteModal loan_id={loan.loan_id} setOpen={setOpenLocalModal} />}/>;
            case 'return-documentation':
                return <LocalFsModal open={openLocalModal} setOpen={setOpenLocalModal} component={<ReturnDocumentationModal loan_id={loan.loan_id} setOpen={setOpenLocalModal} />}/>;
            default:
                return <></>;
        }
    }

    React.useEffect(()=>{
        const o = [];
        // Credit Manager requests documentation team to ready the documentation for loan
        if ((['request-documentation'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['DRAFT'].includes(loan.status)) {
            o.push({'key':'request-documentation','value':'Request Documentation','color':'info'});
        }
        // Documentation team process the documentation for Loan
        if ((['process-documentation'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['DOCUMENT_PENDING','RETURNED_DOCUMENTATION'].includes(loan.status)) {
            o.push({'key':'process-documentation','value':'Process Documentation','color':'warning'});
        }
        if ((['return-documentation'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['DISBURSE_PENDING'].includes(loan.status)) {
            o.push({'key':'return-documentation','value':'Return Documentation','color':'warning'});
        }
        // Disbursement team process the disbursement process.
        if ((['process-disbursement'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['DISBURSE_PENDING'].includes(loan.status)) {
            o.push({'key':'process-disbursement','value':'Process Disbursement','color':'error'});
        }

        const u = [...new Map(o.map(item => [item['key'], item])).values()];
        setOptions(u);
        setSelectedIndex(0);
    },[loan]);

    const handleMenuItemClick = (event, index, color) => {
        setSelectedIndex(index);
        setSelectedColor(color);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            {options.length > 0 &&
                <>
                    <ButtonGroup variant="contained" color={selectedColor} ref={anchorRef} aria-label="split button">
                        <Button onClick={handleClick}>{options[selectedIndex]?.value}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option.key}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index, option.color)}
                                                >
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    {modal()}
                </>
            }
        </>
    );
}