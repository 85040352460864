import * as React from 'react';
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {useDispatch, useSelector} from "react-redux";
import { openRightDrawer } from "../../../Setup/Redux/ConfigAction";

export default function RightDrawer(props) {
    const config = useSelector((store) => store.config);
    const dispatch = useDispatch();
    const open = config.openRightDrawer;
    const drawerWidth = config.rightDrawerWidth;

    const Drawer = styled(MuiDrawer)(
        () => ({
            zIndex: 9999,
                ...({
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    }
                })
        }
    ));

    return (
        <React.Fragment>
            <Drawer
                transition="true"
                anchor="right"
                open={open}
                onClose={ () => dispatch(openRightDrawer(false)) }
                sx={{ width:drawerWidth, maxWidth:'500px', left:'auto'}}
            >
                {props.component}
            </Drawer>
        </React.Fragment>
    );
}
