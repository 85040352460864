import React from 'react';
import {FormControl, Stack} from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {removeDocument} from "../Redux/DocumentAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useDispatch} from "react-redux";

export const DocumentPreviewModal = (props) => {
    const dispatch = useDispatch();
    const handleRemoveImage = async (e) => {
        const document_id = e.currentTarget.dataset.id;
        await removeDocument(dispatch, props, document_id).then((res) => {
            props.setResponse(res);
        });

    };
    const formatSizeUnits = (bytes) => {
        if (bytes < 1048576) {  // If less than 1MB
            return (bytes / 1024).toFixed(2) + ' KB';
        } else {
            return (bytes / 1048576).toFixed(2) + ' MB';
        }
    };

    return (
        <>
            {
                (props.documents?.length > 0) && props.documents?.map((doc, index) => (
                    <FormControl sx={{ background:'#f7f7ff', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', height:'65px', borderRadius:'4px', padding:'8px 15px'}}>
                        <DocumentScannerIcon sx={{ fontSize:'34px', 'color':'#396694'}} />
                        <Stack direction={"column"}>
                            <Typography sx={{fontSize:'13px', paddingLeft: '5px'}}>{ doc?.doc_name }</Typography>
                            <Typography style={{fontSize:'11px',display:'block'}}>{ formatSizeUnits(doc?.doc_size) }</Typography>
                        </Stack>
                        <Button onClick={handleRemoveImage} data-id={ doc?.document_id } sx={{padding:'0'}}><DeleteForeverIcon sx={{ fontSize:'24px', 'color':'#990000', zIndex:0}} /></Button>
                    </FormControl>
                ))
            }
        </>
    )
}