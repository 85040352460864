import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import { doLogout } from "../../Auth";

/****************************************
 SELECT ROLE
 ****************************************/
export const setSelectedRole = (payload) => ({
    type: CONST.SET_SELECTED_ROLE,
    payload: payload
})

/****************************************
 FETCH ROLE
 ****************************************/
export const fetchRoleSuccess = (payload) => ({
    type: CONST.FETCH_ROLE_SUCCESS,
    payload: payload
})

export const fetchRoleFailed = (payload) => ({
    type: CONST.FETCH_ROLE_FAILED,
    payload: payload
})

export const fetchRole = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('role/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchRoleSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchRoleFailed(error?.response));
    });
    return res;
}

/****************************************
 FETCH ROLES
 ****************************************/
export const fetchRolesSuccess = (payload) => ({
    type: CONST.FETCH_ROLES_SUCCESS,
    payload: payload
})

export const fetchRolesFailed = (payload) => ({
    type: CONST.FETCH_ROLES_FAILED,
    payload: payload
})

export const fetchRoles = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.get('role', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchRolesSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchRolesFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD ROLE
 ****************************************/
export const addRoleSuccess = (payload) => ({
    type: CONST.ADD_ROLE_SUCCESS,
    payload: payload
})

export const addRoleFailed = (payload) => ({
    type: CONST.ADD_ROLE_FAILED,
    payload: payload
})

export const addRole = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('role', data).then((response) => {
        res['success'] = response;
        dispatch(addRoleSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addRoleFailed(error?.response));
    });
    return res;
}


/****************************************
 UPDATE ROLE
 ****************************************/
export const updateRoleSuccess = (payload) => ({
    type: CONST.UPDATE_ROLE_SUCCESS,
    payload: payload
})

export const updateRoleFailed = (payload) => ({
    type: CONST.UPDATE_ROLE_FAILED,
    payload: payload
})

export const updateRole = async (dispatch, data) => {
    let res= {}
    await axiosPrivate.patch('role/' + data['role_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateRoleSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updateRoleFailed(error?.response));
    });
    return res;
}


/****************************************
 DELETE ROLE
 ****************************************/
export const deleteRoleSuccess = (payload) => ({
    type: CONST.DELETE_ROLE_SUCCESS,
    payload: payload
})

export const deleteRoleFailed = (payload) => ({
    type: CONST.DELETE_ROLE_FAILED,
    payload: payload
})

export const deleteRole = async (dispatch, data) => {
    let res= {}
    await axiosPrivate.delete('role/' + data['role_id']).then((response) => {
        //TODO::prepare response
        res['success'] = response;
        dispatch(deleteRoleSuccess(data['role_id']));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(deleteRoleFailed(error?.response));
    });
    return res;
}