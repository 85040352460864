import * as React from 'react';
import { Card, CardContent, CardHeader} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {Panel, PanelList} from "../../../Components";

export default function InvoiceConfigPanel() {

    const invoice = useSelector((state) => state.invoices.selected);

    const CustomList = styled(List)({
        color: 'darkslategray',
        padding:0,
        '& .MuiListItem-root': {
            padding: '5px!important',
            borderBottom:'1px solid #f4f4f4',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return (
        <>
            { invoice !== undefined &&
                <Panel>
                    <CardHeader title={"Customer Configuration"} titleTypographyProps={{variant: 'heading'}}/>
                    <CardContent>
                        <PanelList>
                            <ListItem>
                                <ListItemText>Credit Limit Requested: { invoice.credit_limit_requested}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Credit Limit Recommended: { invoice.credit_limit_recommended}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Credit Limit Approved: { invoice.credit_limit_approved}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Max Allowed Tenor: { invoice.max_allowed_tenor}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Profit Rate: { invoice.profit_rate}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Discounting Percentage: { invoice.discounting_percentage}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Account Setup Fee: { invoice.account_setup_fee}</ListItemText>
                            </ListItem>
                        </PanelList>
                    </CardContent>
                </Panel>
            }
        </>
    );
}
