import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {Card, CardContent, CardHeader, useMediaQuery} from "@mui/material";
import { addCustomer, clearErrors, proceed, setSelectedCustomer, CustomerDeleteModal } from "../../index";
import Typography from "@mui/material/Typography";
import {CustomerForm} from "../Forms/CustomerForm";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect, useState} from "react";
import {currencyFormatter} from "../../../../Setup/Config";

export default function CustomerCard(props) {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.customers).length > 0 ? business.customers.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setFsModal({component:null, state:false}));
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCustomer(dispatch, {business_id:business.selected.business_id, is_customer:parseInt(e.target.dataset.is_customer), for_invoice_discounting:1});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCustomer(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<CustomerForm business={business} />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<CustomerDeleteModal />, state:true}));
        dispatch(setSelectedCustomer(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {

        props.setEntityId(param.row.customer_id);
    }

    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Customer Name', type: 'string', flex:1, minWidth:250},
            { field: 'dealing_since', headerName:'Dealing Since', type: 'string', flex:1, minWidth: 120},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', flex:1, minWidth:120},
            { field: 'currency', headerName:'Currency', type: 'string', flex:1, minWidth:120},
            { field: 'address', headerName:'Address', type: 'string', flex:1, minWidth:120},
            { field: 'company_contact_no', headerName:'Company Contact No', type: 'string', flex:1, minWidth:160},
            { field: 'country', headerName:'Country', type: 'string', flex:1, minWidth:120},
            { field: 'company_website', headerName:'Company Website', type: 'string', flex:1, minWidth:160},
            { field: 'products_desc', headerName:'Product Description', type: 'string', flex:1, minWidth:220},
            { field: 'payment_method', headerName:'Payment Method', type: 'string', flex:1, minWidth:120},
            { field: 'amount_previous', headerName:'Amount Previous', type: 'number', flex:1, minWidth:150, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'amount_projected', headerName:'Amount Projected', type: 'number', flex:1, minWidth:150, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'avg_invoice_value', headerName:'Avg Invoice Value', type: 'number', flex:1, minWidth:160, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'no_of_invoice', headerName:'No Of Invoice', type: 'string', flex:1, minWidth:120},
            { field: 'cp_name', headerName:'Contact Person Name', type: 'string', flex:1, minWidth:160},
            { field: 'cp_phone', headerName:'Contact Person Phone', type: 'string', flex:1, minWidth:160},
            { field: 'cp_mobile', headerName:'Contact Person Mobile', type: 'string', flex:1, minWidth:160},
            { field: 'cp_email', headerName:'Contact Person Email', type: 'string', flex:1, minWidth:180},
            { field: 'credit_limit_requested', headerName:'Credit Limit Requested', type: 'string', flex:1, minWidth:160,
                valueGetter: (params) => {
                    return (params.row?.config.find((o) => o.config_type === 'credit_limit_requested'))?.config_value;
                }
            },
            {
                flex:1,
                minWidth: 120,
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Invoice Discounting (customers)'}
                    action={
                        (
                            (['add-business-customers'].some(i => auth.user.permissions.includes(i)) && ['DRAFT','SUBMITTED'].some(i => business.selected.status.includes(i))) ||
                            ['su-edit-business-customer'].some(i => auth.user.permissions.includes(i)) ||
                            auth.user.role_type === 'ADMIN'
                        )
                        &&
                        <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} data-is_customer={1} onClick={handleActionBtn}>Add New</Button>
                    }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { objects !== undefined &&  Object.keys(objects).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.customer_id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={objects || []} sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left: ['company_name'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        card_id: false,
                                        actions: ((['DRAFT','RETURNED'].includes(business.selected.status) && auth.user.role_type === 'CLIENT') || (['SUBMITTED','PROCESSING','PENDING'].includes(business.selected.status) && auth.user.role_type !== 'CLIENT') || auth.user.role_type === 'ADMIN' || ['su-edit-business-customer'].some(i => auth.user.permissions.includes(i))),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}