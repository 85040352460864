import {useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {Chip,Box, FormControl, FormHelperText, InputLabel, Select, Stack, TextField, useMediaQuery} from "@mui/material";
import {fetchCustomers, setSelectedBusiness, setSelectedCustomer} from "../../../Business";
import {addService} from "../../Redux/ServiceAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toastr from "../../../Components/Common/Toastr";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchCustomer} from "../../../Customers";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";

export const NewServiceRequestModal = (props) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const config = useSelector((store) => store.config);
    const business = useSelector((store) => store.business.list);
    const customers = useSelector((store) => store.business.customers?.list);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [response, setResponse] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(true);
    const [data, setData] = useState({});
    const [showCustomerOption, setShowCustomerOption] = useState(false);
    const [showInvoiceOption, setShowInvoiceOption] = useState(false);

    useEffect(() => {
        setResponse(false);
        setData({});
        setShowCustomerOption(false);
        setShowInvoiceOption(false);
    },[])

    const handleChange = (e) => {

        setDisableFields(e.target.name === "business_id" && e.target.value === "")

        if(["business_id","service_type"].includes(e.target.name)){
            setShowCustomerOption(false);
        }

        if(["business_id"].includes(e.target.name)){
            dispatch(setSelectedBusiness(e.target.value));
        }

        if(e.target.name === "service_type" && e.target.value === "INVOICE_DISCOUNTING"){
            loadCustomers();
        }
        if(e.target.name === "customer_id"){
            if(e.target.value === "add-new"){
                navigate('/customers')
            }else{
                setShowInvoiceOption(true);
            }
        }

        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const loadCustomers = async () => {
        let payload = {
            business_id : data['business_id'],
            for_invoice_discounting : 1,
        }
        await fetchCustomers(dispatch, payload).then((res) => {
            setShowCustomerOption(true);
        })
    }

    const uploadInvoice = async () => {
        dispatch(setSelectedCustomer(data['customer_id']));
        await fetchCustomer(dispatch, data['customer_id']);
        dispatch(setFsModal({component:null, state: false}));
        navigate('/services/invoice-upload/', {state:{data}, replace: true})
    }

    const handleSubmit = async () => {
        setDisabled(true);
        data['status'] = 'PENDING';

        await addService(dispatch, data).then((res) => {
            setResponse(res);
            if(res['success']){
                setData({});
                setDisabled(false);
                dispatch(setFsModal({component:null, state:false}))
            }
        });
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={config.openFsModal}
                onClose={ () => dispatch(setFsModal({ component:null, state: false})) }
            >
                <DialogTitle>New Request</DialogTitle>
                <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                    <Stack spacing={2} sx={{mt:2}}>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="businesses">Select Business</InputLabel>
                            <Select
                                name="business_id"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Select Business"
                                value={data?.business_id ?? ""}
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">Select Business</MenuItem>
                                {
                                    (business.filter(o => o.status === 'APPROVED')).map((i,v) =><MenuItem key={i.business_id} value={i.business_id}>{i.name}</MenuItem>)
                                }
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.business_id}</FormHelperText>
                        </FormControl>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="card-type">Service Type</InputLabel>
                            <Select
                                disabled={disableFields}
                                name="service_type"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Service Type"
                                value={data?.service_type ?? ""}
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem key="" value="">Select Service</MenuItem>
                                <MenuItem key={"INVOICE_DISCOUNTING"} value={'INVOICE_DISCOUNTING'}>Invoice Discounting</MenuItem>
                                <MenuItem key={"RECEIVABLE_DISCOUNTING"} value={'RECEIVABLE_DISCOUNTING'}>Receivable Discounting</MenuItem>
                                <MenuItem key={"BUSINESS_FINANCE"} value={'BUSINESS_FINANCE'}>Business Finance</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.service_type}</FormHelperText>
                        </FormControl>
                        {showCustomerOption &&
                            <Stack direction={"column"} spacing={2}>
                                <FormControl sx={{ width:'100%' }}>
                                    <InputLabel id="businesses">Select Approved Customer</InputLabel>
                                    <Select
                                        name="customer_id"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        label="Select Approved Customer"
                                        value={data?.customer_id ?? ""}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem value="">Select Customer</MenuItem>
                                        {
                                            (customers.filter(o => o.status !== 'DRAFT')).map((i) => <MenuItem key={i.customer_id} value={i.customer_id} disabled={i.status !== 'APPROVED'} sx={{justifyContent:'space-between'}}>{i.company_name} <Chip color={"secondary"} label={i.status} /></MenuItem>)
                                        }
                                        <MenuItem value="add-new">Add New Customer</MenuItem>
                                    </Select>
                                    <FormHelperText>{response?.error?.data?.errors?.customer_id}</FormHelperText>
                                </FormControl>

                            </Stack>
                        }

                        {!showCustomerOption &&
                            <>
                                <TextField
                                    disabled={disableFields}
                                    fullWidth
                                    label="Amount Required"
                                    name="amount"
                                    defaultValue={data?.amount || ""}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={response?.error?.data?.errors?.amount}
                                    onBlur={handleChange} />
                                <TextField
                                    disabled={disableFields}
                                    fullWidth
                                    multiline
                                    defaultValue={data?.purpose || ""}
                                    rows={2}
                                    label="purpose"
                                    name="purpose"
                                    inputlabelprops={{ shrink: true }}
                                    helperText={response?.error?.data?.errors?.purpose}
                                    onBlur={handleChange} />
                            </>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions sx={{p:3, pt:0}}>

                    <Stack spacing={2} direction={"row"}>
                        <Button onClick={() => dispatch(setFsModal({ component:null, state: false}))}>Cancel</Button>
                        {showInvoiceOption ?
                            <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ uploadInvoice }>Upload Invoice</Button>
                        :
                            <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Request</Button>
                        }
                    </Stack>
                </DialogActions>
            </Dialog>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    );
}
