import React from 'react'
import Typography from "@mui/material/Typography";
import {Card, CardActions, CardContent, Grid, Stack} from "@mui/material";
import {useSelector} from "react-redux";
import {ProgressBar} from "../../../Components/Common/ProgressBar";

export const AvailableLimitCard = () => {

    const auth = useSelector(store => store.auth);
    const businessCreditStats = useSelector(store => store.dashboard.businessCreditStats);

    return (
        <>
            <Card>
                <CardContent sx={{p:3}}>
                    <Grid>
                        <Typography sx={{ fontSize: '1rem', mb:2 }} variant="h3" color="success">
                            Available Credit Limit
                        </Typography>
                        <Stack direction={"row"}>
                            <Typography sx={{ fontSize: 30, mb:1, fontWeight:'bold', color:'#77aa00' }} variant="h4" color="primary" gutterBottom>
                                {businessCreditStats?.available}
                            </Typography>
                            <Typography sx={{ fontSize: 18, mb:1, pt:1.3, pl:0.5 }} variant="h5" color="text.secondary">
                                /{businessCreditStats?.total}
                            </Typography>
                        </Stack>
                    </Grid>
                    <ProgressBar />
                </CardContent>
                <CardActions sx={{py:1,px:2,fontSize:'.75rem'}}>
                    <Typography sx={{fontSize:'.8rem'}}>
                        Show Business
                    </Typography>
                </CardActions>
            </Card>
        </>
    );
}
