import * as React from 'react';
import {Card, CardContent, CardHeader, Grid, Stack} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {CardList} from "../../../Components";
import Button from "@mui/material/Button";

export default function CustomerDetailsCard() {

    const customer = useSelector((state) => state.business.customers.selected);

    return (
        <>
            { customer !== undefined &&
                <Stack spacing={2}>
                    <Card>
                        <CardHeader title={"Customer Details"} />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Customer"} secondary={customer?.company_name} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Contact Person"} secondary={customer?.cp_name} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Contact Mobile"} secondary={customer?.cp_phone} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Contact Email"} secondary={customer?.cp_email} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Contact Email"} secondary={customer?.cp_phone} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Adress"} secondary={customer?.address} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Company Contact No"} secondary={customer?.company_contact_no} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Country"} secondary={customer?.country} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Website"} secondary={customer?.company_website} />
                                        </ListItem>
                                    </CardList>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Product / Services Description"} secondary={customer?.products_desc} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Dealing Since"} secondary={customer?.dealing_since} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Payment Terms"} secondary={customer?.payment_terms} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Payment Method"} secondary={customer?.payment_method} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Turnover Amount (Last 12 Months)"} secondary={customer?.amount_previous} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Projected Turnover (for 12 Months)"} secondary={customer?.amount_projected} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Average Invoice Value"} secondary={customer?.avg_invoice_value} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"No of Invoice"} secondary={customer?.no_of_invoice} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Currency"} secondary={customer?.currency} />
                                        </ListItem>
                                    </CardList>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            }
        </>
    );
}
