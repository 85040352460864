import * as React from 'react';
import Typography from "@mui/material/Typography";
import moment from "moment/moment";

export default function AuditDetailTemplate(props) {


    return (
        <>
            {
                (()=>{
                    switch (props.type) {
                        case "assigned":
                            return <>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}>to </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"}>{ props?.data?.assigned_to?.first_name + " " + props?.data?.assigned_to?.last_name }</Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}> at </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"dark"} variant={"body1"}>{ moment(props?.data?.updated_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "created":
                            return <>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}>by </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"}>{ props?.data?.created_by?.first_name + " " + props?.data?.created_by?.last_name }</Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}> at </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"} variant={"body1"}>{ moment(props?.data?.created_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "updated":
                            return <>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}>by </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"}>{ props?.data?.updated_by?.first_name + " " + props?.data?.updated_by?.last_name }</Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}> at </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"} variant={"body1"}>{ moment(props?.data?.updated_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "verified":
                            return <>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}>by </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"}>{ props?.data?.verified_by?.first_name + " " + props?.data?.verified_by?.last_name }</Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}> at </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"} variant={"body1"}>{ moment(props?.data?.verified_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "approved":
                            return <>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}>by </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"}>{ props?.data?.approved_by?.first_name + " " + props?.data?.approved_by?.last_name }</Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }}> at </Typography>
                                <Typography sx={{ display: 'inline', fontWeight:'normal!important' }} color={"primary"} variant={"body1"}>{ moment(props?.data?.approved_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        }
                })()
            }
        </>
    );
}