import * as React from 'react';
import {Card, CardContent, CardHeader, Chip, FormControl, InputLabel, Select, Stack, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {fetchAccounts, updateAccount} from "../index";
import moment from "moment";
import MuiDatepicker from "../../Business/Components/Datepicker";
import {useCallback, useEffect, useState} from "react";
import Toastr from "../../Components/Common/Toastr";
import {currencyFormatter} from "../../../Setup/Config/currencyFormat";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {formatDateColumns} from "../../../Setup/Config/formatDateColumns";

export const AccountsTable = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const accounts = useSelector(state => state.accounts.list);
    const business = useSelector(state => state.business);
    const [data, setData] = useState({});
    const [response, setResponse] = useState(false);
    const [filter, setFilter] = useState({
        due_date:moment().format('YYYY-MM-DD'),
    });

    useEffect(() => {
        loadPayments();

    },[])

    const loadPayments = async () => {
        await fetchAccounts(dispatch, filter).then((res) => {
            console.log(res);
        });
    }

    const handleChange = (e) => {
        setFilter(filter => ({
            ...filter,
            [e.target.name] :e.target.value,
        }));
    }

    const customer_banks = () => {
        let obj = [];
        if(business.bankDetails && (business.bankDetails.list)?.length > 0){
            business.bankDetails.list.map(i => {
                obj.push({'value':i.bank?.bank_id,'label':i.bank?.bank_name});
            });
        }
        return obj;
    }

    const columns = [
        { field: 'business_name', headerName: 'Business Name', type: 'string', flex:1, minWidth:280,
            valueGetter: (params) => {
                return params.row?.business?.name;
            }
        },
        { field: 'type', headerName: 'Type', type: 'string', flex:1, minWidth:80 , editable: ['edit-account-type'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN'},
        { field: 'txn_no', headerName: 'TXN No', type: 'string', flex:1, minWidth:110, editable: ['edit-account-txn-no'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN' },
        { field: 'balance', headerName: 'Balance', type: 'number', flex:1, minWidth:100, editable: ['edit-account-balance'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN', valueFormatter: ({ value }) => currencyFormatter.format(value) },
        { field: 'cheque_deposit_date', align:'center', headerName: 'Cheque Deposit Date', type: 'date', flex:1, minWidth:180, editable: ['edit-account-deposit-date'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")
        },
        { field: 'payment_date', align:'center', headerName: 'Payment Date', type: 'date', flex:1, minWidth:100, editable: ['edit-account-payment-date'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            valueFormatter: ({ value }) =>  value && moment(value).format("DD/MM/YYYY")
        },
        { field: 'remarks', headerName: 'Remarks', type: 'string', flex:1, minWidth:200, editable: ['edit-account-remarks'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN'},
        { field: 'cheque_no', headerName: 'CHQ No', type: 'string', flex:1, minWidth:100, editable: ['edit-account-cheque-no'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN' },
        { field: 'description', headerName: 'Description', type: 'string', flex:1, minWidth:200, editable: ['edit-account-description'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN' },
        { field: 'payment_method', headerName: 'Payment Method', type: 'singleSelect', flex:1, minWidth:180, editable: ['edit-account-payment-method'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            valueOptions: ['BANK_TRANSFER','CASH','CHEQUE'],
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                    ({ value: optionValue }) => value === optionValue
                );
                return option?.label;
            },
        },
        { field: 'due_date', headerName: 'Due Date', type: 'date', flex:1, minWidth:100, editable: ['edit-account-due-date'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")
        },
        { field: 'amount', headerName: 'Amount', type: 'number', flex:1, minWidth:100, editable: ['edit-account-amount'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN' , valueFormatter: ({ value }) => currencyFormatter.format(value) },
        { field: 'cheque_bounced', headerName: 'Cheque Bounced', align:'center', type: 'singleSelect', flex:1, minWidth:160, editable: ['edit-account-cheque-bounced'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            valueOptions: [{"value":1, "label":"Yes"},{"value":0, "label":"No"}],
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                    ({ value: optionValue }) => value === optionValue
                );
                return option?.label;
            }
        },
        { field: 'bank_id', headerName: 'Bank', type: 'singleSelect', flex:1, minWidth:280,
            //editable: ['edit-account-bank'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            //valueOptions: customer_banks(),
            editable: false,
            valueGetter: (params) => {
                return params.row?.bank?.bank_name;
            }

        },
        { field: 'status', headerName:'Status', type: 'singleSelect', flex:1, minWidth:100, editable: ['edit-account-status'].some(i => auth.user.permissions.includes(i)) || auth?.user?.role_type === 'ADMIN',
            valueOptions: ['PENDING','PAID','PARTIAL','OVERDUE','CHEQUE_BOUNCED','CHEQUE_DEPOSITED','CHEQUE_ON_HOLD'],
            renderCell: (params) => {
                const status = {
                    'CHEQUE_BOUNCED':'error',
                    'CHEQUE_DEPOSITED':'primary',
                    'CHEQUE_ON_HOLD':'warning',
                    'PENDING':'warning',
                    'PAID':'success',
                    'PARTIAL':'warning',
                    'OVERDUE':'error'
                }
                return(<Chip
                    variant="contained"
                    sx={{fontWeight:'normal'}}
                    label={params.value}
                    color={status[params.value]}
                >
                </Chip>)
            },
        },
    ];

    // const processRowUpdate = async (data) => {
    //     let d = {};
    //
    //     data['cheque_deposit_date'] = data['cheque_deposit_date'] !== null ? moment(data['cheque_deposit_date']).format('YYYY-MM-DD') : null;
    //     data['due_date'] = data['due_date'] !== null ? moment(data['due_date']).format('YYYY-MM-DD') : null;
    //     data['payment_date'] = data['payment_date'] !== null ? moment(data['payment_date']).format('YYYY-MM-DD') : null;
    //     data['balance'] = data['status'] === 'PAID' ? 0 : data['balance'];
    //
    //     await updateAccount(dispatch,data).then((res)=>{
    //
    //         if(res['success']){
    //             d = res['success']?.data ;
    //         }
    //         setResponse(res);
    //     });
    //     return d
    // };

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {

            formatDateColumns(newRow, ['cheque_deposit_date', 'payment_date', 'due_date', 'created_at', 'updated_at']); // Format all date columns
            newRow['balance'] = newRow['status'] === 'PAID' ? 0 : newRow['balance'];

            const response = await updateAccount(dispatch, newRow);
            setResponse(response);
            return response['success'].data; // return the new row containing the row id
        },
        [],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.log(error)
    }, []);

    return (
        <Card>
            <CardHeader title={"All Accounts"} titleTypographyProps={{variant:'subtitle1'}} />
            <CardContent sx={{p:0}}>
                <Box sx={{ p:2, borderBottom:'1px solid #ddd'}}>
                    <Stack direction="row" spacing={2} justifyContent="left" >

                        <TextField
                            sx={{minWidth:'300px'}}
                            label="Business Name"
                            name="business_name"
                            defaultValue={filter?.business_name}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange} />

                        <TextField
                            label="Cheque No"
                            name="cheque_no"
                            defaultValue={filter?.cheque_no}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange} />

                        <Box sx={{width:'200px'}}>
                            <MuiDatepicker label={"Chq Deposit Date"} name="cheque_deposit_date" data={filter} state={setFilter} />
                        </Box>

                        <Box sx={{width:'180px'}}>
                            <MuiDatepicker label={"Payment Date"} name="payment_date" data={filter} state={setFilter} />
                        </Box>

                        <Box sx={{width:'150px'}}>
                            <MuiDatepicker label={"Due Date"} name="due_date" data={filter} state={setFilter} />
                        </Box>

                        <FormControl sx={{ minWidth:'150px' }}>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                                defaultValue={filter?.type}
                                name="status"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Status"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem key="Draft" value="DRAFT">Draft</MenuItem>
                                <MenuItem key="Pending" value="PENDING">Pending</MenuItem>
                                <MenuItem key="Partial" value="PARTIAL">Partial</MenuItem>
                                <MenuItem key="Paid" value="PAID">Paid</MenuItem>
                                <MenuItem key="Overdue" value="OVERDUE">Overdue</MenuItem>
                                <MenuItem key="Default" value="DEFAULT">Default</MenuItem>
                                <MenuItem key="Cheque Bounced" value="CHEQUE_BOUNCED">Cheque Bounced</MenuItem>
                                <MenuItem key="Cheque Deposited" value="CHEQUE_DEPOSITED">Cheque Deposited</MenuItem>
                                <MenuItem key="Cheque On Hold" value="CHEQUE_ON_HOLD">Cheque On Hold</MenuItem>
                                <MenuItem key="Deleted" value="DELETED">Deleted</MenuItem>

                            </Select>
                        </FormControl>
                        <Button onClick={() => loadPayments()} variant={"contained"}>Search</Button>
                    </Stack>
                </Box>
                {accounts !== undefined &&
                    <DataGridPro
                        editMode={"cell"}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        autoHeight
                        getRowId={(row) => row.account_id}
                        rows={accounts}
                        columns={columns}
                        sx={{
                            border:'none'
                        }}
                        hideFooterPagination
                        hideFooter
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            pinnedColumns: { right: ['amount','due_date','status'] },
                        }}
                    />
                }
            </CardContent>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </Card>
    );
}

