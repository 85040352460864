import React, { useState} from 'react'
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import {Grid} from "@mui/material";
import LoanTable from "./Table/LoanTable";

export const Loan = () => {


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <IconBreadcrumbs items={'DASHBOARD/BUSINESS_FINANCE'} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <LoanTable />
                </Grid>
            </Grid>
        </>
    );
}