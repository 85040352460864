const CONST = {

    'FETCH_ROLE_SUCCESS':'ROLES/FETCH_ROLE_SUCCESS',
    'FETCH_ROLE_FAILED':'ROLES/FETCH_ROLE_FAILED',
    'FETCH_ROLES_SUCCESS':'ROLES/FETCH_ROLES_SUCCESS',
    'FETCH_ROLES_FAILED':'ROLES/FETCH_ROLES_FAILED',
    'SET_SELECTED_ROLE':'ROLES/SET_SELECTED_ROLE',
    'ADD_ROLE_SUCCESS':'ROLES/ADD_ROLE_SUCCESS',
    'ADD_ROLE_FAILED':'ROLES/ADD_ROLE_FAILED',
    'UPDATE_ROLE_SUCCESS':'ROLES/UPDATE_ROLE_SUCCESS',
    'UPDATE_ROLE_FAILED':'ROLES/UPDATE_ROLE_FAILED',
    'DELETE_ROLE_SUCCESS':'ROLES/DELETE_ROLE_SUCCESS',
    'DELETE_ROLE_FAILED':'ROLES/DELETE_ROLE_SUCCESS',
}

export default CONST;