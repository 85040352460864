import React, { useState, useEffect, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import { fetchPermissions } from "../Redux/Action";
import Box from "@mui/material/Box";

function debounce(fn, ms) {
    let timer;
    return (...args) => {   // Use rest parameters to capture all arguments
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn(...args);  // Spread out the arguments and pass to fn
        }, ms);
    };
}

function PermissionAutocomplete(props) {
    const dispatch = useDispatch();
    const [options, setOptions] = useState([]);
    const permissions = useSelector((store)=> store.roles.selected.permissions);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchPermissions(dispatch).then(response => {
            setOptions(response['success']?.data?.data || []);
        });
    }, [dispatch]);

    const fetchDebounced = useCallback(debounce(async (value) => {
        if (value && value.length > 2) {
            setLoading(true);
            await fetchPermissions(dispatch, { keyword: value }).then(response => {
                const fetchedOptions = response['success']?.data?.data || [];
                setOptions(fetchedOptions);
                setLoading(false);
            });
        }
    }, 800), [dispatch]);

    const handleInputChange = (event, value) => {
        setInputValue(value);
        if (value.length > 2) {
            fetchDebounced(value);
        }
    };

    const handleAutocompleteChange = (event, newValue) => {
        props.setDisabled(false);
        props.setData({ ...props.data, permissions: newValue }); // Update the permissions key in the data object
    };

    return (
        <Box
            sx={{maxHeight: '400px', overflowY: 'auto', padding:'10px 0'}}
        >
            <Autocomplete
                multiple
                autoSelect={true}
                onChange={handleAutocompleteChange}
                disablePortal
                defaultValue={permissions || []}
                options={options}
                getOptionLabel={(option) => option.label}
                onInputChange={handleInputChange}
                loading={loading}
                inputValue={inputValue}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                isOptionEqualToValue={(option, value) => option.permission_id === value.permission_id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Permission"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
}

export default PermissionAutocomplete;
