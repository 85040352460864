import React from "react";
import {
    FormControl,
    Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector,} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import { setSelectedPersonalBorrowing, updatePersonalBorrowing } from "../../index";
import AttachDoc from "../../../Documents/Components/AttachDoc";
import Toastr from "../../../Components/Common/Toastr";
import MuiDatepicker from "../Datepicker";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

export const OtherBorrowingForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState(false);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(business.personalBorrowings.selected);
    }, [])

    const handleSubmit = async () => {
        setDisabled(true);
        await updatePersonalBorrowing(dispatch, data).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}))
        dispatch(setSelectedPersonalBorrowing(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Other Borrowings
                <Typography> Borrowing from Family, Friends & Others(if any)</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>
                                <TextField
                                    fullWidth
                                    label="Lender Name"
                                    name="lender_name"
                                    defaultValue={data?.lender_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.lender_name}
                                    onBlur={handleChange} />

                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-currency">Currency</InputLabel>
                                    <Select
                                        defaultValue={data?.currency || ""}
                                        name="currency"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Currency"
                                        onChange={(e) => handleChange(e)}
                                        helperText={business?.error?.currency}
                                    >
                                        <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                                        <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                                        <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                                        <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                                    </Select>
                                </FormControl>

                                <MuiDatepicker error={business?.error?.borrowing_start_date} setDisabled={setDisabled} label={"Loan Start Date"} past={true} name="borrowing_start_date" data={data} state={setData} />

                                <MuiDatepicker error={business?.error?.borrowing_end_date} setDisabled={setDisabled} label={"Loan End Date"} past={false} name="borrowing_end_date" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Credit Limit Amount"
                                    name="limit"
                                    defaultValue={data?.limit}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.limit}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Outstanding Amount"
                                    name="outstanding"
                                    defaultValue={data?.outstanding}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.outstanding}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Overdue Amount (if any)"
                                    name="overdue"
                                    defaultValue={data?.overdue}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.overdue}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Monthly Installment Amount (EMI)"
                                    name="emi"
                                    defaultValue={data?.emi}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.emi}
                                    onBlur={handleChange} />

                                <AttachDoc setResponse={setResponse} updateFunction={updatePersonalBorrowing} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Loan Agreement"} entity_name={"personalBorrowings"} entity_id={data?.id} name="agreement_copy_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    name="remarks"
                                    multiline
                                    rows={3}
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />
                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

