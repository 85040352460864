import React, {useEffect, useState} from 'react'
import {Container, FormControlLabel, FormGroup, Grid, Paper, Stack, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { confirmInvoice } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";
export const ConfirmInvoiceModal = (props) => {

    const dispatch = useDispatch();
    const invoice = useSelector((store) => store.invoices.selected);
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        let data = {
            'remarks':reason,
            'invoice_id':invoice.invoice_id
        }
        setDisabled(true);
        await confirmInvoice(dispatch, data).then((res) => {
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setFsModal({component:null, state:false}));
            }
        });
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value === '');
    }

    return(
        <>
            <DialogTitle>Confirm Invoice</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to Confirm this invoice?
                </DialogContentText>
                <TextField
                    placeholder={"Message from Customer"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Confirm Invoice</Button>
            </DialogActions>
        </>
    )
}
