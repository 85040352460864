import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {CustomerSupplierForm} from "../Forms/CustomerSupplierForm";
import {Chip, Grid, Stack, useMediaQuery} from "@mui/material";
import {
    addCustomerSupplier,
    deleteCustomerSupplier,
    clearErrors,
    proceed,
    setSelectedCustomerSupplier,
    CustomerSupplierDeleteModal
} from "../../index";
import {useState} from "react";
import moment from "moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function CustomerSupplierStep() {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.customersSuppliers).length > 0 ? business.customersSuppliers.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [formTitle, setFormTitle] = useState();
    const [subTitle, setSubTitle] = useState();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        setFormTitle(e.target.dataset.form_title);
        setSubTitle(e.target.dataset.sub_title);

        if(e.target.dataset.action === 'AddItem'){

            await addCustomerSupplier(dispatch, { business_id: business.selected.business_id, is_customer:parseInt(e.target.dataset.is_customer)});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCustomerSupplier(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<CustomerSupplierForm formName={formTitle} formSubTitle={subTitle} />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<CustomerSupplierDeleteModal />, state:true}));
        dispatch(setSelectedCustomerSupplier(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Name', type: 'string', flex:1, minWidth:350},
            { field: 'is_customer', headerName:'Type', type: 'string', flex:1, minWidth:110,
                renderCell: (params) => {
                    const type = {
                        0:'warning',
                        1:'info',
                    }
                    const val = {
                        0:'Supplier',
                        1:'Customer',
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={val[params.value]}
                        color={type[params.value]}
                    >
                    </Chip>)
                },
            },
            { field: 'dealing_since', headerName:'Dealing Since', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', flex:1, minWidth:120},
            { field: 'currency', headerName:'Currency', type: 'string', flex:1, minWidth:120},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth:100,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        data-form_title={params.row.is_customer ? "Customers" : "Suppliers"}
                        data-sub_title={"Provide the details of your top " + (params.row.is_customer ? "customers" : "suppliers")}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        data-form_title={params.row.is_customer ? "Customers" : "Suppliers"}
                        data-sub_title={"Provide the details of your top " + (params.row.is_customer ? "customers" : "suppliers")}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}  >
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.customer_id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={objects || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" data-id={"draft"} data-form_title={"Customers"} data-sub_title={"Provide the details of your top customers"} data-is_customer={1} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Customer</Button>
                    <Button variant="outlined" data-id={"draft"} data-form_title={"Suppliers"} data-sub_title={"Provide the details of your top suppliers"} data-is_customer={0} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Supplier</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}