import React, {useEffect} from 'react'
import {Container, Grid, Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import ActionButtons from "../../Customers/Components/ActionButtons";
import {CustomerPropertiesPanel} from "../../Customers";
import CustomerConfigPanel from "../../Customers/Components/Panels/CustomerConfigPanel";
import {ServiceInvoiceForm} from "../../Invoices/Components/Forms/ServiceInvoiceForm";
import {addInvoice} from "../../Invoices";
import { ServiceRequestDetailsPanel } from "./Panels/ServiceRequestDetailsPanel";

export const ServiceInvoiceUpload = () => {

    const dispatch = useDispatch();
    const invoices = useSelector((store) => store.invoices.list);
    const business = useSelector((store) => store.business);
    const customers = useSelector((store) => store.business?.customers);

    useEffect(()=>{
        const draftObject = Object.keys(invoices)?.length > 0 ? invoices.filter(i => i.is_draft === 1 && i.customer_id === customers?.selected?.customer_id) : [];
        if(draftObject.length === 0) {
            addDraftInvoice();
        }
    },[]);

    const addDraftInvoice = async () => {
        let data = {
            'business_id': business?.selected?.business_id,
            'customer_id': customers?.selected?.customer_id,
        }
        await addInvoice(dispatch, data)
    }

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Grid container spacing={2} elevated={3}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <IconBreadcrumbs items={'DASHBOARD/SERVICES'} />
                            <ActionButtons />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ServiceRequestDetailsPanel />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ServiceInvoiceForm />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CustomerPropertiesPanel />
                        <CustomerConfigPanel />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
