import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";



/****************************************
 SET SELECTED PERSONAL BORROWINGS
 ****************************************/
export const setSelectedPersonalBorrowing = (payload) => ({
    type: CONST.SET_SELECTED_PERSONAL_BORROWING,
    payload: payload
})
/****************************************
 FETCH PERSONAL BORROWINGS
 ****************************************/
export const fetchPersonalBorrowingsSuccess = (payload) => ({
    type: CONST.FETCH_PERSONAL_BORROWINGS_SUCCESS,
    payload: payload
})

export const fetchPersonalBorrowingsFailed = (payload) => ({
    type: CONST.FETCH_PERSONAL_BORROWINGS_FAILED,
    payload: payload
})

export const fetchPersonalBorrowings = async (dispatch, id) => {

    await axiosPrivate.get('business/'+ id + '/personalBorrowing').then((response) => {
        dispatch(fetchPersonalBorrowingsSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchPersonalBorrowingsFailed(error?.response));
    });
}
/****************************************
 ADD Business PERSONAL BORROWINGS
 ****************************************/
export const addPersonalBorrowingSuccess = (payload) => ({
    type: CONST.ADD_PERSONAL_BORROWING_SUCCESS,
    payload: payload
})

export const addPersonalBorrowingFailed = (payload) => ({
    type: CONST.ADD_PERSONAL_BORROWING_FAILED,
    payload: payload
})

export const addPersonalBorrowing = async (dispatch, data) => {

    await axiosPrivate.post('personalBorrowing', data).then((response) => {
        dispatch(addPersonalBorrowingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addPersonalBorrowingFailed(error?.response));
    });
}
/****************************************
 Update Business PERSONAL BORROWINGS
 ****************************************/
export const updatePersonalBorrowingSuccess = (payload) => ({
    type: CONST.UPDATE_PERSONAL_BORROWING_SUCCESS,
    payload: payload
})

export const updatePersonalBorrowingFailed = (payload) => ({
    type: CONST.UPDATE_PERSONAL_BORROWING_FAILED,
    payload: payload
})

export const updatePersonalBorrowing = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('personalBorrowing/' + data['id'], data).then((response) => {
        res['success'] = response;
        dispatch(updatePersonalBorrowingSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updatePersonalBorrowingFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business PERSONAL BORROWINGS
 ****************************************/
export const deletePersonalBorrowingSuccess = (payload) => ({
    type: CONST.DELETE_PERSONAL_BORROWING_SUCCESS,
    payload: payload
})

export const deletePersonalBorrowingFailed = (payload) => ({
    type: CONST.DELETE_PERSONAL_BORROWING_FAILED,
    payload: payload
})

export const deletePersonalBorrowing = async (dispatch, id) => {

    await axiosPrivate.delete('personalBorrowing/' + id).then((response) => {
        dispatch(deletePersonalBorrowingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deletePersonalBorrowingFailed(error?.response));
    });
}