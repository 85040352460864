import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED BANK DETAILS
 ****************************************/
export const setSelectedBankDetail = (payload) => ({
    type: CONST.SET_SELECTED_BANK_DETAIL,
    payload: payload
})
/****************************************
 FETCH BANK_DETAILS
 ****************************************/
export const fetchBankDetailsSuccess = (payload) => ({
    type: CONST.FETCH_BANK_DETAILS_SUCCESS,
    payload: payload
})

export const fetchBankDetailsFailed = (payload) => ({
    type: CONST.FETCH_BANK_DETAILS_FAILED,
    payload: payload
})

export const fetchBankDetails = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('business/'+ id + '/bankDetail').then((response) => {
        res['success'] = response;
        dispatch(fetchBankDetailsSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchBankDetailsFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD Business BANK DETAILS
 ****************************************/
export const addBankDetailSuccess = (payload) => ({
    type: CONST.ADD_BANK_DETAIL_SUCCESS,
    payload: payload
})

export const addBankDetailFailed = (payload) => ({
    type: CONST.ADD_BANK_DETAIL_FAILED,
    payload: payload
})

export const addBankDetail = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('bankDetail', data).then((response) => {
        res['success'] = response;
        dispatch(addBankDetailSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addBankDetailFailed(error?.response));
    });
    return res;
}


/****************************************
 Update Business BANK DETAILS
 ****************************************/
export const updateBankDetailSuccess = (payload) => ({
    type: CONST.UPDATE_BANK_DETAIL_SUCCESS,
    payload: payload
})

export const updateBankDetailFailed = (payload) => ({
    type: CONST.UPDATE_BANK_DETAIL_FAILED,
    payload: payload
})

export const updateBankDetail = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('bankDetail/' + data['bank_detail_id'], data).then((response) => {
        dispatch(updateBankDetailSuccess(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateBankDetailFailed(error?.response));
    });
    return res;
}

/****************************************
 DELETE Business BANK DETAILS
 ****************************************/
export const deleteBankDetailSuccess = (payload) => ({
    type: CONST.DELETE_BANK_DETAIL_SUCCESS,
    payload: payload
})

export const deleteBankDetailFailed = (payload) => ({
    type: CONST.DELETE_BANK_DETAIL_FAILED,
    payload: payload
})

export const deleteBankDetail = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.delete('bankDetail/' + id).then((response) => {
        res['success'] = response;
        dispatch(deleteBankDetailSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteBankDetailFailed(error?.response));
    });
    return res;
}
