import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED CREDIT CARD
 ****************************************/
export const setSelectedCreditCard = (payload) => ({
    type: CONST.SET_SELECTED_CREDIT_CARD,
    payload: payload
})
/****************************************
 FETCH CREDIT_CARD
 ****************************************/
export const fetchCreditCardsSuccess = (payload) => ({
    type: CONST.FETCH_CREDIT_CARDS_SUCCESS,
    payload: payload
})

export const fetchCreditCardsFailed = (payload) => ({
    type: CONST.FETCH_CREDIT_CARDS_FAILED,
    payload: payload
})

export const fetchCreditCards = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('business/'+ id + '/creditCard').then((response) => {
        res['success'] = response;
        dispatch(fetchCreditCardsSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchCreditCardsFailed(error?.response));
    });
    return res;
}
/****************************************
 ADD Business CREDIT CARD
 ****************************************/
export const addCreditCardSuccess = (payload) => ({
    type: CONST.ADD_CREDIT_CARD_SUCCESS,
    payload: payload
})

export const addCreditCardFailed = (payload) => ({
    type: CONST.ADD_CREDIT_CARD_FAILED,
    payload: payload
})

export const addCreditCard = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('creditCard', data).then((response) => {
        res['success'] = response;
        dispatch(addCreditCardSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addCreditCardFailed(error?.response));
    });
    return res;
}
/****************************************
 Update Business CREDIT CARD
 ****************************************/
export const updateCreditCardSuccess = (payload) => ({
    type: CONST.UPDATE_CREDIT_CARD_SUCCESS,
    payload: payload
})

export const updateCreditCardFailed = (payload) => ({
    type: CONST.UPDATE_CREDIT_CARD_FAILED,
    payload: payload
})

export const updateCreditCard = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('creditCard/' + data['card_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateCreditCardSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateCreditCardFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business CREDIT CARD
 ****************************************/
export const deleteCreditCardSuccess = (payload) => ({
    type: CONST.DELETE_CREDIT_CARD_SUCCESS,
    payload: payload
})

export const deleteCreditCardFailed = (payload) => ({
    type: CONST.DELETE_CREDIT_CARD_FAILED,
    payload: payload
})

export const deleteCreditCard = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.delete('creditCard/' + id).then((response) => {
        res['success'] = response;
        dispatch(deleteCreditCardSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteCreditCardFailed(error?.response));
    });
    return res;
}
