import appReducer from "./AppReducer";
import storage from 'redux-persist/lib/storage'
const rootReducer = (state,action) => {

    if (action.type === 'APP/LOGOUT_SUCCESS') {
        storage.removeItem('persist:root')
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
};

export default rootReducer;