import React, { useState } from 'react'
import {Container, Fab, Paper} from "@mui/material";
import DataTable from './Table';
import AddIcon from "@mui/icons-material/Add";
import RightDrawer from "../../Components/Drawers/RightDrawer";
import Modal from "../../Components/Common/Modal";
import Typography from "@mui/material/Typography";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import { useDispatch } from "react-redux";
import { AddItem, EditItem, DeleteItem } from './Forms';
import { openRightDrawer, openCommonModal } from "../../../Setup/Redux/ConfigAction";
import { fetchPermission, setSelectedPermission } from "../Redux/Action";

export const Permissions = () => {

    const dispatch = useDispatch();
    const [formName, setFormName] = useState(null);

    const addItem = () => {
        dispatch(openRightDrawer(true));
        setFormName('AddItem');
    };

    const editItem = React.useCallback(
        (id) => () => {
            dispatch(openRightDrawer(true));
            fetchPermission(dispatch, id);
            setFormName('EditItem');
        },
        [],
    );

    const deleteItem = React.useCallback(

        (id) => () => {

            dispatch(openCommonModal(true));
            setFormName('DeleteItem');
            dispatch(setSelectedPermission(id));
        },
        [],
    );

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
            <Paper sx={{ p:2, mb:2}} variant="outlined">
                <IconBreadcrumbs items={'DASHBOARD/PERMISSIONS'} />
            </Paper>

            <Paper sx={{ display:'flex', flexDirection:'column', flex:1}}>

                <Typography
                    sx={{ flex: '1', p:2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Permissions
                </Typography>

                <DataTable editItem={editItem} deleteItem={deleteItem} />

                <Fab onClick={addItem} size="big" color="error" aria-label="add" sx={{ position: 'absolute', bottom: 50, right:50 }}>
                    <AddIcon />
                </Fab>
                {
                    (()=>{
                        switch (formName) {
                            case 'AddItem':
                                return <RightDrawer component={<AddItem />} />
                            case 'EditItem':
                                return <RightDrawer component={<EditItem />} />
                            case 'DeleteItem':
                                return <Modal component={<DeleteItem />} />
                            default:
                                return false
                        }
                    })()
                }
            </Paper>
            </Container>
        </>
    );
}
