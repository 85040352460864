import React, {useEffect, useState} from 'react'
import {Container, Grid, Stack, } from "@mui/material";
import {useDispatch} from "react-redux";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import {
    ActionButtons,
    InvoiceTable,
    fetchInvoices,
    fetchInvoice,
} from "../index";
import InvoiceDetailsCard from "./Cards/InvoiceDetailsCard";
import {InvoicePropertiesPanel} from "./Panels/InvoicePropertiesPanel";
import {fetchBusiness, fetchCustomer} from "../../Business";
import {useParams} from "react-router-dom";
import DocumentsList from "../../Documents/Components/DocumentsList";

export const Invoices = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        await fetchInvoices(dispatch).then((res) => {
            //console.log(res);
        });
    }

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <IconBreadcrumbs items={'DASHBOARD/INVOICES'} />
                    </Grid>
                    <Grid item xs={12}>
                        <InvoiceTable />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export const InvoiceView = () => {

    const { invoice_id } = useParams();
    const dispatch = useDispatch();
    const [response, setResponse] = useState();

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async() => {
        await fetchInvoice(dispatch, invoice_id).then(async (res) => {

            setResponse(res);
            if(res['success'] && res?.success?.data?.business_id !== undefined){
                await fetchBusiness(dispatch, res?.success?.data?.business_id);
                await fetchCustomer(dispatch, res?.success?.data?.customer_id);
            }
        });
    }

    return (
        <>
            {(response !== undefined && response.hasOwnProperty('success')) &&
                <Container maxWidth={'xl'} sx={{p:0}}>
                    {(response && response?.success !== undefined) &&
                        <Grid container spacing={2} elevated={3}>
                            <Grid item xs={12}>
                                <Stack justifyContent={'space-between'} direction={'row'}>
                                    <IconBreadcrumbs items={'DASHBOARD/INVOICES'} />
                                    <ActionButtons />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <InvoiceDetailsCard />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InvoicePropertiesPanel />
                                <DocumentsList entityId={invoice_id} tab={"invoices"} />
                            </Grid>
                        </Grid>
                    }
                </Container>
            }
        </>
    );
}

