import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


//setSelectedUser
export const resetUsers = (payload) => ({
    type: CONST.RESET_ALL,
    payload:null
})
export const setSelectedUser = (payload) => ({
    type: CONST.SET_SELECTED_USER_SUCCESS,
    payload: payload
})

/****************************************
 FETCH USER
 ****************************************/
export const fetchUserSuccess = (payload) => ({
    type: CONST.FETCH_USER_SUCCESS,
    payload: payload
})

export const fetchUserFailed = (payload) => ({
    type: CONST.FETCH_USER_FAILED,
    payload: payload
})

export const fetchUser = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.get('user/' + id).then((response) => {
        res['success'] = response;
        dispatch(fetchUserSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchUserFailed(error?.response));
    });
    return res;
}

/****************************************
 FETCH USERS
 ****************************************/
export const fetchUsersSuccess = (payload) => ({
    type: CONST.FETCH_USERS_SUCCESS,
    payload: payload
})

export const fetchUsersFailed = (payload) => ({
    type: CONST.FETCH_USERS_FAILED,
    payload: payload
})

export const fetchUsers = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.get('user', {params:data}).then((response) => {
        res['success'] = response;
        dispatch(fetchUsersSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchUsersFailed(error?.response));
    });
    return res;
}


/****************************************
 REGISTER USER
 ****************************************/
export const doRegisterSuccess = (payload) => ({
    type: CONST.REGISTER_USER_SUCCESS,
    payload: payload
})

export const doRegisterFailed = (payload) => ({
    type: CONST.REGISTER_USER_FAILED,
    payload: payload
})

export const doRegister = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('register', data).then((response) => {
        res['success'] = response;
        dispatch(doRegisterSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(doRegisterFailed(error?.response));
    });
    return res;
}

/****************************************
 CAPTURE LEAD
 ****************************************/
export const captureLeadSuccess = (payload) => ({
    type: CONST.CAPTURE_LEAD_SUCCESS,
    payload: payload
})

export const captureLeadFailed = (payload) => ({
    type: CONST.CAPTURE_LEAD_FAILED,
    payload: payload
})

export const captureLead = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('lead', data).then((response) => {
        res['success'] = response;
        dispatch(captureLeadSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(captureLeadFailed(error?.response));
    });
    return res;
}



/****************************************
 SEND VERIFICATION EMAIL
 ****************************************/
export const sendVerificationEmailSuccess = (payload) => ({
    type: CONST.SEND_VERIFICATION_EMAIL_SUCCESS,
    payload: payload
})

export const sendVerificationEmailFailed = (payload) => ({
    type: CONST.SEND_VERIFICATION_EMAIL_FAILED,
    payload: payload
})

export const sendVerificationEmail = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('send-verification-email', data).then((response) => {
        res['success'] = response;
        dispatch(sendVerificationEmailSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(sendVerificationEmailFailed(error?.response));
    });
    return res;
}

/****************************************
 VERIFY USER
 ****************************************/
export const doVerifySuccess = (payload) => ({
    type: CONST.VERIFY_EMAIL_SUCCESS,
    payload: payload
})

export const doVerifyFailed = (payload) => ({
    type: CONST.VERIFY_USER_FAILED,
    payload: payload
})

export const doVerify = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('verify', data).then((response) => {
        res['success'] = response;
        dispatch(doVerifySuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(doVerifyFailed(error?.response));
    });
    return res;
}


/****************************************
 FORGOT PASSWORD
 ****************************************/
export const doForgotPasswordSuccess = (payload) => ({
    type: CONST.FORGOT_PASSWORD_SUCCESS,
    payload,
})

export const doForgotPasswordFailed = (payload) => ({
    type: CONST.FORGOT_PASSWORD_FAILED,
    payload,
})

export const doForgotPassword = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.post('forgot-password', data).then((response) => {
        res['success'] = response;
        dispatch(doForgotPasswordSuccess(response))
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(doForgotPasswordFailed(error?.response));
    });
    return res;
}



/****************************************
 RESET PASSWORD
 ****************************************/
export const doResetPasswordSuccess = (payload) => ({
    type: CONST.RESET_PASSWORD_SUCCESS,
    payload: payload
})

export const doResetPasswordFailed = (payload) => ({
    type: CONST.RESET_PASSWORD_FAILED,
    payload: payload
})

export const doResetPassword = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('reset-password', data).then((response) => {
        res['success'] = response;
        dispatch(doResetPasswordSuccess(response))
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(doResetPasswordFailed(error?.response));
    });
    return res;
}

/****************************************
 ADD USER
 ****************************************/
export const addUserSuccess = (payload) => ({
    type: CONST.ADD_USER_SUCCESS,
    payload: payload
})

export const addUserFailed = (payload) => ({
    type: CONST.ADD_USER_FAILED,
    payload: payload
})

export const addUser = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('user', data).then((response) => {
        res['success'] = response;
        dispatch(addUserSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addUserFailed(error?.response));
    });
    return res;
}
/****************************************
 UPDATE USER
 ****************************************/
export const updateUserSuccess = (payload) => ({
    type: CONST.UPDATE_USER_SUCCESS,
    payload: payload
})

export const updateUserFailed = (payload) => ({
    type: CONST.UPDATE_USER_FAILED,
    payload: payload
})

export const updateUser = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('user/' + data['user_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateUserSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(updateUserFailed(error?.response));
    });
    return res;
}


/****************************************
 DELETE USER
 ****************************************/
export const deleteUserSuccess = (payload) => ({
    type: CONST.DELETE_USER_SUCCESS,
    payload: payload
})

export const deleteUserFailed = (payload) => ({
    type: CONST.DELETE_USER_FAILED,
    payload: payload
})

export const deleteUser = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.delete('user/' + data['user_id']).then((response) => {
        res['success'] = response;
        dispatch(deleteUserSuccess(data['user_id']));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(deleteUserFailed(error?.response));
    });
    return res;
}

/****************************************
 Change Password
 ****************************************/
export const changePasswordSuccess = (payload) => ({
    type: CONST.CHANGE_PASSWORD_SUCCESS,
    payload: payload
})

export const changePasswordFailed = (payload) => ({
    type: CONST.CHANGE_PASSWORD_FAILED,
    payload: payload
})

export const changePassword = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('change-password/' + data['user_id'], data).then((response) => {
        res['success'] = response;
        dispatch(changePasswordSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(changePasswordFailed(error?.response));
    });
    return res;
}