import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl, FormControlLabel, FormLabel,
    Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openCommonModal, openRightDrawer} from "../../../Setup/Redux/ConfigAction";
import {addRole, updateRole, deleteRole} from "../Redux/Action";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomAlert from "../../Components/Common/CustomAlert";
import PermissionAutocomplete from "../../Permissions/Components/AutoComplete";

export const AddItem = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.roles);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async() => {
        setDisabled(true);
        await addRole(dispatch, data).then((res)=>{
            console.log("Added");
        })
    };

    return(
        <Card sx={{ height: '100%', borderRadius:0, position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={ () => dispatch(openRightDrawer(false)) }>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Add Role</Typography>
                }
            />
            <CardContent sx={{ flex:1, overflow:'auto' }}>
                <TextField
                    fullWidth
                    label="Role Name"
                    name="role_name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.role_name}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Role Level"
                    name="role_level"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.role_level}
                    onChange={(e) => handleChange(e)}
                />

                <PermissionAutocomplete setDisabled={setDisabled} data={data} setData={setData} />

                <FormControl>
                    <FormLabel id="role_status_label">Status</FormLabel>
                    <RadioGroup
                        row
                        value={data['is_active']}
                        onChange={(e) => handleChange(e)}
                        aria-labelledby="role_status_label"
                        name="is_active"
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Active" />
                        <FormControlLabel value="0" control={<Radio />} label="Suspend" />
                    </RadioGroup>
                </FormControl>
            </CardContent>
            <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                <Button disabled={disabled} variant={'contained'} color={'primary'} onClick={ handleSubmit }>Add Role</Button>
                <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(openRightDrawer(false))}>Close</Button>
            </CardActions>
        </Card>
    )
}

export const EditItem = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.roles);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({})
    const [msg, setMsg] = useState({});
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData(items.selected);
    },[items.selected])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updateRole(dispatch, data).then((res)=>{
            setResponse(res);
        })
    };

    return(
        <>
            { data !== undefined && Object.keys(data).length > 0 ?
                <Card sx={{ height: '100%', position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
                    <CardHeader
                        action={
                            <IconButton aria-label="share" onClick={()=> dispatch(openRightDrawer(false))}>
                                <CloseIcon />
                            </IconButton>
                        }
                        avatar={
                            <Typography>Edit Role</Typography>
                        }
                    />
                    <CardContent sx={{ flex:1 }}>
                        <CustomAlert msg={msg} />
                        <TextField
                            fullWidth
                            label="Role Name"
                            name="role_name"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            value={ data['role_name'] }
                            sx={{mb:2}}
                            helperText={items.error?.role_name}
                        />
                        <TextField
                            fullWidth
                            label="Role Level"
                            name="role_level"
                            onChange={(e) => handleChange(e)}
                            value={ data['role_level'] }
                            variant="outlined"
                            size="large"
                            sx={{mb:3}}
                            helperText={items.error?.role_level}
                        />

                        <PermissionAutocomplete setDisabled={setDisabled} data={data} setData={setData} />

                        <FormControl sx={{mt:2}}>
                            <FormLabel id="role_status_label">Status</FormLabel>
                            <RadioGroup
                                row
                                value={data['is_active']}
                                onChange={(e) => handleChange(e)}
                                aria-labelledby="role_status_label"
                                name="is_active"
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Active" />
                                <FormControlLabel value="0" control={<Radio />} label="Suspend" />
                            </RadioGroup>
                        </FormControl>

                    </CardContent>
                    <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                        <Button disabled={disabled} color="primary" variant="contained" onClick={ handleSubmit }>Save</Button>
                        <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(openRightDrawer(false))}>Close</Button>
                    </CardActions>
                </Card>
                : '' }
        </>
    )
}

export const DeleteItem = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.roles);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        await deleteRole(dispatch, {role_id : items.selected.role_id || ''});
        dispatch(openCommonModal(false));
    };

    return(
        <>
            <DialogTitle>Delete Role</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to remove this role?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(openCommonModal(false))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Delete Role</Button>
            </DialogActions>
        </>
    )
}