import React from 'react'
import {Outlet} from "react-router-dom";
import {theme} from "../Setup/Theme/theme";
import {ThemeProvider} from "@mui/material/styles";

export function PublicRoutes() {
  return (
      <ThemeProvider theme={ theme }>
          <Outlet />
      </ThemeProvider>
  )
}
