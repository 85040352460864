import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchLoan} from "../Redux/LoanAction";
import {fetchBankDetails, fetchOwnership} from "../../Business";
import {fetchConfig} from "../../Business/Redux/ConfigAction";
import {Container, Grid, Stack} from "@mui/material";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import ActionButtons from "./ActionButtons";
import {LoanScheduleTable} from "./Table/LoanScheduleTable";
import {LoanDetailsPanel} from "./Panel/LoanDetailsPanel";
import LoanConfigPanel from "./Panel/LoanConfigPanel";
import {useEffect, useState} from "react";
import {ServiceChargeTable} from "../../Services";
import {fetchService} from "../../Services";
import LoanDocumentPanel from "./Panel/LoanDocumentPanel";
import Toastr from "../../Components/Common/Toastr";

export const LoanView = () => {

    const dispatch = useDispatch();
    const [dataReady, setDataReady] = useState(false);
    const auth = useSelector((store) => store.auth);
    const { loan_id } = useParams();
    const [response, setResponse] = useState(false);

    useEffect( () => {
        fetch();
    },[]);

    const fetch = async () => {
        let response = '';
        await fetchLoan(dispatch, loan_id).then(async (res) => {
            response = res['success'];
            setDataReady(res['success'].status === 200);
        });

        await fetchOwnership(dispatch, response.data.business_id);
        await fetchBankDetails(dispatch, response.data.business_id);
        await fetchConfig(dispatch, response.data.business_id);
        await fetchService(dispatch, response.data.service_id);
    }

    return (
        <>
            {dataReady &&
                <Container maxWidth={'xl'} sx={{p:0}}>
                    <Grid container justifyContent={"center"} spacing={2} >
                        <Grid item xs={12}>
                            <Stack justifyContent={'space-between'} direction={'row'}>
                                <IconBreadcrumbs items={'DASHBOARD/BUSINESS_FINANCE'} />
                                <ActionButtons />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Stack spacing={2} direction={"column"}>
                                <LoanScheduleTable loan_id={loan_id} />
                                { auth.user.role_type !== 'CLIENT' && <ServiceChargeTable loan_id={loan_id} /> }
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Stack spacing={2} direction={"column"}>
                                <LoanDocumentPanel loan_id={loan_id} setResponse={setResponse} />
                                <LoanDetailsPanel loan_id={loan_id} />
                                <LoanConfigPanel loan_id={loan_id} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            }
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    );
}