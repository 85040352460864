import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {VatReturnForm} from "../Forms/VatReturnForm";
import {Grid, Stack, useMediaQuery} from "@mui/material";
import { addVatReturn, deleteVatReturn, clearErrors, proceed, setSelectedVatReturn,VatReturnDeleteModal} from "../../index";
import moment from "moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {currencyFormatter} from "../../../../Setup/Config";

export default function VatReturnStep() {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.vatReturns).length > 0 ? business.vatReturns.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addVatReturn(dispatch, {business_id:business.selected.business_id});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedVatReturn(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<VatReturnForm />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<VatReturnDeleteModal />, state:true}));
        dispatch(setSelectedVatReturn(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'period_from', headerName:'Period From', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'period_to', headerName:'Period To', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'turnover_amount', headerName:'Sales Amt', type: 'number', flex:1, minWidth:110, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'expenses_amount', headerName:'Expenses Amt', type: 'number', flex:1, minWidth:140, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'vat_payable', headerName:'Payable Amt', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'payment_date', headerName:'Payment Date', type: 'date', flex:1, minWidth: 130, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            {
                flex:1,
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                minWidth:120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={objects || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] },
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add VAT Return</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}