const CONST = {

    'FETCH_SETTING_SUCCESS':'SETTING/FETCH_SETTING_SUCCESS',
    'FETCH_SETTING_FAILED':'SETTING/FETCH_SETTING_FAILED',
    'FETCH_SETTINGS_SUCCESS':'SETTING/FETCH_SETTINGS_SUCCESS',
    'FETCH_SETTINGS_FAILED':'SETTING/FETCH_SETTINGS_FAILED',
    'SET_SELECTED_SETTING':'SETTING/SET_SELECTED_SETTING',
    'ADD_SETTING_SUCCESS':'SETTING/ADD_SETTING_SUCCESS',
    'ADD_SETTING_FAILED':'SETTING/ADD_SETTING_FAILED',
    'UPDATE_SETTING_SUCCESS':'SETTING/UPDATE_SETTING_SUCCESS',
    'UPDATE_SETTING_FAILED':'SETTING/UPDATE_SETTING_FAILED',
    'DELETE_SETTING_SUCCESS':'SETTING/DELETE_SETTING_SUCCESS',
    'DELETE_SETTING_FAILED':'SETTING/DELETE_SETTING_SUCCESS',
}

export default CONST;