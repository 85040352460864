import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';

function handleClick(event) {
    //event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function IconBreadcrumbs(props) {

    const items = props.items.split('/')
    const crumbs = {
        'DASHBOARD':{
            'label':'Dashboard',
            'link':'/dashboard',
        },
        'BUSINESS':{
            'label':'Business',
            'link':'/business',
        },
        'INVOICE_DISCOUNTING':{
            'label':'Invoice Discounting',
            'link':'/invoice-discounting',
        },
        'INVOICES':{
            'label':'Invoices',
            'link':'/invoices',
        },
        'CUSTOMERS':{
            'label':'Customers',
            'link':'/customers',
        },
        'BUSINESS_FINANCE':{
            'label':'Business Finance',
            'link':'/business-finance',
        },
        'REPAYMENTS':{
            'label':'Repayments',
            'link':'loans',
        },
        'PAYMENTS':{
            'label':'Payments',
            'link':'payments',
        },
        'USERS':{
            'label':'Users',
            'link':'/users',
        },
        'ROLES':{
            'label':'Roles',
            'link':'/roles',
        },
        'PERMISSIONS':{
            'label':'Permissions',
            'link':'/permissions',
        },
        'SETTINGS':{
            'label':'Settings',
            'link':'/settings',
        },
    }
    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    to="/dashboard"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {crumbs[items[0]].label}
                </Link>
                {crumbs[items[1]] !== undefined &&
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {crumbs[items[1]].label}
                </Typography>
                }
                {crumbs[items[2]] !== undefined &&
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {crumbs[items[2]].label}
                    </Typography>
                }
            </Breadcrumbs>
        </div>
    );
}
