import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function MuiDatepicker(props) {
    const handleChange = (newValue) => {
        if (typeof props.setDisabled === 'function') {
            props.setDisabled(false);
        }
        props.state({
            ...props.data,
            [props.name] : newValue?.format(props.format || "YYYY-MM-DD"),
        })
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                views={props.views || ['year', 'month', 'day']}
                label={props.label}
                inputFormat={props.format || "DD/MM/YYYY"}
                value={props.data !== undefined && props.data[props.name] !== undefined ? props.data[props.name] : null }
                onChange={handleChange}
                disabled={props?.disabled}
                disableFuture={ props?.past !== undefined && props?.past }
                disablePast={ props?.past !== undefined ? !props?.past : false }
                renderInput={(params) => <TextField {...params} helperText={props?.error} />}

            />
        </LocalizationProvider>
    );
}