import React from "react";
import {
    Grid, Stack,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";
import Toastr from "../../../Components/Common/Toastr";
import Typography from "@mui/material/Typography";
import AttachDoc from "../../../Documents/Components/AttachDoc";


export const LoanDocumentForm = () => {

    const dispatch = useDispatch();
    const loan = useSelector((store) => store.loans.selected);
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(loan);
    }, [])

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Loan Documents
                <Typography>Upload Loan Documents</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        {(data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>

                                <AttachDoc multiple={true} setResponse={setResponse} allowedFileType={['pdf']} onBlur={handleChange} label={"Loan Agreement Docs"} entity_name={"loan"} entity_id={data?.loan_id} name="loan_agreement" data={data} state={setData} />

                                <AttachDoc multiple={true} setResponse={setResponse} allowedFileType={['pdf']} onBlur={handleChange} label={"Cheques"} entity_name={"loan"} entity_id={data?.loan_id} name="cheque" data={data} state={setData} />

                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

