const CONST = {
    'CLEAR_PROCEED_STATE':'BUSINESS/CLEAR_PROCEED_STATE',
    'CLEAR_ERROR_STATE':'BUSINESS/CLEAR_ERROR_STATE',
    'SET_SELECTED_BUSINESS':'BUSINESS/SET_SELECTED_BUSINESS',
    'SET_SELECTED_BUSINESS_SUCCESS':'BUSINESS/SET_SELECTED_BUSINESS_SUCCESS',
    'FETCH_BUSINESS_SUCCESS':'BUSINESS/FETCH_BUSINESS_SUCCESS',
    'FETCH_BUSINESS_FAILED':'BUSINESS/FETCH_BUSINESS_FAILED',
    'FETCH_BUSINESS_CONFIG_SUCCESS':'BUSINESS/FETCH_BUSINESS_CONFIG_SUCCESS',
    'FETCH_BUSINESS_CONFIG_FAILED':'BUSINESS/FETCH_BUSINESS_CONFIG_FAILED',
    'FETCH_BUSINESSES_SUCCESS':'BUSINESS/FETCH_BUSINESSES_SUCCESS',
    'FETCH_BUSINESSES_FAILED':'BUSINESS/FETCH_BUSINESSES_FAILED',
    'ADD_BUSINESS_SUCCESS':'BUSINESS/ADD_BUSINESS_SUCCESS',
    'ADD_BUSINESS_FAILED':'BUSINESS/ADD_BUSINESS_FAILED',
    'UPDATE_BUSINESS_SUCCESS':'BUSINESS/UPDATE_BUSINESS_SUCCESS',
    'UPDATE_BUSINESS_FAILED':'BUSINESS/UPDATE_BUSINESS_FAILED',
    'SUBMIT_BUSINESS_FOR_APPROVAL_SUCCESS':'BUSINESS/SUBMIT_BUSINESS_FOR_APPROVAL_SUCCESS',
    'SUBMIT_BUSINESS_FOR_APPROVAL_FAILED':'BUSINESS/SUBMIT_BUSINESS_FOR_APPROVAL_FAILED',
    'VERIFY_BUSINESS_SUCCESS':'BUSINESS/VERIFY_BUSINESS_SUCCESS',
    'VERIFY_BUSINESS_FAILED':'BUSINESS/VERIFY_BUSINESS_FAILED',
    'RETURN_BUSINESS_SUCCESS':'BUSINESS/RETURN_BUSINESS_SUCCESS',
    'RETURN_BUSINESS_FAILED':'BUSINESS/RETURN_BUSINESS_FAILED',
    'APPROVE_BUSINESS_SUCCESS':'BUSINESS/APPROVE_BUSINESS_SUCCESS',
    'APPROVE_BUSINESS_FAILED':'BUSINESS/APPROVE_BUSINESS_FAILED',
    'REJECT_BUSINESS_SUCCESS':'BUSINESS/REJECT_BUSINESS_SUCCESS',
    'REJECT_BUSINESS_FAILED':'BUSINESS/REJECT_BUSINESS_FAILED',
    'EXPORT_BUSINESS_SUCCESS':'BUSINESS/EXPORT_BUSINESS_SUCCESS',
    'EXPORT_BUSINESS_FAILED':'BUSINESS/EXPORT_BUSINESS_FAILED',
    'DELETE_BUSINESS_SUCCESS':'BUSINESS/DELETE_BUSINESS_SUCCESS',
    'DELETE_BUSINESS_FAILED':'BUSINESS/DELETE_BUSINESS_FAILED',

    'SET_SELECTED_OWNERSHIP':'BUSINESS/SET_SELECTED_OWNERSHIP',
    'SET_SELECTED_OWNERSHIP_SUCCESS':'BUSINESS/SET_SELECTED_OWNERSHIP_SUCCESS',
    'FETCH_OWNERSHIP_SUCCESS':'BUSINESS/FETCH_OWNERSHIP_SUCCESS',
    'FETCH_OWNERSHIP_FAILED':'BUSINESS/FETCH_OWNERSHIP_FAILED',
    'FETCH_OWNERSHIPS_SUCCESS':'BUSINESS/FETCH_OWNERSHIPS_SUCCESS',
    'FETCH_OWNERSHIPS_FAILED':'BUSINESS/FETCH_OWNERSHIPS_FAILED',
    'ADD_OWNERSHIP_SUCCESS':'BUSINESS/ADD_OWNERSHIP_SUCCESS',
    'ADD_OWNERSHIP_FAILED':'BUSINESS/ADD_OWNERSHIP_FAILED',
    'UPDATE_OWNERSHIP_SUCCESS':'BUSINESS/UPDATE_OWNERSHIP_SUCCESS',
    'UPDATE_OWNERSHIP_FAILED':'BUSINESS/UPDATE_OWNERSHIP_FAILED',
    'DELETE_OWNERSHIP_SUCCESS':'BUSINESS/DELETE_OWNERSHIP_SUCCESS',
    'DELETE_OWNERSHIP_FAILED':'BUSINESS/DELETE_OWNERSHIP_FAILED',

    'SET_SELECTED_FINANCIAL':'BUSINESS/SET_SELECTED_FINANCIAL',
    'FETCH_FINANCIAL_SUCCESS':'BUSINESS/FETCH_FINANCIAL_SUCCESS',
    'FETCH_FINANCIAL_FAILED':'BUSINESS/FETCH_FINANCIAL_FAILED',
    'FETCH_FINANCIALS_SUCCESS':'BUSINESS/FETCH_FINANCIALS_SUCCESS',
    'FETCH_FINANCIALS_FAILED':'BUSINESS/FETCH_FINANCIALS_FAILED',
    'ADD_FINANCIAL_SUCCESS':'BUSINESS/ADD_FINANCIAL_SUCCESS',
    'ADD_FINANCIAL_FAILED':'BUSINESS/ADD_FINANCIAL_FAILED',
    'UPDATE_FINANCIAL_SUCCESS':'BUSINESS/UPDATE_FINANCIAL_SUCCESS',
    'UPDATE_FINANCIAL_FAILED':'BUSINESS/UPDATE_FINANCIAL_FAILED',
    'DELETE_FINANCIAL_SUCCESS':'BUSINESS/DELETE_FINANCIAL_SUCCESS',
    'DELETE_FINANCIAL_FAILED':'BUSINESS/DELETE_FINANCIAL_FAILED',

    'SET_SELECTED_BANK_DETAIL':'BUSINESS/SET_SELECTED_BANK_DETAIL',
    'FETCH_BANK_DETAIL_SUCCESS':'BUSINESS/FETCH_BANK_DETAIL_SUCCESS',
    'FETCH_BANK_DETAIL_FAILED':'BUSINESS/FETCH_BANK_DETAIL_FAILED',
    'FETCH_BANK_DETAILS_SUCCESS':'BUSINESS/FETCH_BANK_DETAILS_SUCCESS',
    'FETCH_BANK_DETAILS_FAILED':'BUSINESS/FETCH_BANK_DETAILS_FAILED',
    'ADD_BANK_DETAIL_SUCCESS':'BUSINESS/ADD_BANK_DETAIL_SUCCESS',
    'ADD_BANK_DETAIL_FAILED':'BUSINESS/ADD_BANK_DETAIL_FAILED',
    'UPDATE_BANK_DETAIL_SUCCESS':'BUSINESS/UPDATE_BANK_DETAIL_SUCCESS',
    'UPDATE_BANK_DETAIL_FAILED':'BUSINESS/UPDATE_BANK_DETAIL_FAILED',
    'DELETE_BANK_DETAIL_SUCCESS':'BUSINESS/DELETE_BANK_DETAIL_SUCCESS',
    'DELETE_BANK_DETAIL_FAILED':'BUSINESS/DELETE_BANK_DETAIL_FAILED',

    'SET_SELECTED_BANK_BORROWING':'BUSINESS/SET_SELECTED_BANK_BORROWING',
    'FETCH_BANK_BORROWING_SUCCESS':'BUSINESS/FETCH_BANK_BORROWING_SUCCESS',
    'FETCH_BANK_BORROWING_FAILED':'BUSINESS/FETCH_BANK_BORROWING_FAILED',
    'FETCH_BANK_BORROWINGS_SUCCESS':'BUSINESS/FETCH_BANK_BORROWINGS_SUCCESS',
    'FETCH_BANK_BORROWINGS_FAILED':'BUSINESS/FETCH_BANK_BORROWINGS_FAILED',
    'ADD_BANK_BORROWING_SUCCESS':'BUSINESS/ADD_BANK_BORROWING_SUCCESS',
    'ADD_BANK_BORROWING_FAILED':'BUSINESS/ADD_BANK_BORROWING_FAILED',
    'UPDATE_BANK_BORROWING_SUCCESS':'BUSINESS/UPDATE_BANK_BORROWING_SUCCESS',
    'UPDATE_BANK_BORROWING_FAILED':'BUSINESS/UPDATE_BANK_BORROWING_FAILED',
    'DELETE_BANK_BORROWING_SUCCESS':'BUSINESS/DELETE_BANK_BORROWING_SUCCESS',
    'DELETE_BANK_BORROWING_FAILED':'BUSINESS/DELETE_BANK_BORROWING_FAILED',

    'SET_SELECTED_CREDIT_CARD':'BUSINESS/SET_SELECTED_CREDIT_CARD',
    'FETCH_CREDIT_CARD_SUCCESS':'BUSINESS/FETCH_CREDIT_CARD_SUCCESS',
    'FETCH_CREDIT_CARD_FAILED':'BUSINESS/FETCH_CREDIT_CARD_FAILED',
    'FETCH_CREDIT_CARDS_SUCCESS':'BUSINESS/FETCH_CREDIT_CARDS_SUCCESS',
    'FETCH_CREDIT_CARDS_FAILED':'BUSINESS/FETCH_CREDIT_CARDS_FAILED',
    'ADD_CREDIT_CARD_SUCCESS':'BUSINESS/ADD_CREDIT_CARD_SUCCESS',
    'ADD_CREDIT_CARD_FAILED':'BUSINESS/ADD_CREDIT_CARD_FAILED',
    'UPDATE_CREDIT_CARD_SUCCESS':'BUSINESS/UPDATE_CREDIT_CARD_SUCCESS',
    'UPDATE_CREDIT_CARD_FAILED':'BUSINESS/UPDATE_CREDIT_CARD_FAILED',
    'DELETE_CREDIT_CARD_SUCCESS':'BUSINESS/DELETE_CREDIT_CARD_SUCCESS',
    'DELETE_CREDIT_CARD_FAILED':'BUSINESS/DELETE_CREDIT_CARD_FAILED',

    'SET_SELECTED_PERSONAL_BORROWING':'BUSINESS/SET_SELECTED_PERSONAL_BORROWING',
    'FETCH_PERSONAL_BORROWING_SUCCESS':'BUSINESS/FETCH_PERSONAL_BORROWING_SUCCESS',
    'FETCH_PERSONAL_BORROWING_FAILED':'BUSINESS/FETCH_PERSONAL_BORROWING_FAILED',
    'FETCH_PERSONAL_BORROWINGS_SUCCESS':'BUSINESS/FETCH_PERSONAL_BORROWINGS_SUCCESS',
    'FETCH_PERSONAL_BORROWINGS_FAILED':'BUSINESS/FETCH_PERSONAL_BORROWINGS_FAILED',
    'ADD_PERSONAL_BORROWING_SUCCESS':'BUSINESS/ADD_PERSONAL_BORROWING_SUCCESS',
    'ADD_PERSONAL_BORROWING_FAILED':'BUSINESS/ADD_PERSONAL_BORROWING_FAILED',
    'UPDATE_PERSONAL_BORROWING_SUCCESS':'BUSINESS/UPDATE_PERSONAL_BORROWING_SUCCESS',
    'UPDATE_PERSONAL_BORROWING_FAILED':'BUSINESS/UPDATE_PERSONAL_BORROWING_FAILED',
    'DELETE_PERSONAL_BORROWING_SUCCESS':'BUSINESS/DELETE_PERSONAL_BORROWING_SUCCESS',
    'DELETE_PERSONAL_BORROWING_FAILED':'BUSINESS/DELETE_PERSONAL_BORROWING_FAILED',

    'SET_SELECTED_CHEQUE':'BUSINESS/SET_SELECTED_CHEQUE',
    'FETCH_CHEQUE_SUCCESS':'BUSINESS/FETCH_CHEQUE_SUCCESS',
    'FETCH_CHEQUE_FAILED':'BUSINESS/FETCH_CHEQUE_FAILED',
    'FETCH_CHEQUES_SUCCESS':'BUSINESS/FETCH_CHEQUES_SUCCESS',
    'FETCH_CHEQUES_FAILED':'BUSINESS/FETCH_CHEQUES_FAILED',
    'ADD_CHEQUE_SUCCESS':'BUSINESS/ADD_CHEQUE_SUCCESS',
    'ADD_CHEQUE_FAILED':'BUSINESS/ADD_CHEQUE_FAILED',
    'UPDATE_CHEQUE_SUCCESS':'BUSINESS/UPDATE_CHEQUE_SUCCESS',
    'UPDATE_CHEQUE_FAILED':'BUSINESS/UPDATE_CHEQUE_FAILED',
    'DELETE_CHEQUE_SUCCESS':'BUSINESS/DELETE_CHEQUE_SUCCESS',
    'DELETE_CHEQUE_FAILED':'BUSINESS/DELETE_CHEQUE_FAILED',

    'SET_SELECTED_VAT_RETURN':'BUSINESS/SET_SELECTED_VAT_RETURN',
    'FETCH_VAT_RETURN_SUCCESS':'BUSINESS/FETCH_VAT_RETURN_SUCCESS',
    'FETCH_VAT_RETURN_FAILED':'BUSINESS/FETCH_VAT_RETURN_FAILED',
    'FETCH_VAT_RETURNS_SUCCESS':'BUSINESS/FETCH_VAT_RETURNS_SUCCESS',
    'FETCH_VAT_RETURNS_FAILED':'BUSINESS/FETCH_VAT_RETURNS_FAILED',
    'ADD_VAT_RETURN_SUCCESS':'BUSINESS/ADD_VAT_RETURN_SUCCESS',
    'ADD_VAT_RETURN_FAILED':'BUSINESS/ADD_VAT_RETURN_FAILED',
    'UPDATE_VAT_RETURN_SUCCESS':'BUSINESS/UPDATE_VAT_RETURN_SUCCESS',
    'UPDATE_VAT_RETURN_FAILED':'BUSINESS/UPDATE_VAT_RETURN_FAILED',
    'DELETE_VAT_RETURN_SUCCESS':'BUSINESS/DELETE_VAT_RETURN_SUCCESS',
    'DELETE_VAT_RETURN_FAILED':'BUSINESS/DELETE_VAT_RETURN_FAILED',

    'SET_SELECTED_WPS_DETAIL':'BUSINESS/SET_SELECTED_WPS_DETAIL',
    'FETCH_WPS_DETAIL_SUCCESS':'BUSINESS/FETCH_WPS_DETAIL_SUCCESS',
    'FETCH_WPS_DETAIL_FAILED':'BUSINESS/FETCH_WPS_DETAIL_FAILED',
    'FETCH_WPS_DETAILS_SUCCESS':'BUSINESS/FETCH_WPS_DETAILS_SUCCESS',
    'FETCH_WPS_DETAILS_FAILED':'BUSINESS/FETCH_WPS_DETAILS_FAILED',
    'ADD_WPS_DETAIL_SUCCESS':'BUSINESS/ADD_WPS_DETAIL_SUCCESS',
    'ADD_WPS_DETAIL_FAILED':'BUSINESS/ADD_WPS_DETAIL_FAILED',
    'UPDATE_WPS_DETAIL_SUCCESS':'BUSINESS/UPDATE_WPS_DETAIL_SUCCESS',
    'UPDATE_WPS_DETAIL_FAILED':'BUSINESS/UPDATE_WPS_DETAIL_FAILED',
    'DELETE_WPS_DETAIL_SUCCESS':'BUSINESS/DELETE_WPS_DETAIL_SUCCESS',
    'DELETE_WPS_DETAIL_FAILED':'BUSINESS/DELETE_WPS_DETAIL_FAILED',

    'SET_SELECTED_ADDRESS':'BUSINESS/SET_SELECTED_ADDRESS',
    'FETCH_ADDRESS_SUCCESS':'BUSINESS/FETCH_ADDRESS_SUCCESS',
    'FETCH_ADDRESS_FAILED':'BUSINESS/FETCH_ADDRESS_FAILED',
    'FETCH_ADDRESSES_SUCCESS':'BUSINESS/FETCH_ADDRESSES_SUCCESS',
    'FETCH_ADDRESSES_FAILED':'BUSINESS/FETCH_ADDRESSES_FAILED',
    'ADD_ADDRESS_SUCCESS':'BUSINESS/ADD_ADDRESS_SUCCESS',
    'ADD_ADDRESS_FAILED':'BUSINESS/ADD_ADDRESS_FAILED',
    'UPDATE_ADDRESS_SUCCESS':'BUSINESS/UPDATE_ADDRESS_SUCCESS',
    'UPDATE_ADDRESS_FAILED':'BUSINESS/UPDATE_ADDRESS_FAILED',
    'DELETE_ADDRESS_SUCCESS':'BUSINESS/DELETE_ADDRESS_SUCCESS',
    'DELETE_ADDRESS_FAILED':'BUSINESS/DELETE_ADDRESS_FAILED',

    'SET_SELECTED_CUSTOMER_SUPPLIER':'BUSINESS/SET_SELECTED_CUSTOMER_SUPPLIER',
    'FETCH_CUSTOMER_SUPPLIER_SUCCESS':'BUSINESS/FETCH_CUSTOMER_SUPPLIER_SUCCESS',
    'FETCH_CUSTOMER_SUPPLIER_FAILED':'BUSINESS/FETCH_CUSTOMER_SUPPLIER_FAILED',
    'FETCH_CUSTOMER_SUPPLIERS_SUCCESS':'BUSINESS/FETCH_CUSTOMER_SUPPLIERS_SUCCESS',
    'FETCH_CUSTOMER_SUPPLIERS_FAILED':'BUSINESS/FETCH_CUSTOMER_SUPPLIERS_FAILED',
    'ADD_CUSTOMER_SUPPLIER_SUCCESS':'BUSINESS/ADD_CUSTOMER_SUPPLIER_SUCCESS',
    'ADD_CUSTOMER_SUPPLIER_FAILED':'BUSINESS/ADD_CUSTOMER_SUPPLIER_FAILED',
    'UPDATE_CUSTOMER_SUPPLIER_SUCCESS':'BUSINESS/UPDATE_CUSTOMER_SUPPLIER_SUCCESS',
    'UPDATE_CUSTOMER_SUPPLIER_FAILED':'BUSINESS/UPDATE_CUSTOMER_SUPPLIER_FAILED',
    'DELETE_CUSTOMER_SUPPLIER_SUCCESS':'BUSINESS/DELETE_CUSTOMER_SUPPLIER_SUCCESS',
    'DELETE_CUSTOMER_SUPPLIER_FAILED':'BUSINESS/DELETE_CUSTOMER_SUPPLIER_FAILED',

    'SET_SELECTED_CUSTOMER':'BUSINESS/SET_SELECTED_CUSTOMER',
    'FETCH_CUSTOMER_SUCCESS':'BUSINESS/FETCH_CUSTOMER_SUCCESS',
    'FETCH_CUSTOMER_FAILED':'BUSINESS/FETCH_CUSTOMER_FAILED',
    'FETCH_CUSTOMERS_SUCCESS':'BUSINESS/FETCH_CUSTOMERS_SUCCESS',
    'FETCH_CUSTOMERS_FAILED':'BUSINESS/FETCH_CUSTOMERS_FAILED',
    'ADD_CUSTOMER_SUCCESS':'BUSINESS/ADD_CUSTOMER_SUCCESS',
    'ADD_CUSTOMER_FAILED':'BUSINESS/ADD_CUSTOMER_FAILED',
    'UPDATE_CUSTOMER_SUCCESS':'BUSINESS/UPDATE_CUSTOMER_SUCCESS',
    'UPDATE_CUSTOMER_FAILED':'BUSINESS/UPDATE_CUSTOMER_FAILED',
    'VERIFY_CUSTOMER_SUCCESS':'CUSTOMER/VERIFY_CUSTOMER_SUCCESS',
    'VERIFY_CUSTOMER_FAILED':'CUSTOMER/VERIFY_CUSTOMER_FAILED',
    'RETURN_CUSTOMER_SUCCESS':'CUSTOMER/RETURN_CUSTOMER_SUCCESS',
    'RETURN_CUSTOMER_FAILED':'CUSTOMER/RETURN_CUSTOMER_FAILED',
    'APPROVE_CUSTOMER_SUCCESS':'CUSTOMER/APPROVE_CUSTOMER_SUCCESS',
    'APPROVE_CUSTOMER_FAILED':'CUSTOMER/APPROVE_CUSTOMER_FAILED',
    'REJECT_CUSTOMER_SUCCESS':'CUSTOMER/REJECT_CUSTOMER_SUCCESS',
    'REJECT_CUSTOMER_FAILED':'CUSTOMER/REJECT_CUSTOMER_FAILED',

    'DELETE_CUSTOMER_SUCCESS':'BUSINESS/DELETE_CUSTOMER_SUCCESS',
    'DELETE_CUSTOMER_FAILED':'BUSINESS/DELETE_CUSTOMER_FAILED',

    'FETCH_DOCUMENTS_SUCCESS':'BUSINESS/FETCH_DOCUMENTS_SUCCESS',
    'FETCH_DOCUMENTS_FAILED':'BUSINESS/FETCH_DOCUMENTS_FAILED',
    'UPLOAD_DOCUMENT_SUCCESS':'BUSINESS/UPLOAD_DOCUMENT_SUCCESS',
    'UPLOAD_DOCUMENT_FAILED':'BUSINESS/UPLOAD_DOCUMENT_FAILED',
    'REMOVE_DOCUMENT_SUCCESS':'BUSINESS/REMOVE_DOCUMENT_SUCCESS',
    'REMOVE_DOCUMENT_FAILED':'BUSINESS/REMOVE_DOCUMENT_FAILED',

    'SET_SELECTED_INVOICE':'INVOICE/SET_SELECTED_INVOICE',
    'SET_SELECTED_INVOICE_SUCCESS':'INVOICE/SET_SELECTED_INVOICE_SUCCESS',
    'FETCH_INVOICE_SUCCESS':'INVOICE/FETCH_INVOICE_SUCCESS',
    'FETCH_INVOICE_FAILED':'INVOICE/FETCH_INVOICE_FAILED',
    'FETCH_INVOICES_SUCCESS':'INVOICE/FETCH_INVOICES_SUCCESS',
    'FETCH_INVOICES_FAILED':'INVOICE/FETCH_INVOICES_FAILED',
    'ADD_INVOICE_SUCCESS':'INVOICE/ADD_INVOICE_SUCCESS',
    'ADD_INVOICE_FAILED':'INVOICE/ADD_INVOICE_FAILED',
    'UPDATE_INVOICE_SUCCESS':'INVOICE/UPDATE_INVOICE_SUCCESS',
    'UPDATE_INVOICE_FAILED':'INVOICE/UPDATE_INVOICE_FAILED',
    'SUBMIT_INVOICE_FOR_APPROVAL_SUCCESS':'INVOICE/SUBMIT_INVOICE_FOR_APPROVAL_SUCCESS',
    'SUBMIT_INVOICE_FOR_APPROVAL_FAILED':'INVOICE/SUBMIT_INVOICE_FOR_APPROVAL_FAILED',
    'VERIFY_INVOICE_SUCCESS':'INVOICE/VERIFY_INVOICE_SUCCESS',
    'VERIFY_INVOICE_FAILED':'INVOICE/VERIFY_INVOICE_FAILED',
    'RETURN_INVOICE_SUCCESS':'INVOICE/RETURN_INVOICE_SUCCESS',
    'RETURN_INVOICE_FAILED':'INVOICE/RETURN_INVOICE_FAILED',
    'CONFIRM_INVOICE_SUCCESS':'INVOICE/CONFIRM_INVOICE_SUCCESS',
    'CONFIRM_INVOICE_FAILED':'INVOICE/CONFIRM_INVOICE_FAILED',
    'APPROVE_INVOICE_SUCCESS':'INVOICE/APPROVE_INVOICE_SUCCESS',
    'APPROVE_INVOICE_FAILED':'INVOICE/APPROVE_INVOICE_FAILED',
    'REJECT_INVOICE_SUCCESS':'INVOICE/REJECT_INVOICE_SUCCESS',
    'REJECT_INVOICE_FAILED':'INVOICE/REJECT_INVOICE_FAILED',
    'DELETE_INVOICE_SUCCESS':'INVOICE/DELETE_INVOICE_SUCCESS',
    'DELETE_INVOICE_FAILED':'INVOICE/DELETE_INVOICE_FAILED',

    'FETCH_BUSINESS_SERVICES_SUCCESS':'BUSINESS/FETCH_BUSINESS_SERVICES_SUCCESS',
    'FETCH_BUSINESS_SERVICES_FAILED':'BUSINESS/FETCH_BUSINESS_SERVICES_FAILED',
}

export default CONST;