import * as React from 'react';
import { Container, Grid} from "@mui/material";
import {useDispatch, } from "react-redux";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import {fetchAccounts} from "../index";
import {useEffect} from "react";
import {AccountsTable} from "../index";
import moment from "moment";

export const Accounts = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetch();
    },[]);

    const fetch = async () => {

        const due_date = moment().format('YYYY-MM-DD');

        // await fetchAccounts(dispatch, {due_date} ).then((res) => {
        //     //console.log(res);
        // });
    }

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <IconBreadcrumbs items={'DASHBOARD/PAYMENTS'} />
                    </Grid>
                    <Grid item xs={12}>
                        <AccountsTable />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

