import * as React from 'react';
import { CardContent, CardHeader, IconButton, Collapse} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import { Panel, PanelList } from "../../../Components";

export default function CustomerConfigPanel() {

    const customer = useSelector(state => state.business?.customers?.selected);
    const config = customer.config;
    const [isExpanded, setIsExpanded] = useState(false);

    const config_list = [
        {"label" : "Credit Limit Requested","name" : "credit_limit_requested", "suffix": "currency" },
        {"label" : "Credit Limit Recommended","name" : "credit_limit_recommended", "suffix": "currency" },
        {"label" : "Credit Limit Approved","name" : "credit_limit_approved", "suffix": "currency" },
        {"label" : "Max Allowed Tenor","name" : "max_allowed_tenor", "suffix": "days" },
        {"label" : "Discount Forfaiting Fee","name" : "discounting_forfaiting_fee", "suffix": "currency" },
        {"label" : "Margin Required","name" : "margin_required", "suffix": "currency" },
        {"label" : "Payment Terms","name" : "payment_terms", "suffix": "days" },
        {"label" : "Grace Period","name" : "grace_period", "suffix": "days" },
        {"label" : "Payment Method","name" : "payment_method", "suffix": "" },
        {"label" : "Currency","name" : "currency", "suffix": "" },
        {"label" : "Retention Amount","name" : "retention_amount", "suffix": "currency" },
        {"label" : "Drawdown Fee","name" : "drawdown_fee", "suffix": "currency" },
        {"label" : "Utilization Commitment","name" : "utilization_commitment", "suffix": "%" },
        {"label" : "Other Conditions","name" : "other_conditions", "suffix": "" },
    ];

    return (
        <>
            { (config !== undefined && config.length > 0) &&
                <Panel>
                    <CardHeader title={"Customer Configuration"} titleTypographyProps={{variant: 'heading'}}
                                action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }/>
                    <Collapse in={isExpanded}>
                        <CardContent>
                            <PanelList>
                                <ListItem>
                                    <ListItemText primary={'Number of Discounted Invoices'} secondary={customer?.discounted_invoices} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={'Credit Limit Utilized'} secondary={customer?.credit_limit_utilized} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={'Credit Limit Available'} secondary={customer?.credit_limit_available} />
                                </ListItem>
                                { config_list.map((v,i) => {
                                    const value = (config.find(x => x.config_type === v.name))?.config_value;
                                    const currency = (config.find(x => x.config_type === 'currency'))?.config_value;
                                    return value && <ListItem key={i}>
                                        <ListItemText primary={v.label} secondary={ value + " " + (v.suffix === 'currency' ? currency : v.suffix)  } />
                                    </ListItem>
                                })}
                            </PanelList>
                        </CardContent>
                    </Collapse>
                </Panel>
            }
        </>
    );
}
