import React from "react";
import {
    FormControl, Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import {setSelectedCreditCard, updateCreditCard, } from "../../index";
import MenuItem from "@mui/material/MenuItem";
import BankAutocomplete from "../BankAutocomplete";
import Toastr from "../../../Components/Common/Toastr";
import AttachDoc from "../../../Documents/Components/AttachDoc";

export const CreditCardForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState(false);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(business.creditCards.selected);
    }, [])

    const handleSubmit = async () => {
        setDisabled(true);
        await updateCreditCard(dispatch, data).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setFsModal({component:null, state:false}));
        dispatch(setSelectedCreditCard(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Personal / Corporate Credit Card Details
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>
                                <FormControl sx={{ width:'100%' }}>
                                    <InputLabel id="card-type">Card Type</InputLabel>
                                    <Select
                                        defaultValue={data?.type}
                                        name="type"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Card Type"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem key={"Corporate"} value={'Corporate'}>Corporate</MenuItem>
                                        <MenuItem key={"Personal"} value={'Personal'}>Personal</MenuItem>
                                    </Select>
                                </FormControl>

                                <BankAutocomplete setDisabled={setDisabled} setData={setData} data={data} />

                                <TextField
                                    fullWidth
                                    label="Card Holder Name"
                                    name="card_holder_name"
                                    defaultValue={data?.card_holder_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.card_holder_name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Credit Card Limit Amount"
                                    name="limit"
                                    defaultValue={data?.limit}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.limit}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Credit Card Outstanding Amount"
                                    name="outstanding"
                                    defaultValue={data?.outstanding}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.outstanding}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Credit Card Overdue Amount (if any)"
                                    name="overdue"
                                    defaultValue={data?.overdue}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.overdue}
                                    onBlur={handleChange} />

                                <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateCreditCard} allowedFileType={['pdf']} onBlur={handleChange} label={"Card Statements"} entity_name={"creditCards"} entity_id={data?.card_id} name="cardStatements" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    name="remarks"
                                    multiline
                                    rows={3}
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />
                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}

