import * as React from 'react';
import {useDispatch} from "react-redux";
import {disbursed} from "../../Redux/LoanAction";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export const DisbursementCompleteModal = (props) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        let response = '';
        setDisabled(true);
        await disbursed(dispatch, {loan_id:props.loan_id}).then(res => {
            response = res['success'];
            props.setOpen(false);
            if(res['success']){
                props.setOpen(false);
                navigate('/business-finance');
            }
        });
        //await fetchService(dispatch, response.data.service_id);
    };

    return(
        <>
            <DialogTitle>Request Disbursement</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, the loan is disbursed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Loan Disbursed</Button>
            </DialogActions>
        </>
    )
}