import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected: {}
};

const settingReducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_SELECTED_SETTING:
            return {
                ...state,
                selected: state.list.filter(item => item.setting_id === action.payload)[0],
            }
        case CONST.FETCH_SETTING_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                code:200,
                message: ["Settings loaded successfully"]
            }
        case CONST.FETCH_SETTING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                list:action.payload.data,
                error: [],
                message: ["Settings loaded successfully"]
            }
        case CONST.FETCH_SETTINGS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_SETTING_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: {},
                error: [],
                message: ["Settings added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_SETTING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_SETTING_SUCCESS:
            const indexU = state.list.findIndex(setting => setting.setting_id === action.payload.data.setting_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Settings updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_SETTING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.DELETE_SETTING_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.setting_id !== action.payload),
                selected: {},
                error: [],
                message: ["Settings deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_SETTING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default settingReducer ;