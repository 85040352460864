const CONST = {
    'CLEAR_PROCEED_STATE':'LOAN/CLEAR_PROCEED_STATE',
    'CLEAR_ERROR_STATE':'LOAN/CLEAR_ERROR_STATE',

    'SET_SELECTED_LOAN':'LOAN/SET_SELECTED_LOAN',
    'SET_SELECTED_LOAN_SUCCESS':'LOAN/SET_SELECTED_LOAN_SUCCESS',
    'FETCH_LOAN_SUCCESS':'LOAN/FETCH_LOAN_SUCCESS',
    'FETCH_LOAN_FAILED':'LOAN/FETCH_LOAN_FAILED',
    'FETCH_LOANS_SUCCESS':'LOAN/FETCH_LOANS_SUCCESS',
    'FETCH_LOANS_FAILED':'LOAN/FETCH_LOANS_FAILED',
    'ADD_LOAN_SUCCESS':'LOAN/ADD_LOAN_SUCCESS',
    'ADD_LOAN_FAILED':'LOAN/ADD_LOAN_FAILED',
    'VERIFY_LOAN_SUCCESS':'LOAN/VERIFY_LOAN_SUCCESS',
    'VERIFY_LOAN_FAILED':'LOAN/VERIFY_LOAN_FAILED',
    'RETURN_LOAN_SUCCESS':'LOAN/RETURN_LOAN_SUCCESS',
    'RETURN_LOAN_FAILED':'LOAN/RETURN_LOAN_FAILED',
    'APPROVE_LOAN_SUCCESS':'LOAN/APPROVE_LOAN_SUCCESS',
    'APPROVE_LOAN_FAILED':'LOAN/APPROVE_LOAN_FAILED',
    'REJECT_LOAN_SUCCESS':'LOAN/REJECT_LOAN_SUCCESS',
    'REJECT_LOAN_FAILED':'LOAN/REJECT_LOAN_FAILED',
    'UPDATE_LOAN_SUCCESS':'LOAN/UPDATE_LOAN_SUCCESS',
    'UPDATE_LOAN_FAILED':'LOAN/UPDATE_LOAN_FAILED',
    'DELETE_LOAN_SUCCESS':'LOAN/DELETE_LOAN_SUCCESS',
    'DELETE_LOAN_FAILED':'LOAN/DELETE_LOAN_FAILED',

    'REQUEST_DOCUMENTATION_SUCCESS':'LOAN/REQUEST_DOCUMENTATION_SUCCESS',
    'REQUEST_DOCUMENTATION_FAILED':'LOAN/REQUEST_DOCUMENTATION_FAILED',
    'DOCUMENTATION_SUCCESS':'LOAN/DOCUMENTATION_SUCCESS',
    'DOCUMENTATION_FAILED':'LOAN/DOCUMENTATION_FAILED',
    'RETURN_DOCUMENTATION_SUCCESS':'LOAN/RETURN_DOCUMENTATION_SUCCESS',
    'RETURN_DOCUMENTATION_FAILED':'LOAN/RETURN_DOCUMENTATION_FAILED',

    'REQUEST_DISBURSEMENT_SUCCESS':'LOAN/REQUEST_DISBURSEMENT_SUCCESS',
    'REQUEST_DISBURSEMENT_FAILED':'LOAN/REQUEST_DISBURSEMENT_FAILED',
    'DISBURSEMENT_SUCCESS':'LOAN/DISBURSEMENT_SUCCESS',
    'DISBURSEMENT_FAILED':'LOAN/DISBURSEMENT_FAILED',

    'FETCH_LOAN_SCHEDULES_SUCCESS':'LOAN/FETCH_LOAN_SCHEDULES_SUCCESS',
    'FETCH_LOAN_SCHEDULES_FAILED':'LOAN/FETCH_LOAN_SCHEDULES_FAILED',
    'UPDATE_LOAN_SCHEDULE_SUCCESS':'LOAN/UPDATE_LOAN_SCHEDULE_SUCCESS',
    'UPDATE_LOAN_SCHEDULE_FAILED':'LOAN/UPDATE_LOAN_SCHEDULE_FAILED',
}

export default CONST;