import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal} from "../../../../Setup/Redux/ConfigAction";
import {ChequeForm} from "../Forms/ChequeForm";
import {Card, CardContent, CardHeader, useMediaQuery} from "@mui/material";
import { addCheque, deleteCheque, clearErrors, proceed, setSelectedCheque, ChequeDeleteModal } from "../../index";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {currencyFormatter} from "../../../../Setup/Config";

export default function ChequeCard(props) {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.cheques).length > 0 ? business.cheques.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setFsModal({component:null, state:false}));
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCheque(dispatch, {business_id:business.selected.business_id});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCheque(e.currentTarget.dataset.id));
        }
        dispatch(setFsModal({component:<ChequeForm business={business} />, state:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setFsModal({component:<ChequeDeleteModal />, state:true}));
        dispatch(setSelectedCheque(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.cheque_id);
    }


    const columns = React.useMemo(
        () => [
            { field: 'cheque_id', headerName:'Cheque ID', type: 'string'},
            { field: 'cheque_date', headerName:'Date', type: 'date', flex:1, minWidth: 100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'cheque_no', headerName:'Cheque No', type: 'string',  flex:1, minWidth:90 },
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth:350,
                valueGetter: (params) => {
                    return params.row?.bank?.bank_name;
                }
            },
            { field: 'party_name', headerName:'Issued To', type: 'string', flex:1, minWidth:160 },
            { field: 'amount', headerName:'Cheque Amount', type: 'number',  flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'return_reason', headerName:'Return Reason', type: 'string', flex:1, minWidth: 150 },
            { field: 'settlement_date', headerName:'Settlement Date', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'case_reference_no', headerName:'Case Reference No', type: 'string', flex:1, minWidth: 150 },
            { field: 'remarks', headerName:'Remarks', type: 'string', flex:1, minWidth: 150 },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Cheques'}
                    action={
                        (
                            (['add-business-cheques'].some(i => auth.user.permissions.includes(i)) && ['DRAFT','SUBMITTED','VERIFIED'].some(i => business.selected.status.includes(i))) ||
                            ['su-edit-business-cheques'].some(i => auth.user.permissions.includes(i)) ||
                            auth.user.role_type === 'ADMIN'
                        )
                        &&
                        <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>
                    }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { objects !== undefined &&  Object.keys(objects).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.cheque_id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={objects}
                            sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left:['cheque_date'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        cheque_id:false,
                                        actions: ((['DRAFT','RETURNED'].includes(business.selected.status) && auth.user.role_type === 'CLIENT') || (['SUBMITTED','PROCESSING','PENDING'].includes(business.selected.status) && auth.user.role_type !== 'CLIENT') || auth.user.role_type === 'ADMIN' || ['su-edit-business-cheques'].some(i => auth.user.permissions.includes(i))),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}