import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED VAT_RETURN
 ****************************************/
export const setSelectedVatReturn = (payload) => ({
    type: CONST.SET_SELECTED_VAT_RETURN,
    payload: payload
})
/****************************************
 FETCH VAT_RETURN
 ****************************************/
export const fetchVatReturnsSuccess = (payload) => ({
    type: CONST.FETCH_VAT_RETURNS_SUCCESS,
    payload: payload
})

export const fetchVatReturnsFailed = (payload) => ({
    type: CONST.FETCH_VAT_RETURNS_FAILED,
    payload: payload
})

export const fetchVatReturns = async (dispatch, id) => {

    await axiosPrivate.get('business/'+ id + '/vatReturn').then((response) => {
        dispatch(fetchVatReturnsSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchVatReturnsFailed(error?.response));
    });
}
/****************************************
 ADD Business VAT_RETURN
 ****************************************/
export const addVatReturnSuccess = (payload) => ({
    type: CONST.ADD_VAT_RETURN_SUCCESS,
    payload: payload
})

export const addVatReturnFailed = (payload) => ({
    type: CONST.ADD_VAT_RETURN_FAILED,
    payload: payload
})

export const addVatReturn = async (dispatch, data) => {

    await axiosPrivate.post('vatReturn', data).then((response) => {
        dispatch(addVatReturnSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addVatReturnFailed(error?.response));
    });
}
/****************************************
 Update Business VAT_RETURN
 ****************************************/
export const updateVatReturnSuccess = (payload) => ({
    type: CONST.UPDATE_VAT_RETURN_SUCCESS,
    payload: payload
})

export const updateVatReturnFailed = (payload) => ({
    type: CONST.UPDATE_VAT_RETURN_FAILED,
    payload: payload
})

export const updateVatReturn = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('vatReturn/' + data['id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateVatReturnSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateVatReturnFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business VAT_RETURN
 ****************************************/
export const deleteVatReturnSuccess = (payload) => ({
    type: CONST.DELETE_VAT_RETURN_SUCCESS,
    payload: payload
})

export const deleteVatReturnFailed = (payload) => ({
    type: CONST.DELETE_VAT_RETURN_FAILED,
    payload: payload
})

export const deleteVatReturn = async (dispatch, id) => {

    await axiosPrivate.delete('vatReturn/' + id).then((response) => {
        dispatch(deleteVatReturnSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteVatReturnFailed(error?.response));
    });
}