import * as React from 'react';

import {List as ListUI} from "@mui/material";
import {styled} from "@mui/material/styles";

export const CardList = ({children, props}) => {

    const List = styled(ListUI)({
        color: 'darkslategray',
        '& .MuiListItem-root': {
            padding: '1px!important',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                padding:'4px 10px',
                borderRadius:'4px',
                background: '#f8f8f8',
                marginBottom:'2px',
                minHeight:'50px',
                border:'1px solid #efefef',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return (
        <List {...props}>
            {children}
        </List>
    )
}