import {styled, useTheme} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect, useRef} from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {openLeftDrawer, setAppBarHeight} from "../../../Setup/Redux/ConfigAction";
import AccountMenu from "../Menu/AccountMenu";
import {Container, Stack} from "@mui/material";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {doLogout} from "../../Auth";

export const AppBarIfund = () => {

    const dispatch = useDispatch();
    const config = useSelector((store) => store.config );
    const open = config.openLeftDrawer;
    const DrawerWidth = config.leftDrawerWidth;

    const AppBar = styled(MuiAppBar, {

        shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme, open }) => ({
        background:theme.navbarPublicColor,
        zIndex: theme.zIndex.drawer + 1,
        height:80,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: DrawerWidth,
            width: `calc(100% - ${DrawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        '& .MuiToolbar-root': {
            minHeight: 80,
        }
    }));

    const appBarRef = useRef(null)

    //To get the height of appbar and use it somewhere.
    useEffect(()=>{
        dispatch(setAppBarHeight(appBarRef.current.clientHeight));
    },[])

    return (
        <AppBar position="fixed" open={open} ref={appBarRef}>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Toolbar>

                    <Box component="img" src={'/images/ifund-logo.png'} alt="Logo" sx={{ height: '62px', marginRight: '16px' }} />
                    <Stack spacing={2} direction={"row"} sx={{ml:4}}>
                        <Link className={'publicMenu'} to="/dashboard" component={Typography} variant="normal" noWrap> Dashboard</Link>
                        <Link className={'publicMenu'} to="/customers" component={Typography} variant="normal" noWrap> Customers </Link>
                        <Link className={'publicMenu'} to="/business-finance" component={Typography} variant="normal" noWrap> Requests </Link>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant={"contained"} color={"error"} onClick={() => doLogout(dispatch)}>Logout</Button>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
