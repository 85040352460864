import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";



/****************************************
 FETCH LOAN_SCHEDULES
 ****************************************/
export const fetchLoanSchedulesSuccess = (payload) => ({
    type: CONST.FETCH_LOAN_SCHEDULES_SUCCESS,
    payload: payload
})

export const fetchLoanSchedulesFailed = (payload) => ({
    type: CONST.FETCH_LOAN_SCHEDULES_FAILED,
    payload: payload
})

export const fetchLoanSchedules = async (dispatch, data) => {

    await axiosPrivate.get('loan', data).then((response) => {
        dispatch(fetchLoanSchedulesSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchLoanSchedulesFailed(error?.response));
    });
}

/****************************************
 Update LoanSchedule - Details
 ****************************************/
export const updateLoanScheduleSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SCHEDULE_SUCCESS,
    payload: payload
})

export const updateLoanScheduleFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_SCHEDULE_FAILED,
    payload: payload
})

export const updateLoanSchedule = async (dispatch, data) => {

    data['is_active'] = 1;
    let res = {};

    await axiosPrivate.patch('loan/schedule/' + data['id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateLoanScheduleSuccess(response));
    }).catch((error)=>{
        res['error'] = error?.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateLoanScheduleFailed(error?.response));
    });
    return res;
}


/****************************************
 Submit LoanSchedule - For Approval Request
 ****************************************/
export const updateLoanScheduleStatusSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SCHEDULE_SUCCESS,
    payload: payload
})

export const updateLoanScheduleStatusFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_SCHEDULE_FAILED,
    payload: payload
})

export const updateLoanScheduleStatus = async (dispatch, data) => {
    let res;
    await axiosPrivate.patch('loan/' + data['loan_id'], data).then((response) => {
        dispatch(updateLoanScheduleStatusSuccess(response));
        res = response;
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateLoanScheduleStatusFailed(error?.response));
    });
    return res;
}