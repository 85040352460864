import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";

/****************************************
 SET SELECTED FINANCIAL
 ****************************************/
export const setSelectedFinancial = (payload) => ({
    type: CONST.SET_SELECTED_FINANCIAL,
    payload: payload
})

/****************************************
 FETCH FINANCIAL
 ****************************************/
export const fetchFinancialsSuccess = (payload) => ({
    type: CONST.FETCH_FINANCIALS_SUCCESS,
    payload: payload
})

export const fetchFinancialsFailed = (payload) => ({
    type: CONST.FETCH_FINANCIALS_FAILED,
    payload: payload
})

export const fetchFinancials = async (dispatch, id) => {

    await axiosPrivate.get('business/'+ id + '/financial').then((response) => {
        dispatch(fetchFinancialsSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchFinancialsFailed(error?.response));
    });
}
/****************************************
 ADD Business Financial
 ****************************************/
export const addFinancialSuccess = (payload) => ({
    type: CONST.ADD_FINANCIAL_SUCCESS,
    payload: payload
})

export const addFinancialFailed = (payload) => ({
    type: CONST.ADD_FINANCIAL_FAILED,
    payload: payload
})

export const addFinancial = async (dispatch, data) => {

    await axiosPrivate.post('financial', data).then((response) => {
        dispatch(addFinancialSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addFinancialFailed(error?.response));
    });
}
/****************************************
 Update Business Financial
 ****************************************/
export const updateFinancialSuccess = (payload) => ({
    type: CONST.UPDATE_FINANCIAL_SUCCESS,
    payload: payload
})

export const updateFinancialFailed = (payload) => ({
    type: CONST.UPDATE_FINANCIAL_FAILED,
    payload: payload
})

export const updateFinancial = async (dispatch, data) => {
    let res = {};
    await axiosPrivate.patch('financial/' + data['id'], data).then((response) => {
        dispatch(updateFinancialSuccess(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateFinancialFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business Financial
 ****************************************/
export const deleteFinancialSuccess = (payload) => ({
    type: CONST.DELETE_FINANCIAL_SUCCESS,
    payload: payload
})

export const deleteFinancialFailed = (payload) => ({
    type: CONST.DELETE_FINANCIAL_FAILED,
    payload: payload
})

export const deleteFinancial = async (dispatch, id) => {

    await axiosPrivate.delete('financial/' + id).then((response) => {
        dispatch(deleteFinancialSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteFinancialFailed(error?.response));
    });
}