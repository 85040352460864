import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    DataGridPro,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";
import { CardContent, CardHeader} from "@mui/material";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {SkeletonTable} from "../../../Components/Common/SkeletonTable";
import {InvoiceDetail} from "../Cards/InvoiceDetail";
import {fetchInvoice, fetchInvoices} from "../../Redux/InvoiceAction";
import {Panel} from "../../../Components";
import {useNavigate} from "react-router-dom";
import {Chip} from "../../../Components/Common/Chip";

export const MyInvoicesPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const invoices = useSelector((store) => store.invoices);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [filters, setFilters] = React.useState({
        pageSize: '',
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {
        setShowSkeleton(true);
        await fetchInvoices(dispatch, filters).then((res) => {
            setShowSkeleton(false);
        })
    }

    const viewItem = async (id) => {
        await fetchInvoice(dispatch, id);
        navigate('/invoices/' + id)
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <InvoiceDetail data={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
                ),
            },
            { field: 'invoice_no', headerName:'Invoice No', type: 'string', minWidth:100, flex: 1,},
            { field: 'invoice_amount', headerName:'Amount', type: 'string', minWidth:100, flex: 1,},
            { field: 'assigned_to', headerName:'Assigned to', type: 'string', minWidth:100, flex: 1,
                valueGetter: (params) => {
                    return params.row?.assigned_to[0]?.first_name;
                }
            },
            { field: 'assigned_at', headerName:'Assigned At', type: 'string', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).from(),
                valueGetter: (params) => {
                    return params.row?.assigned_to[0]?.created_at;
                }
            },
            { field: 'status',headerAlign:'center', align:'center',  headerName:'Status', type: 'string', flex:1, minWidth:100,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'PENDING':'warning',
                        'SUBMITTED':'info',
                        'VERIFIED':'success',
                        'PROCESSING':'primary',
                        'APPROVED':'success',
                        'REJECTED':'error',
                        'CANCELLED':'secondary',
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    >
                    </Chip>)
                },
            },
        ],
        [],
    );

    return (
        <>
            <Panel>
                <CardHeader title={'Invoices'} />
                <CardContent sx={{p:0}}>
                    { !showSkeleton ?
                        <DataGridPro
                            getRowId={(row) => row.invoice_id}
                            pageSize={filters.pageSize}
                            pageSizeOptions={[5, 10, 25]}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onCellClick={(params) => {
                                if (params.field === 'invoice_no') {
                                    viewItem(params.row.invoice_id);
                                }
                            }}
                            rows={invoices.list || []}
                            sx={{ border:'none'}}
                            rowThreshold={0}
                            getDetailPanelContent={getDetailPanelContent}
                            getDetailPanelHeight={getDetailPanelHeight}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 5 } },
                                pinnedColumns: { right: [ 'status','actions'] },
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                columns:{
                                    columnVisibilityModel:{
                                        actions: auth.user.role_type !== "CLIENT",
                                        assigned_to: ["ADMIN","SUPERADMIN"].includes(auth.user.role_type),
                                    }
                                }
                            }}
                            autoHeight={true}
                            pagination={true}
                        />
                        :
                        <SkeletonTable rows={5} cols={4} />
                    }
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};