import React, {useState, useEffect, useCallback} from 'react';
import Box from "@mui/material/Box";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader, Container, FormControl, FormHelperText,
    Grid, InputLabel, Select,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {captureLead, doRegister} from "../../Users";
import {resetUsers} from "../../Users";
import {isBrowser} from "react-device-detect";
import {useTheme} from "@mui/material/styles";
import Toastr from "../../Components/Common/Toastr";
import PoweredBy from "../../Components/Common/PoweredBy";
import {showBlockUI} from "../../../Setup/Redux/ConfigAction";
import BlockUI from "../../Components/Common/BlockUI";
import MuiDatepicker from "../../Business/Components/Datepicker";
import MenuItem from "@mui/material/MenuItem";

export const Register = () => {

    return (
        <>
            {isBrowser ? <BrowserView /> : <MobileView /> }
        </>
    )
}

const checkValidCompany = (date) => {

    const corporation_date = new Date(date);
    let last_year = new Date();
    last_year = last_year.setDate(last_year.getDate() - 180);
    return corporation_date < last_year;
}


const BrowserView = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const users = useSelector((store)=> store.users);
    const [response, setResponse] = useState({});
    const [disabled,setDisabled] = useState(false);
    const [leadCaptured, setLeadCaptured] = useState(false);
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [data, setData] = useState({});

    useEffect(()=>{
        setLeadCaptured(false);
        setRegistrationComplete(false);
        dispatch(showBlockUI(false));
        dispatch(resetUsers(false));
    },[]);

    const handleSubmit = async () => {
        setResponse(false);
        setDisabled(true);
        dispatch(showBlockUI(true));

        if(checkValidCompany(data['company_incorporation_date'])){
            data['service'] = 'RECEIVABLE_DISCOUNTING';
            data['total_revenue'] = 0;
            await doRegister(dispatch, data).then((res)=>{
                dispatch(showBlockUI(false));
                setResponse(res);
                setRegistrationComplete(res['success']);
            });
        }else{
            await captureLead(dispatch, data).then((res)=>{
                dispatch(showBlockUI(false));
                setLeadCaptured(res['success']);
            });
        }
    };
    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const ALPHABETS = /^[a-zA-Z]+$/;

    return (<Container component="main" maxWidth={'md'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh', width:'100%'}} alignItems={"center"}>

                    <Box sx={{flexGrow:1, display:'flex', justifyContent:'center', alignItems:'center'}} />

                    <Box sx={{p:2, flexGrow:0.75, width:'100%'}}>

                        <Stack direction={"column"} spacing={2} alignItems={"center"}>

                            <Box sx={{marginBottom:'20px'}}>
                                <img src={'/images/ifund-logo.png'} width={200}  alt={"Logo"}/>
                            </Box>

                            { (!leadCaptured && !registrationComplete) && <Card sx={{marginTop:'80px', border:'none', width:'100%'}}>

                                <CardHeader title={"Registration"} sx={{borderBottom:'none!important'}} subheader={"To apply for funding, simply provide us with the details about your company and initiate the registration process."} />

                                <CardContent>

                                    { response.status !== 201 &&
                                        <Grid container spacing={2} elevated={3}>
                                            <Grid item sm={6}>
                                                <Stack spacing={2}>
                                                    <TextField
                                                        type="text"
                                                        name="first_name"
                                                        onKeyDown={(e) => {
                                                            if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange(e)}
                                                        value={ data['first_name'] }
                                                        required
                                                        label="First Name"
                                                        helperText={users.error?.first_name}
                                                    />
                                                    <TextField
                                                        type="email"
                                                        name="email"
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['email']}
                                                        required
                                                        label="Email"
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off',
                                                            },
                                                        }}
                                                        helperText={users.error?.email}
                                                    />
                                                    <TextField
                                                        type="tel"
                                                        name="phone"
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['phone']}
                                                        required
                                                        label="Phone"
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off',
                                                            },
                                                        }}
                                                        helperText={users.error?.phone}
                                                    />
                                                    <TextField
                                                        type="password"
                                                        name="password"
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['password']}
                                                        autoComplete="off"
                                                        required
                                                        label="Password"
                                                        sx={{ width: '100%' }}
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off',
                                                            },
                                                        }}
                                                        helperText={users.error?.password}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Stack spacing={2}>
                                                    <TextField
                                                        type="text"
                                                        name="last_name"
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['last_name']}
                                                        required
                                                        onKeyDown={(event) => {
                                                            if (!ALPHABETS.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        label="Last Name"
                                                        helperText={users.error?.last_name}
                                                    />
                                                    <TextField
                                                        type="text"
                                                        name="company_name"
                                                        onKeyDown={(e) => {
                                                            if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange(e)}
                                                        value={ data['company_name'] }
                                                        required
                                                        label="Company Name"
                                                        helperText={users.error?.company_name}
                                                    />

                                                    <MuiDatepicker error={users?.error?.company_incorporation_date} setDisabled={setDisabled} label={"Company Incorporation Date"} past={true} name="company_incorporation_date" data={data} state={setData} />

                                                    <TextField
                                                        type="text"
                                                        name="total_revenue"
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['total_revenue'] ?? 0}
                                                        required
                                                        hidden={true}
                                                        label="Total Revenue"
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off',
                                                            },
                                                        }}
                                                        helperText={users.error?.total_revenue}
                                                    />
                                                    <TextField
                                                        type="text"
                                                        name="amount"
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['amount']}
                                                        required
                                                        label="Required Funding Amount"
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off',

                                                            },
                                                        }}
                                                        helperText={users.error?.amount}
                                                    />
                                                    <FormControl sx={{ width:'100%' }} hidden={true}>
                                                        <InputLabel id="card-type">Funding Tenor</InputLabel>
                                                        <Select
                                                            value={""}
                                                            name="tenor"
                                                            MenuProps={{
                                                                style: {zIndex: 35001}
                                                            }}
                                                            fullWidth
                                                            label="Funding Tenor"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            <MenuItem key={""} value=" " disabled>Select Tenor</MenuItem>
                                                            <MenuItem key={"3M"} value={'3M'}>3 Months</MenuItem>
                                                            <MenuItem key={"6M"} value={'6M'}>6 Months</MenuItem>
                                                            <MenuItem key={"12M"} value={'12M'}>12 Months</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{users.error?.tenor}</FormHelperText>
                                                    </FormControl>
                                                    <FormControl sx={{ width:'100%', display:'none' }}>
                                                        <InputLabel id="service">Service</InputLabel>
                                                        <Select
                                                            defaultValue={"RECEIVABLE_DISCOUNTING"}
                                                            name="service"
                                                            MenuProps={{
                                                                style: {zIndex: 35001}
                                                            }}
                                                            fullWidth
                                                            label="Service"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            <MenuItem key={""} value={""} disabled>Select Service</MenuItem>
                                                            <MenuItem key={"RECEIVABLE_DISCOUNTING"} value={'RECEIVABLE_DISCOUNTING'}>Receivable Discounting</MenuItem>
                                                            {/*<MenuItem key={"INVOICE_DISCOUNTING"} value={'INVOICE_DISCOUNTING'}>Invoice Discounting</MenuItem>*/}
                                                        </Select>
                                                        <FormHelperText>{users.error?.service}</FormHelperText>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Stack spacing={2}>
                                                    <TextField
                                                        type="text"
                                                        name="purpose"
                                                        multiline
                                                        rows={4}
                                                        onChange={(e) => handleChange(e)}
                                                        value={data['purpose']}
                                                        autoComplete="off"
                                                        required
                                                        label="Purpose of Funding"
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off',
                                                            },
                                                        }}
                                                        helperText={users.error?.purpose}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    }
                                </CardContent>
                                <CardActions sx={{display:'flex', flexDirection:'column', p:2, borderTop:'none!important'}} >

                                    { !leadCaptured &&
                                        <Button color="primary" sx={{ width:'25%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Sign Up</Button>
                                    }

                                    <Stack direction={"row"} spacing={2} justifyContent="space-between">
                                        <Typography align={'center'}>
                                            Already have an account? <Link to={'/login'}>Login</Link>
                                        </Typography>
                                    </Stack>
                                </CardActions>
                            </Card> }
                            { leadCaptured && <RegistrationFailedCard /> }
                            { registrationComplete && <RegistrationSuccessCard /> }
                            <BlockUI />
                        </Stack>
                    </Box>

                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
                { Object.keys(response).length > 0 && <Toastr response={response} /> }
            </Container>
    )
}

const MobileView = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const users = useSelector((store)=> store.users);
    const [response, setResponse] = useState({});
    const [disabled,setDisabled] = useState(false);
    const [data, setData] = useState({});

    useEffect(()=>{
        dispatch(resetUsers(false));
    },[]);

    const handleSubmit = async () => {
        setResponse(false);
        setDisabled(true);
        dispatch(showBlockUI(true));

        await doRegister(dispatch, data).then((res)=>{
            dispatch(showBlockUI(false));
            setResponse(res);
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    const ALPHABETS = /^[a-zA-Z]+$/;

    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={'/images/ifund-logo.png'} width={150}  alt={"logo"}/>
                    </Box>

                    <Box sx={{p:2,flexGrow:0.75}}>
                        <Stack spacing={2} sx={{ minWidth: '310px' }}>
                            { response.status !== 201 &&
                                <>
                                    <TextField
                                        type="text"
                                        id="first_name"
                                        onKeyDown={(e) => {
                                            if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                                e.preventDefault();
                                            }
                                        }}
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        value={ data['first_name'] }
                                        required
                                        label="First Name"
                                        helperText={users.error?.first_name}
                                    />
                                    <TextField
                                        type="text"
                                        id="last_name"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        value={data['last_name']}
                                        required
                                        onKeyDown={(event) => {
                                            if (!ALPHABETS.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        label="Last Name"
                                        helperText={users.error?.last_name}
                                    />
                                    <TextField
                                        type="email"
                                        id="email"
                                        onChange={(e) => handleChange(e)}
                                        value={data['email']}
                                        required
                                        label="Email"
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.email}
                                    />
                                    <TextField
                                        type="tel"
                                        id="phone"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        value={data['phone']}
                                        required
                                        label="Phone"
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.phone}
                                    />
                                    <TextField
                                        type="password"
                                        id="password"
                                        onChange={(e) => handleChange(e)}
                                        value={data['password']}
                                        autoComplete="off"
                                        required
                                        label="Password"
                                        sx={{ width: '100%' }}
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.password}
                                    />
                                    <TextField
                                        type="text"
                                        id="company_name"
                                        onKeyDown={(e) => {
                                            if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                                e.preventDefault();
                                            }
                                        }}
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        value={ data['company_name'] }
                                        required
                                        label="Company Name"
                                        helperText={users.error?.company_name}
                                    />

                                    <MuiDatepicker error={users?.error?.company_incorporation_date} setDisabled={setDisabled} label={"Company Incorporation Date"} past={true} name="company_incorporation_date" data={data} state={setData} />

                                    <TextField
                                        type="text"
                                        id="total_revenue"
                                        onChange={(e) => handleChange(e)}
                                        value={data['total_revenue']}
                                        required
                                        hidden={true}
                                        label="Total Revenue"
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.total_revenue}
                                    />
                                    <TextField
                                        type="text"
                                        id="amount"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        value={data['amount']}
                                        required
                                        label="Required Funding Amount"
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.amount}
                                    />
                                    <TextField
                                        type="text"
                                        id="tenor"
                                        onChange={(e) => handleChange(e)}
                                        value={data['tenor']}
                                        autoComplete="off"
                                        required
                                        label="Funding Tenor"
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.tenor}
                                    />
                                    <FormControl sx={{ width:'100%', display:'none' }}>
                                        <InputLabel id="service">Service</InputLabel>
                                        <Select
                                            defaultValue={"RECEIVABLE_DISCOUNTING"}
                                            name="service"
                                            MenuProps={{
                                                style: {zIndex: 35001}
                                            }}
                                            fullWidth
                                            label="Service"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <MenuItem key={""} value={""} disabled>Select Service</MenuItem>
                                            <MenuItem key={"RECEIVABLE_DISCOUNTING"} value={'RECEIVABLE_DISCOUNTING'}>Receivable Finance</MenuItem>
                                            {/*<MenuItem key={"INVOICE_DISCOUNTING"} value={'INVOICE_DISCOUNTING'}>Invoice Discounting</MenuItem>*/}
                                        </Select>
                                        <FormHelperText>{users.error?.service}</FormHelperText>
                                    </FormControl>
                                    <TextField
                                        type="text"
                                        id="purpose"
                                        multiline
                                        rows={4}
                                        onChange={(e) => handleChange(e)}
                                        value={data['purpose']}
                                        autoComplete="off"
                                        required
                                        label="Purpose of Funding"
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText={users.error?.purpose}
                                    />
                                    <Button disabled={disabled} color="info" sx={{ width:'100%', fontSize:'15px', fontWeight:'bold'}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Get Started</Button>
                                </>
                            }

                            <Typography align={'center'}>
                                Already have an account? <Link to={'/login'}>Login</Link>
                            </Typography>

                            <BlockUI />

                            { Object.keys(response).length > 0 && <Toastr response={response} /> }
                        </Stack>
                    </Box>

                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>
    )
}


const RegistrationFailedCard = () => {

    return (<Card>
        <CardHeader title={"Sorry! You do not meet the requirement."} titleTypographyProps={{color:'error', fontSize:'16px', fontWeight:'bold'}} />
        <CardContent sx={{alignItems:'center', justifyContent:'center', paddingY:'50px'}}>
            <Typography color={'darkgrey'} sx={{textAlign:'center'}}>Your business must be atleast 6 months old in order to register</Typography>
            <Typography color={'darkgray'} sx={{textAlign:'center'}}>For more information, you can send an email to info@ifundfactoring.com</Typography>
        </CardContent>
    </Card>)
}

const RegistrationSuccessCard = () => {

    return (<Card>
                <CardHeader title={"User Registered Successfully"} titleTypographyProps={{color:'success', fontSize:'16px', fontWeight:'bold'}} />
                <CardContent sx={{alignItems:'center', justifyContent:'center', paddingY:'50px'}}>
                    <Typography color={'darkgrey'} sx={{textAlign:'center'}}>Please check your mailbox for Email Verification</Typography>
                    <Typography color={'darkgray'} sx={{textAlign:'center'}}>You can access Ifund after email verification.</Typography>
                </CardContent>
                <CardActions sx={{display:'flex', flexDirection:'column', p:2, borderTop:'none!important'}} >

                    <Stack direction={"row"} spacing={2} justifyContent="space-between">
                        <Typography align={'center'}>
                            Already have an account? <Link to={'/login'}>Login</Link>
                        </Typography>
                    </Stack>
                </CardActions>
            </Card>)
}