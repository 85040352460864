import React from 'react'
import {fetchBanks, fetchLOV, setFsModal} from "../../../Setup/Redux/ConfigAction";
import {useDispatch, useSelector} from "react-redux";
import {DashboardAdmin} from "./DashboardAdmin";
import {DashboardStaff} from "./DashboardStaff";
import {DashboardClient} from "./DashboardClient";
import {fetchStats} from "../Redux/DashboardAction";
import {fetchBusinesses} from "../../Business";

export const Dashboard = () => {

    const dispatch = useDispatch();
    const auth = useSelector(store => store.auth);

    //auth.user.role_type === 'CLIENT' && dispatch(fetchBusinesses);

    dispatch(fetchBusinesses);
    dispatch(fetchBanks);
    dispatch(fetchLOV);
    dispatch(fetchStats);
    dispatch(setFsModal({ component:null, state: false}))

    return (
        <>
            { auth?.user?.role_type === 'ADMIN' ? <DashboardAdmin /> : auth?.user?.role_type === 'USER' ? <DashboardStaff /> : <DashboardClient />}
        </>
    );
}
