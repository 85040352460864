import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED CHEQUE
 ****************************************/
export const setSelectedCheque = (payload) => ({
    type: CONST.SET_SELECTED_CHEQUE,
    payload: payload
})
/****************************************
 FETCH CHEQUE
 ****************************************/
export const fetchChequesSuccess = (payload) => ({
    type: CONST.FETCH_CHEQUES_SUCCESS,
    payload: payload
})

export const fetchChequesFailed = (payload) => ({
    type: CONST.FETCH_CHEQUES_FAILED,
    payload: payload
})

export const fetchCheques = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('business/'+ id + '/cheque').then((response) => {
        res['success'] = response;
        dispatch(fetchChequesSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchChequesFailed(error?.response));
    });
    return res;
}
/****************************************
 ADD Business CHEQUE
 ****************************************/
export const addChequeSuccess = (payload) => ({
    type: CONST.ADD_CHEQUE_SUCCESS,
    payload: payload
})

export const addChequeFailed = (payload) => ({
    type: CONST.ADD_CHEQUE_FAILED,
    payload: payload
})

export const addCheque = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('cheque', data).then((response) => {
        res['success'] = response;
        dispatch(addChequeSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addChequeFailed(error?.response));
    });
    return res;
}
/****************************************
 Update Business CHEQUE
 ****************************************/
export const updateChequeSuccess = (payload) => ({
    type: CONST.UPDATE_CHEQUE_SUCCESS,
    payload: payload
})

export const updateChequeFailed = (payload) => ({
    type: CONST.UPDATE_CHEQUE_FAILED,
    payload: payload
})

export const updateCheque = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('cheque/' + data['cheque_id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateChequeSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateChequeFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business CHEQUE
 ****************************************/
export const deleteChequeSuccess = (payload) => ({
    type: CONST.DELETE_CHEQUE_SUCCESS,
    payload: payload
})

export const deleteChequeFailed = (payload) => ({
    type: CONST.DELETE_CHEQUE_FAILED,
    payload: payload
})

export const deleteCheque = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.delete('cheque/' + id).then((response) => {
        res['success'] = response;
        dispatch(deleteChequeSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteChequeFailed(error?.response));
    });
    return res;
}