import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { openFsModal } from "../../../Setup/Redux/ConfigAction";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function LocalFsModal(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={props.open}
                onClose={ () => props.setOpen(false) }
                aria-labelledby="responsive-dialog-title"
            >
                {props.component}
            </Dialog>
        </>
    );
}