import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export default function PoweredBy(props) {

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Ifund Copyright © 2023 | Powered By '}
            <Link color="inherit" to={{ pathname:"https://www.xeestudios.ae/" }} target={"_blank"}>
                XeeStudios
            </Link>
        </Typography>
    );
}


