const CONST = {
    'PERFORM_LOGIN_SUCCESS': 'APP/LOGIN_SUCCESS',
    'PERFORM_LOGIN_FAILED': 'APP/LOGIN_FAILED',
    'PERFORM_LOGIN_LOADING': 'APP/LOGIN_LOADING',
    'PERFORM_LOGOUT_SUCCESS': 'APP/LOGOUT_SUCCESS',
    'PERFORM_LOGOUT_FAILED': 'APP/LOGOUT_FAILED',
    'PERFORM_LOGOUT_LOADING': 'APP/LOGOUT_LOADING',
    'PERFORM_REFRESH_TOKEN': 'APP/REFRESH_TOKEN',

    'LOAD_USER_ACCOUNTS':'ACCOUNTS/LOAD_USER_ACCOUNTS',
    'LOAD_USER_ACCOUNTS_FAILED':'ACCOUNTS/LOAD_USER_ACCOUNTS_FAILED',

    'FETCH_CONFIGS_SUCCESS':'DASHBOARD/FETCH_CONFIGS_SUCCESS',
    'FETCH_CONFIGS_FAILED':'DASHBOARD/FETCH_CONFIGS_FAILED',
    'FETCH_BANKS_SUCCESS':'DASHBOARD/FETCH_BANKS_SUCCESS',
    'FETCH_BANKS_FAILED':'DASHBOARD/FETCH_BANKS_FAILED',
    'FETCH_LOVS_SUCCESS':'DASHBOARD/FETCH_LOVS_SUCCESS',
    'FETCH_LOVS_FAILED':'DASHBOARD/FETCH_LOVS_FAILED',
    'HAS_BUSINESS_SUCCESS':'DASHBOARD/HAS_BUSINESS_SUCCESS',
    'HAS_BUSINESS_FAILED':'DASHBOARD/HAS_BUSINESS_FAILED',

    'SET_SELECTED_ACCOUNT':'ACCOUNTS/SET_SELECTED_ACCOUNT',
    'RIGHT_DRAWER_STATE':'GENERAL/RIGHT_DRAWER_STATE',
    'LEFT_DRAWER_STATE':'GENERAL/LEFT_DRAWER_STATE',
    'SET_APPBAR_HEIGHT':'GENERAL/SET_APPBAR_HEIGHT',
    'COMMON_MODAL_STATE':'MODAL/COMMON_MODAL_STATE',

    'FS_MODAL_STATE':'MODAL/FS_MODAL_STATE',
    'SET_FS_MODAL':'MODAL/SET_FS_MODAL',
    'BLOCKUI_STATE':'GENERAL/BLOCKUI_STATE',

    'LOAD_USERS':'USERS/LOAD_USERS',
    'LOAD_USERS_FAILED':'USERS/LOAD_USERS_FAILED',
}

export default CONST;