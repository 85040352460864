import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { fetchBusinesses } from "../../index";
import {Link} from "react-router-dom";
import { Chip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment";


export default function BusinessTable(props) {

    const auth = useSelector((store) => store.auth);
    const business = useSelector((store) => store.business);
    const dispatch = useDispatch();

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        fetchBusinesses(dispatch, filters);
        return ()=>{
            isMounted = false;
            controller.abort()
        }
    },[filters]);

    const columns = React.useMemo(
        () => [
            { field: 'business_id', headerName:'Business ID', type: 'string', filterable: false },
            { field: 'name', headerName:'Business Name', type: 'string', flex: 1, minWidth: 350,
                renderCell: (params) => {
                    return (['DRAFT','RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT' ||
                            ['SUBMITTED','PROCESSING','PENDING'].includes(params.row.status)) && auth.user.role_type !== 'CLIENT' ?
                        <Typography component={Link} onClick={props.editItem(params.id)}>{params.value}</Typography> :
                        <Link to={`/business/${params.id}`}>{params.value}</Link>
                }
            },
            { field: 'trade_license_number', headerName:'Trade License Number', type: 'string', flex: 1, minWidth: 200 },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', flex: 1, minWidth: 200, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'updated_at', headerName:'Updated At', type: 'dateTime', flex: 1, minWidth: 200, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'status', headerName:'Status', type: 'string', width:120,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'SUBMITTED':'info',
                        'PENDING':'warning',
                        'PROCESSING':'primary',
                        'VERIFIED':'success',
                        'APPROVED':'success',
                        'RETURNED':'warning',
                        'REJECTED':'error'
                    }
                    return(<Chip
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    >
                    </Chip>)
                },
            },
            { field: 'actions', type: 'actions', headerName:'Action', flex: 1, minWidth: 150,
                getActions: (params) => {

                    if((['DRAFT','RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT' || ['SUBMITTED','PROCESSING','PENDING'].includes(params.row.status)) && auth.user.role_type !== 'CLIENT') {
                        return [
                            <GridActionsCellItem
                                icon={<DeleteIcon/>}
                                label="Delete"
                                onClick={props.deleteItem(params.id)}
                            />,
                            <GridActionsCellItem
                                icon={<EditIcon/>}
                                label="Edit"
                                onClick={props.editItem(params.id)}
                            />]
                    }else {
                        return [
                                <GridActionsCellItem
                                    icon={<FindInPageIcon />}
                                    label="View"
                                    onClick={ props.viewItem(params.id) }
                                />
                            ];
                    }
                },
            },
        ],
        [props.deleteItem, props.editItem],
    );

    return (
        <div style={{ flex: '1 1 100%', width: '100%', borderTop:'1px solid #ddd' }}>
            <DataGridPro
                autoHeight={true}
                getRowId={(row) => row.business_id}
                pageSize={filters.pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                columns={columns}
                rows={business.list || []} sx={{ border:'none'}}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'created_at', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            business_id: false,
                        },
                    },
                    pinnedColumns:{ right: ['actions'] },
                }}
            />
        </div>
    );
}
