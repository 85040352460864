import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";



/****************************************
 SET SELECTED BANK BORROWINGS
 ****************************************/
export const setSelectedBankBorrowing = (payload) => ({
    type: CONST.SET_SELECTED_BANK_BORROWING,
    payload: payload
})
/****************************************
 FETCH BANK_BORROWINGS
 ****************************************/
export const fetchBankBorrowingsSuccess = (payload) => ({
    type: CONST.FETCH_BANK_BORROWINGS_SUCCESS,
    payload: payload
})

export const fetchBankBorrowingsFailed = (payload) => ({
    type: CONST.FETCH_BANK_BORROWINGS_FAILED,
    payload: payload
})

export const fetchBankBorrowings = async (dispatch, id) => {

    await axiosPrivate.get('business/'+ id + '/bankBorrowing').then((response) => {
        dispatch(fetchBankBorrowingsSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchBankBorrowingsFailed(error?.response));
    });
}

/****************************************
 ADD Business BANK BORROWINGS
 ****************************************/
export const addBankBorrowingSuccess = (payload) => ({
    type: CONST.ADD_BANK_BORROWING_SUCCESS,
    payload: payload
})

export const addBankBorrowingFailed = (payload) => ({
    type: CONST.ADD_BANK_BORROWING_FAILED,
    payload: payload
})

export const addBankBorrowing = async (dispatch, data) => {

    await axiosPrivate.post('bankBorrowing', data).then((response) => {
        dispatch(addBankBorrowingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addBankBorrowingFailed(error?.response));
    });
}


/****************************************
 Update Business BANK BORROWING
 ****************************************/
export const updateBankBorrowingSuccess = (payload) => ({
    type: CONST.UPDATE_BANK_BORROWING_SUCCESS,
    payload: payload
})

export const updateBankBorrowingFailed = (payload) => ({
    type: CONST.UPDATE_BANK_BORROWING_FAILED,
    payload: payload
})

export const updateBankBorrowing = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('bankBorrowing/' + data['id'], data).then((response) => {
        dispatch(updateBankBorrowingSuccess(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateBankBorrowingFailed(error?.response));
    });
    return res;
}

/****************************************
 DELETE Business BANK BORROWING
 ****************************************/
export const deleteBankBorrowingSuccess = (payload) => ({
    type: CONST.DELETE_BANK_BORROWING_SUCCESS,
    payload: payload
})

export const deleteBankBorrowingFailed = (payload) => ({
    type: CONST.DELETE_BANK_BORROWING_FAILED,
    payload: payload
})

export const deleteBankBorrowing = async (dispatch, id) => {

    await axiosPrivate.delete('bankBorrowing/' + id).then((response) => {
        dispatch(deleteBankBorrowingSuccess(response));
    }).catch((error)=>{
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteBankBorrowingFailed(error?.response));
    });
}