import React, {useEffect, useState} from 'react'
import {Container, Fab, Grid, Paper, Stack, TextField} from "@mui/material";
import BusinessTable from './Tables/BusinessTable';
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {openRightDrawer, setFsModal} from "../../../Setup/Redux/ConfigAction";
import {
    fetchBusinesses,
    approveBusiness,
    deleteBusiness,
    fetchBusiness,
    rejectBusiness, returnBusiness,
    setSelectedBusiness, verifyBusiness, exportBusiness
} from "../index";
import {Wizard} from "./Wizard";
import {useNavigate, useParams} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TabView from "./TabView";
import CustomAlertV2 from "../../Components/Common/CustomAlertV2";
import Divider from "@mui/material/Divider";
import Toastr from "../../Components/Common/Toastr";

export const Business = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formName, setFormName] = useState(null);

    useEffect(() => {
        fetchBusinesses(dispatch);
        dispatch(setFsModal({ component:null, state: false}))
    },[]);
    const addItem = () => {
        dispatch(openRightDrawer(true));
        setFormName('AddBusiness');
    };

    const viewItem = React.useCallback(
        (id) => async () => {
            await fetchBusiness(dispatch, id);
            navigate('/business/' + id);
        },
        [],
    );

    const editItem = React.useCallback(
        (id) => async () => {
            await fetchBusiness(dispatch, id);
            navigate('/business/' + id);
        },
        [],
    );

    const deleteItem = React.useCallback(

        (id) => () => {
            dispatch(setFsModal({component:<DeleteBusinessModal />, state:true}));
            setFormName('DeleteBusiness');
            dispatch(setSelectedBusiness(id));
        },
        [],
    );

    return (
        <>
            <Container maxWidth={'xl'} sx={{p:0}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <IconBreadcrumbs items={'DASHBOARD/BUSINESS'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ display:'flex', flexDirection:'column', flex:1}}>

                            <Typography
                                sx={{ flex: '1', p:2 }}
                                variant="h6"
                                component="div"
                            >
                                Business
                            </Typography>

                            <BusinessTable editItem={editItem} deleteItem={deleteItem} viewItem={viewItem} />

                            <Fab onClick={addItem} size="big" color="error" aria-label="add" sx={{ position: 'absolute', bottom: 50, right:50 }}>
                                <AddIcon />
                            </Fab>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export const BusinessView = () => {

    const {business_id} = useParams();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});
    const auth = useSelector((store) => store.auth);

    useEffect(() => {
        fetchData();
        dispatch(setFsModal({ component:null, state: false}))
    },[]);

    let fetchData = async () => {
        await fetchBusiness(dispatch, business_id).then((res) => {
            setResponse(res);
        });
    }
    return (
        <>
            {
                (() => {
                    switch (response?.success?.data?.status){
                        case 'DRAFT':
                        case 'RETURNED':
                            return <Wizard />;
                        case 'SUBMITTED':
                        case 'VERIFIED':
                        case 'REJECTED':
                        case 'APPROVED':
                            return <TabView/>;
                        default:
                            return <></>;
                    }
                })()
            }

        </>
    );
}

export const DeleteBusinessModal = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.business);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        await deleteBusiness(dispatch, {business_id : items.selected.business_id || ''});
        dispatch(setFsModal({component:null, state:false}))
    };

    return(
        <>
            <DialogTitle>Delete Business</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to remove this business?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Delete Business</Button>
            </DialogActions>
        </>
    )
}

export const VerifyBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const config = useSelector((state) => state.business.config);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState();

    useEffect(() => {

        let con = {}
        config.map((c) => con[c.config_type] = c.config_value)
        setData(con);
    },[]);

    const handleSubmit = async () => {

        let formData = {}
        formData['business_id'] = props.business_id;
        formData['config'] = data;

        setDisabled(true);

        await verifyBusiness(dispatch, formData).then((res)=>{

            if(res['success']){
                dispatch(setFsModal({component:null, state:false}));
                navigate('/business');
            }else{
                setResponse(res);
            }
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    return(
        <>
            <DialogTitle>Verify Business</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        label={"Discounting Limit Amount"}
                        fullWidth
                        required
                        name="credit_limit"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.credit_limit']}
                    />
                    <TextField
                        label={"Forfaiting / Discounting Rate %"}
                        fullWidth
                        required
                        name="profit_rate"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.profit_rate']}
                    />
                    <TextField
                        label={"Processing Fee %"}
                        fullWidth
                        required
                        name="processing_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.processing_fee']}
                    />
                    <TextField
                        label={"Tenor (Days)"}
                        fullWidth
                        required
                        name="max_allowed_tenor"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.max_allowed_tenor']}
                    />
                    <TextField
                        label={"No of Repayments"}
                        fullWidth
                        required
                        name="no_of_repayments"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.no_of_repayments']}
                    />
                    <TextField
                        label={"Account Setup Fee"}
                        fullWidth
                        required
                        name="account_setup_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.account_setup_fee']}
                    />
                    <TextField
                        label={"Document Fee"}
                        fullWidth
                        required
                        name="document_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.document_fee']}
                    />
                    <TextField
                        label={"Due Diligence Fee"}
                        fullWidth
                        required
                        name="due_diligence_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.due_diligence_fee']}
                    />
                    <Divider textAlign="left" sx={{ fontSize:'11px'}}>The below config is for Invoice Discounting</Divider>
                    <TextField
                        label={"Drawdown Fee"}
                        fullWidth
                        required
                        name="drawdown_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.drawdown_fee']}
                    />
                    <TextField
                        label={"Retention Amount%"}
                        fullWidth
                        required
                        name="retention_amount"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.retention_amount']}
                    />
                    <TextField
                        label={"Utilization Commitment %"}
                        fullWidth
                        required
                        name="utilization_commitment"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.utilization_commitment']}
                    />
                    <TextField
                        label={"Other Conditions"}
                        fullWidth
                        required
                        name="other_conditions"
                        onBlur={handleChange}
                        helperText={response?.error?.data?.errors?.['config.other_conditions']}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false})) }>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Verify Business</Button>
            </DialogActions>
        </>
    )
}

export const ReturnBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();

    const handleSubmit = async () => {
        let data = {
            'status': 'RETURNED',
            'reason':reason,
            'business_id': props.business_id
        }
        setDisabled(true);
        await returnBusiness(dispatch, data);
        dispatch(setFsModal({component:null, state:false}));
        navigate('/business');
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value === '');
    }

    return(
        <>
            <DialogTitle>Return Business</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to Return this business?
                </DialogContentText>
                <TextField
                    label={"Reason for Return"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Return Business</Button>
            </DialogActions>
        </>
    )
}

export const ApproveBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const config = useSelector((state) => state.business.config);
    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useEffect(() => {
        let con = {}
        config.map((c) => con[c.config_type] = c.config_value)
        setData(con);
    },[]);

    const handleSubmit = async () => {

        let formData = {}
        formData['business_id'] = props.business_id;
        formData['config'] = data;

        setDisabled(true);

        await approveBusiness(dispatch, formData).then((res)=>{

            if(res['success']){
                dispatch(setFsModal({component:null, state:false}));
                navigate('/business');
            }else{
                setResponse(res);
            }
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    return(
        <>
            <DialogTitle>Approve Business</DialogTitle>
            <DialogContent>
                {Object.keys(data).length > 0 &&
                <Stack spacing={2} sx={{pt:2}}>
                    { response && <CustomAlertV2 response={response} /> }
                    <TextField
                        defaultValue={data?.credit_limit}
                        label={"Discounting Limit Amount"}
                        fullWidth
                        required
                        name="credit_limit"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.credit_limit']}
                    />
                    <TextField
                        defaultValue={data?.profit_rate}
                        label={"Forfaiting / Discounting Rate %"}
                        fullWidth
                        required
                        name="profit_rate"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.profit_rate']}
                    />
                    <TextField
                        defaultValue={data?.processing_fee}
                        label={"Processing Fee %"}
                        fullWidth
                        required
                        name="processing_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.processing_fee']}
                    />
                    <TextField
                        defaultValue={data?.max_allowed_tenor}
                        label={"Tenor (Days)"}
                        fullWidth
                        required
                        name="max_allowed_tenor"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.max_allowed_tenor']}
                    />
                    <TextField
                        defaultValue={data?.no_of_repayments}
                        label={"No of Repayments"}
                        fullWidth
                        required
                        name="no_of_repayments"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.no_of_repayments']}
                    />
                    <TextField
                        defaultValue={data?.account_setup_fee}
                        label={"Account Setup Fee"}
                        fullWidth
                        required
                        name="account_setup_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.account_setup_fee']}
                    />
                    <TextField
                        defaultValue={data?.document_fee}
                        label={"Document Fee"}
                        fullWidth
                        required
                        name="document_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.document_fee']}
                    />
                    <TextField
                        defaultValue={data?.due_diligence_fee}
                        label={"Due Diligence Fee"}
                        fullWidth
                        required
                        name="due_diligence_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.due_diligence_fee']}
                    />
                    <Divider textAlign="left" sx={{ fontSize:'11px'}}>The below config is for Invoice Discounting</Divider>
                    <TextField
                        defaultValue={data?.drawdown_fee}
                        label={"Drawdown Fee"}
                        fullWidth
                        required
                        name="drawdown_fee"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.drawdown_fee']}
                    />
                    <TextField
                        defaultValue={data?.retention_amount}
                        label={"Retention Amount %"}
                        fullWidth
                        required
                        name="retention_amount"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.retention_amount']}
                    />
                    <TextField
                        defaultValue={data?.utilization_commitment}
                        label={"Utilization Commitment %"}
                        fullWidth
                        required
                        name="utilization_commitment"
                        onBlur={handleChange}
                        type={"number"}
                        helperText={response?.error?.data?.errors?.['config.utilization_commitment']}
                    />
                    <TextField
                        defaultValue={data?.other_conditions}
                        label={"Other Conditions"}
                        fullWidth
                        required
                        name="other_conditions"
                        onBlur={handleChange}
                        helperText={response?.error?.data?.errors?.['config.other_conditions']}
                    />
                </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Approve Business</Button>
            </DialogActions>
        </>
    )
}

export const RejectBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();

    const handleSubmit = async () => {
        let data = {
            'status': 'REJECTED',
            'reason': reason,
            'business_id': props.business_id
        }
        setDisabled(true);
        await rejectBusiness(dispatch, data);
        dispatch(setFsModal({component:null, state:false}));
        navigate('/business');
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value <= 0);
    }

    return(
        <>
            <DialogTitle>Reject Business</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to reject this business?
                </DialogContentText>
                <TextField
                    label={"Reason for Rejection"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'error'} onClick={ handleSubmit }>Reject Business</Button>
            </DialogActions>
        </>
    )
}

export const ExportBusinessModal = (props) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        let data = {
            'business_id': props.business_id
        }
        setDisabled(true);
        await exportBusiness(dispatch, data).then((res)=>{
            setResponse(res);
        });
        dispatch(setFsModal({component:null, state:false}));
    };


    return(
        <>
            <DialogTitle>Export Business</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to export this business?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Export Business</Button>
            </DialogActions>
            {response && <Toastr response={response} />}
        </>
    )
}