import CONST from './Constant';

export const proceed = (payload) => ({
    type: CONST.CLEAR_PROCEED_STATE,
    payload:payload,
})
export const clearErrors = (payload) => ({
    type: CONST.CLEAR_ERROR_STATE,
    payload:payload,
})







