import CONST from './Constant';
import axiosPrivate from '../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";


/****************************************
 SET SELECTED ADDRESS
 ****************************************/
export const setSelectedAddress = (payload) => ({
    type: CONST.SET_SELECTED_ADDRESS,
    payload: payload
})
/****************************************
 FETCH ADDRESS
 ****************************************/
export const fetchAddressesSuccess = (payload) => ({
    type: CONST.FETCH_ADDRESSES_SUCCESS,
    payload: payload
})

export const fetchAddressesFailed = (payload) => ({
    type: CONST.FETCH_ADDRESSES_FAILED,
    payload: payload
})

export const fetchAddresses = async (dispatch, id) => {
    let res = {}
    await axiosPrivate.get('business/'+ id + '/address').then((response) => {
        res['success'] = response;
        dispatch(fetchAddressesSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchAddressesFailed(error?.response));
    });
    return res;
}
/****************************************
 ADD Business ADDRESS
 ****************************************/
export const addAddressSuccess = (payload) => ({
    type: CONST.ADD_ADDRESS_SUCCESS,
    payload: payload
})

export const addAddressFailed = (payload) => ({
    type: CONST.ADD_ADDRESS_FAILED,
    payload: payload
})

export const addAddress = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.post('address', data).then((response) => {
        res['success'] = response;
        dispatch(addAddressSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(addAddressFailed(error?.response));
    });
    return res;
}
/****************************************
 Update Business ADDRESS
 ****************************************/
export const updateAddressSuccess = (payload) => ({
    type: CONST.UPDATE_ADDRESS_SUCCESS,
    payload: payload
})

export const updateAddressFailed = (payload) => ({
    type: CONST.UPDATE_ADDRESS_FAILED,
    payload: payload
})

export const updateAddress = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.patch('address/' + data['id'], data).then((response) => {
        res['success'] = response;
        dispatch(updateAddressSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(updateAddressFailed(error?.response));
    });
    return res;
}
/****************************************
 DELETE Business ADDRESS
 ****************************************/
export const deleteAddressSuccess = (payload) => ({
    type: CONST.DELETE_ADDRESS_SUCCESS,
    payload: payload
})

export const deleteAddressFailed = (payload) => ({
    type: CONST.DELETE_ADDRESS_FAILED,
    payload: payload
})

export const deleteAddress = async (dispatch, id) => {
    let res = {};
    await axiosPrivate.delete('address/' + id).then((response) => {
        res['success'] = response;
        dispatch(deleteAddressSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        return dispatch(deleteAddressFailed(error?.response));
    });
    return res;
}