import React, { useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { setFsModal, showBlockUI} from "../../../../Setup/Redux/ConfigAction";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {FormControl, InputLabel, Select, TextField} from "@mui/material";
import {addBusiness} from "../../index";
import {useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Toastr from "../../../Components/Common/Toastr";

export const RegisterBusinessModal = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lov = useSelector((store) => store.config.lov);
    const [disabled, setDisabled] = useState(false);
    const [disabledField, setDisabledField] = useState(false);
    const [response, setResponse] = useState({});
    const [data, setData] = useState({
        name:null,
        trade_license_number:null,
    });

    const handleSubmit = async () => {

        if(data['name'] != null && data['trade_license_number'] != null){

            setDisabled(true);
            dispatch(showBlockUI(true));

            await addBusiness(dispatch, data).then((res)=>{
                setResponse(res);
                setDisabledField(res['success'] !== undefined);
                if(res['success']){
                    dispatch(setFsModal({component:null, state:false}))
                    navigate('/business/' + res['success'].data.business_id);
                }
            });
        }
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    return(
        <>
            <DialogTitle>Start here</DialogTitle>
            <DialogContent sx={{justifyContent:'center', paddingTop:'5px!important', alignContent:'center', display:'flex', flexDirection:'column'}}>
                <TextField
                    fullWidth
                    disabledField
                    label="Type your business name"
                    name="name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    onChange={(e) => handleChange(e)}
                    helperText={response?.error?.data?.errors?.name}
                />
                <TextField
                    fullWidth
                    disabledField
                    label="Trade license number"
                    name="trade_license_number"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    onChange={(e) => handleChange(e)}
                    helperText={response?.error?.data?.errors?.trade_license_number}
                />
                <FormControl fullWidth>
                    <InputLabel id="license-authority-label">Trade License Authority</InputLabel>
                    <Select
                        name="trade_license_authority"
                        label="Trade License Authority"
                        onChange={handleChange}
                    >
                        <MenuItem key={"null"} value="">Select</MenuItem>
                        {
                            (lov.filter(i => i.name === 'trade_license_authority')).map((i,v) =><MenuItem key={v} value={i.value}>{i.label}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Create</Button>
            </DialogActions>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    )
}