import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setFsModal } from "../../../../Setup/Redux/ConfigAction";
import { useMediaQuery} from "@mui/material";
import { updateBusinessStatus } from "../../index";
import {useTheme} from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Toastr from "../../../Components/Common/Toastr";

export const BusinessSubmitModal = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const config = useSelector((store) => store.config);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const business = useSelector((store)=> store.business);
    const [response, setResponse] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        let payload = {};
        payload['business_id'] = business.selected['business_id'];
        payload['status'] = 'SUBMITTED';

        await updateBusinessStatus(dispatch, payload).then((res) => {
            setResponse(res);
            setDisabled(false);
            if(res.hasOwnProperty('success')){
                dispatch(setFsModal({component:null, state:true}));
                navigate('/business');
            }

        });
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={config.openFsModal}
                onClose={ () => dispatch(setFsModal({component:null, state:true})) }
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>Confirm Submission!</DialogTitle>
                <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                    Are you sure, you want to submit now?
                </DialogContent>
                <DialogActions sx={{p:3, pt:0}}>
                    <Button onClick={() => dispatch(setFsModal({component:null, state:true}))}>Cancel</Button>
                    <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Proceed</Button>
                </DialogActions>
            </Dialog>
            { Object.keys(response).length > 0 && <Toastr response={response} /> }
        </>
    );
}
