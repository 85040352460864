import * as React from 'react';
import {Card as CardUI} from "@mui/material";
import {styled} from "@mui/material/styles";

export const Panel = ({children, props}) => {

    const Card = styled(CardUI)({
        marginBottom:'1rem',
        '& .MuiCardContent-root': {
            padding: '0',
            paddingBottom: 0,
        },
        '& .MuiCardHeader-title': {
            fontSize: '1rem',
        }
    });

    return (
        <Card {...props}>
            {children}
        </Card>
    )
}