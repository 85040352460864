import React, {useState} from 'react'
import { TextField } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { rejectInvoice } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setFsModal} from "../../../../Setup/Redux/ConfigAction";

export const RejectInvoiceModal = () => {

    const dispatch = useDispatch();
    const invoice = useSelector((store) => store.invoices.selected);
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        let data = {
            'status': 'REJECTED',
            'reason': reason,
            'invoice_id': invoice.invoice_id
        }
        setDisabled(true);
        await rejectInvoice(dispatch, data).then((res) => {
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setFsModal({component:null, state:false}));
            }
        });
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value < 0);
    }

    return(
        <>
            <DialogTitle>Reject Invoice</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to reject this invoice?
                </DialogContentText>
                <TextField
                    placeholder={"Reason for Rejection"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    defaultValue={0}
                    type={"number"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setFsModal({component:null, state:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'error'} onClick={ handleSubmit }>Reject Invoice</Button>
            </DialogActions>
        </>
    )
}