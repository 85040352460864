import React,{ useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import {Alert, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Divider from '@mui/material/Divider';
import { sendVerificationEmail } from "../../Users";
import {Link, useParams} from "react-router-dom";


const EmailVerification = () => {

    const { token } = useParams();
    const config = useSelector((store) => store.config);
    const dispatch = useDispatch();
    const [disabled,setDisabled] = useState();

    const [data, setData] = useState({
        token : token,
    })

    const [msg, setMsg] = useState({
        'code':'',
        'message':'',
    });
    
    useEffect(() => {
        setMsg({
            'code':'',
            'message':'',
        });
    }, [data['username']])

    const handleSubmit = () => {

        setDisabled(true);

        sendVerificationEmail(dispatch, data).then(
            (res) => {
                setMsg( data => ({
                        'code':res.payload.status,
                        'message': res.payload?.data?.message
                    })
                );
                if(res.payload.status === 200){
                    setDisabled(false);
                }
            }
        );
    };


    const errM = () => {

        const status = { '201':'success', '200':'success', '422':'warning', '403':'error' }

        return msg.code ?
            (<Alert severity="error" color={status[msg.code]} sx={{ mb:3}}>
                { msg.message }
            </Alert>)
            :''
    }

    return (
        <>
            <Box sx={{ backgroundImage: `url('/images/bg.png')`, display:'flex', flexDirection:'column', height:'100vh', width:'100%' }}>
                <Box className={'container-fluid'} sx={{background:'#ffffff', boxShadow: '0 0 20px 0 rgb(0 0 0 / 3%)'}}>
                    <Box className={'container'}>
                        <Box className={'row header'}>
                            <Box className={'col-12 align-items-center justify-items-center d-flex '} sx={{ height: `${config.appBarHeight + 'px'}`}}>
                                <img src={'/images/ifund-logo.png'} width={100} ></img>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={'container'}>
                    <Box className={'row'}>
                        <Box className={'col-8 pt-5'}>
                            <Typography variant="h1" sx={{ fontSize:'40px', fontWeight:'bold', textTransform:'uppercase'}}>Email Verification</Typography>
                            <Typography variant="p" className={'py-4'} sx={{ marginTop:'20px', display:'block', fontSize:'18px', maxWidth:'800px'}}>We have sent an email to the registered email address. Please check your Inbox/Spam/Junk folders and activate the account.</Typography>
                        </Box>
                        <Box className={'col-4'} sx={{ height:`calc(100vh - ${config.appBarHeight + 'px'})`}}>
                            <Box sx={{ display:'flex', marginTop:'40px',  justifyContent:'center', alignItems:'center'}}>
                                <form className={'w-100 mt-4'} autoComplete="off">
                                    <Paper sx={{ display:"flex", flexDirection:'column', width:'100%'}}>
                                        <Box sx={{p:4}}>
                                            <Typography variant="h4" sx={{ mb:2, fontSize:'22px'}}>Send Email Verification</Typography>
                                            { errM() }
                                            <Divider />
                                            <Button disabled={disabled} color="warning" sx={{ width:'100%', fontSize:'15px'}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Send Again</Button>
                                        </Box>
                                        <Paper className={'px-4'} sx={{ display:"flex", flexDirection:'column', width:'100%', p:2}}>
                                            <Typography>
                                                New to Ifund? <Link to={'/login'}>Login</Link>
                                            </Typography>
                                        </Paper>
                                    </Paper>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default EmailVerification