import React, {useEffect, useState} from 'react'
import {Container,  Grid,  Stack, Box } from "@mui/material";
import {useDispatch,} from "react-redux";
import IconBreadcrumbs from "../../Components/Common/Breadcrumbs";
import {
    CustomerTable,
    fetchCustomers,
    fetchCustomer, setSelectedCustomer,
} from "../index";
import Button from "@mui/material/Button";
import ActionButtons from "./ActionButtons";
import CustomerDetailsCard from "./Cards/CustomerDetailsCard";
import CustomerConfigPanel from "./Panels/CustomerConfigPanel";
import {CustomerPropertiesPanel} from "./Panels/CustomerPropertiesPanel";
import {CustomerForm} from "./Forms/CustomerForm";
import { setFsModal} from "../../../Setup/Redux/ConfigAction";
import {useParams} from "react-router-dom";
import DocumentsList from "../../Documents/Components/DocumentsList";

export const Customers = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
    },[]);

    let fetchData = async () => {
        await fetchCustomers(dispatch, {'for_invoice_discounting': 1}).then((res) => {
            setResponse(res);
        });
    }

    const handleActionBtn = (e) => {
        dispatch(setFsModal({component:<CustomerForm />, state:true}));
        dispatch(setSelectedCustomer({}));
    }

    return (
        <>
            {(response !== undefined && response.hasOwnProperty('success')) &&
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box justifyContent={"space-between"} display={"flex"}>
                            <IconBreadcrumbs items={'DASHBOARD/CUSTOMERS'} sx={{display:'inline-block'}} />
                            <Button variant="contained" data-id={"draft"} data-action={"AddItem"} data-is_customer={1} onClick={handleActionBtn}>Add Customer</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerTable />
                    </Grid>
                </Grid>
            </>
            }
        </>
    );
}

export const CustomerView = () => {

    const { customer_id } = useParams();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
    },[customer_id])

    const fetchData = async() => {
        await fetchCustomer(dispatch, customer_id).then((res) => {
            setResponse(res);
        });
    }

    return (
        <>
            {(response !== undefined && response.hasOwnProperty('success')) &&
            <Container maxWidth={'xl'} sx={{p:0}}>
                {(response && response?.success !== undefined) &&
                <Grid container spacing={2} elevated={3}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <IconBreadcrumbs items={'DASHBOARD/CUSTOMERS'} />
                            <ActionButtons />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <CustomerDetailsCard />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DocumentsList entityId={customer_id} tab={"customers"} setResponse={setResponse} />
                        <CustomerPropertiesPanel />
                        <CustomerConfigPanel />
                    </Grid>
                </Grid>
                }
            </Container>
            }
        </>
    );
}