import React, {useState} from 'react';
import { useSelector } from "react-redux";
import {
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Collapse,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {styled} from "@mui/material/styles";
import Divider from "@mui/material/Divider";

export default function ReportsList() {

    const documents = useSelector((store) => store.documents.files) || [];
    const [isExpanded, setIsExpanded] = useState(false);

    const CustomList = styled(List)(({ theme }) => ({
        display:'flex',
        flexDirection:'column',
        '& .MuiListItem-root:lastChild': {
            borderBottom:'none',
        },
        '& .MuiListItem-root': {
            justifyContent:'space-between',
            padding: '8px!important',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',  // Aligns items vertically
            maxWidth: '100%',
            overflow: 'hidden',
            marginBottom: '1px',
            paddingBottom: '0px!important',
            backgroundColor:'#f8f8f8',
            borderBottom:'1px solid #ddd',
            '& .MuiSvgIcon-root': {
                width: '20px',
            },
            '& .MuiListItemText-root':{
                margin: '0px',
                '& span:first-child':{
                    lineHeight: '1',
                    fontSize:'0.8rem',
                    paddingLeft:'8px'
                },
                '& a': {
                    flex:1,
                    fontSize: '12px',
                    paddingLeft: '8px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginLeft: '0px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                    width: '90%',
                    display:'inline-block',
                },
            },
            '& .MuiIconButton-root': {
                padding: '0px!important',
                marginLeft: '0',
            }
        }
    }));

    const exports = documents
        .filter(i => i.entity_name === 'business' && ['export'].includes(i.type))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 5)
        .map(i => (
            <ListItem key={i.document_id} secondaryAction={<Link to={process.env.REACT_APP_BASE_URL + 'document/' + i.document_id} target={"_blank"} rel="noopener noreferrer"><IconButton  edge="end" aria-label="delete"> <DownloadIcon /> </IconButton></Link>}>
                <ListItemText
                    primary={ formatTime(i.created_at) }
                    secondary={<Link to={process.env.REACT_APP_BASE_URL + 'document/' + i.document_id} target={"_blank"} rel="noopener noreferrer">Download</Link>}
                />
            </ListItem>
        ));

    const reports = documents
        .filter(i => i.entity_name === 'business' && ['report'].includes(i.type))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 5)
        .map(i => (
            <ListItem key={i.document_id} secondaryAction={<Link to={process.env.REACT_APP_BASE_URL + 'document/' + i.document_id} target={"_blank"} rel="noopener noreferrer"><IconButton  edge="end" aria-label="delete"> <DownloadIcon /> </IconButton></Link>}>
                <ListItemText
                    primary={ formatTime(i.created_at) }
                    secondary={<Link to={process.env.REACT_APP_BASE_URL + 'document/' + i.document_id} target={"_blank"} rel="noopener noreferrer">{ i.doc_name}</Link>}
                />
            </ListItem>
        ));

    return (
        <>
            {(exports.length > 0 || reports.length > 0) &&
                <Card>
                    <CardHeader title="Reports" titleTypographyProps={{ variant: 'headline' }}
                        action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }
                    />
                    <Collapse in={isExpanded}>
                        <CardContent sx={{ padding: '0px!important' }}>
                            <CustomList dense={false} sx={{ padding: 0 }}>
                                {reports}
                            </CustomList>
                            <Divider sx={{fontSize:'8px'}}> EXPORTS </Divider>
                            <CustomList dense={false} sx={{ padding: 0 }}>
                                {exports}
                            </CustomList>
                        </CardContent>
                    </Collapse>
                </Card>
            }
        </>
    );
}


function formatTime(time){
    const refTime = moment(time);
    return (moment().diff(refTime, 'minutes') >= 30) ? refTime.format('DD/MM/YYYY h:mm A') : refTime.fromNow();
}