import * as React from 'react';
import { Card, CardContent, CardHeader} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";

export default function LoanConfigPanel() {

    const config = useSelector((state) => state.business.config);

    const CustomList = styled(List)({
        color: 'darkslategray',
        '& .MuiListItem-root': {
            padding: '0!important',
            borderBottom:'1px solid #f4f4f4',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return (
        <>
            { (config !== undefined && config.length > 0) &&
                <Card>
                    <CardHeader title={"Loan Configuration"} titleTypographyProps={{variant: 'heading'}}/>
                    <CardContent>
                        <CustomList>
                            <ListItem>
                                <ListItemText primary={"Credit Limit Requested:"} secondary={config.credit_limit_requested} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Credit Limit Recommended:"} secondary={config.credit_limit_recommended} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Credit Limit Approved:"} secondary={config.credit_limit_approved} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Max Allowed Tenor:"} secondary={config.max_allowed_tenor} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Profit Rate:"} secondary={config.profit_rate} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Discounting Percentage:"} secondary={config.discounting_percentage} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Account Setup Fee:"} secondary={config.account_setup_fee} />
                            </ListItem>
                        </CustomList>
                    </CardContent>
                </Card>
            }
        </>
    );
}
