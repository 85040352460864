import * as React from 'react';
import {Card, CardContent, CardHeader, Collapse, IconButton} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {currencyFormatter} from "../../../../Setup/Config/currencyFormat";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useState} from "react";

export function BusinessConfigPanel() {

    const config = useSelector((state) => state.business.config);
    const [isExpanded, setIsExpanded] = useState(false);

    const CustomList = styled(List)({
        padding:0,
        color: 'darkslategray',
        '& .MuiListItem-root': {
            padding: '5px!important',
            borderBottom:'1px solid #f4f4f4',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    const config_list = [
        {"label" : "Credit Limit Recommended","name" : "credit_limit", "format":"currency" },
        {"label" : "Profit Rate","name" : "profit_rate", "format":"rate" },
        {"label" : "Max Allowed Tenor","name" : "max_allowed_tenor", "format":"days" },
        {"label" : "Processing Fee","name" : "processing_fee", "format":"rate" },
        {"label" : "No of Repayments","name" : "no_of_repayments", "format":"number" },
        {"label" : "Account Setup Fee","name" : "account_setup_fee", "format":"currency" },
        {"label" : "Document Fee","name" : "document_fee", "format":"currency" },
        {"label" : "Due Diligence Fee","name" : "due_diligence_fee", "format":"currency" },
        {"label" : "Drawdown Fee","name" : "drawdown_fee", "format":"currency" },
        {"label" : "Retention Amount","name" : "retention_amount", "format":"currency" },
        {"label" : "Utilization Commitment","name" : "utilization_commitment", "format":"currency" },
        {"label" : "Other Conditions","name" : "other_conditions", "format":"text" },
    ]

    const formattedValue = (format, value) => {
        const v = value ?? '-';
        switch(format) {
            case 'currency':
                return v !== '-' ? currencyFormatter.format(v) : "-";
            case 'rate':
                return parseFloat(v).toFixed(2) + '%';
            case 'number':
                return parseInt(v);
            case 'text':
                return v;
            case 'days':
                return v + ' days';
            default:
                return v;
        }
    }

    return (
        <>
            { (config !== undefined && config.length > 0) &&
                <Card>
                    <CardHeader title={"Business Configuration"} titleTypographyProps={{variant: 'heading'}}
                        action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }
                    />
                    <Collapse in={isExpanded}>
                        <CardContent>
                            <CustomList>
                                { config_list.map((v,i) => (
                                    <ListItem key={i}>
                                        <ListItemText primary={v.label} secondary={ formattedValue(v.format, (config.find(x => x.config_type === v.name))?.config_value)} />
                                    </ListItem>
                                ))}
                            </CustomList>
                        </CardContent>
                    </Collapse>
                </Card>
            }
        </>
    );
}
