import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {Card, CardActions, CardContent,CardHeader, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { openRightDrawer } from "../../../Setup/Redux/ConfigAction";

const AddItem = () => {

    const dispatch = useDispatch();

    return(
        <Card sx={{ height: '100%', position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={()=> dispatch(openRightDrawer(false))}>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Add Role</Typography>
                }
            />
            <CardContent sx={{ flex:1 }}>

            </CardContent>
            <CardActions className={'card-footer'} sx={{}}>
                <Button variant={'contained'}>Submit</Button>
            </CardActions>
        </Card>
    )
}

export default AddItem;