import CONST from './constant';
import axios,{axiosPrivate} from "../../../Setup/Axios/SetupAxios";




/****************************************
 FETCH BANK_DETAILS
 ****************************************/
export const doLoginSuccess = (payload) => ({
    type: CONST.LOGIN_SUCCESS,
    payload: payload
})

export const doLoginFailed = (payload) => ({
    type: CONST.LOGIN_FAILED,
    payload: payload
})

export const doLogin = async (dispatch, data) => {
    let res = {}
    await axios.post('login/', data).then((response) => {
        res['success'] = response;
        dispatch(doLoginSuccess(response));
    }).catch((error)=>{
        res['error'] = error.response;
        dispatch(doLoginFailed(error?.response));
    });
    return res;
}

export const refreshToken = (payload) => ({
    type: CONST.PERFORM_REFRESH_TOKEN,
    payload:payload,
})


//LOGOUT
export const doLogoutSuccess = (payload) => ({
    type: CONST.PERFORM_LOGOUT_SUCCESS,
    payload,
})

export const doLogoutFailed = (payload) => ({
    type: CONST.PERFORM_LOGOUT_FAILED,
    payload,
})

export const doLogoutLoading = () => ({
    type: CONST.PERFORM_LOGOUT_LOADING,
})

export const doLogout = async (dispatch) => {

    dispatch(doLogoutLoading())

    await axiosPrivate.post('logout').then((response) => {
        dispatch(doLogoutSuccess(response.data));
    }).catch((error)=>{
        dispatch(doLogoutFailed(error))
    });
}

