import React from "react";
import {Box, Container} from "@mui/material";

export const FooterIfund = () => {
    return(
        <>
            <Box sx={{background:"url('/images/footer-bg.jpg')"}}>
                <Box sx={{ background:'rgba(0,44,71,0.56)', height:64,}}>

                </Box>
                <Box sx={{ background:'rgba(0,44,71,0.96)'}}>
                    <Container component="main" sx={{
                        display:'flex', flexDirection:'column', flexGrow: 1,
                        maxWidth: {
                            xs: '100vw',
                            sm: '90vw',
                            md: '92vw',
                            lg: '95vw',
                            xl: '55vw',
                            xxl: '70vw',
                        },
                        minWidth: {
                            xl: '1500px',
                        },
                        m:'0 auto 0', p:3
                    }}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:64,}}>
                            <Box sx={{color:'#fff', fontSize:12}}>Copyright © 2023 iFund</Box>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </>
    );
}
