import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";

export const SkeletonTable = ({ rows = 5, cols = 4 }) => {
    const colWidthPercentage = 100 / cols;

    return (
        <Box sx={{ p: 2 }}>
            <Stack spacing={1}>
                {Array(rows).fill().map((_, rowIndex) => (
                    <Stack key={rowIndex} direction={"row"} spacing={2}>
                        {Array(cols).fill().map((_, colIndex) => (
                            <Skeleton
                                key={colIndex}
                                variant="rectangular"
                                height={50}
                                width={`${colWidthPercentage}%`}
                            />
                        ))}
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
}