import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import { AccountCircle, Logout } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { doLogout } from "../../Auth";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";

export default function AccountMenu() {

    //For Logout
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((store)=> store.auth);

    //Account Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => (doLogout(dispatch).then(()=>{
        navigate('/login');
    }));

    return (
        <div>
            <Box sx={{ display: { md: 'flex' } }}>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleClick}
                >
                    <Typography sx={{ pr:2}}>
                        { auth?.user?.first_name + ' ' + auth?.user?.last_name }
                    </Typography>
                    <AccountCircle />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                elevation={0}
                sx={{
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiButtonBase-root':{
                            width:200
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Link hidden={true} style={{ textDecoration:'none'}} to={'/profile'} >
                    <MenuItem>Profile </MenuItem>
                </Link>

                <Link hidden={true} style={{ textDecoration:'none'}} to={'/settings'} >
                    <MenuItem> Settings </MenuItem>
                </Link>

                <Divider />
                <Link style={{ textDecoration:'none'}} to={'/logout'} >
                    <MenuItem onClick={ handleLogout } > Logout </MenuItem>
                </Link>
            </Menu>
        </div>
    );
}
